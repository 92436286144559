import {
  CREATE_ORG_REQUEST,
  DELETE_ORG_REQUEST,
  DELETE_ORG_REQUEST_DONE,
  EDIT_ORG_REQUEST,
  EDIT_ORG_REQUEST_DONE,
  GET_ALL_USERS_IN_ORG,
  GET_ALL_USERS_IN_ORG_RETURNED,
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATION_TYPES_SUCCESS,
  GET_ORGS_REQUEST,
  GET_ORGS_RETURNED,
  GET_ORG_BY_ID,
  GET_ORG_BY_ID_RETURNED,
  GET_SPECIALTIES,
  GET_SPECIALTIES_SUCCESS,
  ORGS_SEARCH_REQUEST,
  ORGS_SEARCH_RETURNED,
  ORG_CREATED,
} from "../constants/actionTypes";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { getOrgsApiGWClient } from "../../../../core/utils/auth-functions";
import {
  orgsApiClient,
  usersApiClient,
} from "../../../../core/utils/restClient";

import {
  showErrorMessage,
  showSuccessMessage,
  showSweetSuccessMessage,
} from "../../../../../actions/Notifications";

import moment from "moment";
import { orgToFilteredOrgItem } from "../converter/orgToFIlteredOrgItem";
import { organizationToOrganizationListItem } from "../converter/organizationToOrganizationListItem";
import { permissionConverter as psConverter } from "../../../../core/utils/PermissionConverter";
import { setLoggedInUser } from "../../../../../actions/Auth";

const errorMessage = "Something went wrong. please try again later.";

export function* getOrgsRequest(action) {
  const { filters } = action.payload;

  const statusFilter = filters[0];
  const selectedStatus = statusFilter.items.filter((item) => item.isSelected);

  const typeFilter = filters[1];
  const selectedTypes = typeFilter.items.filter((item) => item.isSelected);

  let clinicFilter = null;
  let selectedStatusValues = statusFilter.items.map((status) => status.value);
  let selectedTypesValues = typeFilter.items.map((type) => type.value);

  if (selectedStatus.length && !selectedTypes.length) {
    selectedStatusValues = selectedStatus.map((status) => status.value);
    clinicFilter = { status: selectedStatusValues, types: selectedTypesValues };
  } else if (!selectedStatus.length && selectedTypes.length) {
    selectedTypesValues = selectedTypes.map((type) => type.value);
    clinicFilter = { status: selectedStatusValues, types: selectedTypesValues };
  } else if (selectedStatus.length && selectedTypes.length) {
    selectedStatusValues = selectedStatus.map((status) => status.value);
    selectedTypesValues = selectedTypes.map((type) => type.value);
    clinicFilter = { status: selectedStatusValues, types: selectedTypesValues };
  }

  let orgs = [];
  let message = "";
  let convertedOrgs = [];
  let pathTemplate = "/api";
  const myInit = {
    queryStringParameters: {
      data: clinicFilter ? JSON.stringify(clinicFilter) : "",
    },
  };
  const res = yield orgsApiClient.get(pathTemplate, myInit);
  if (!res.success) {
    message = res.message || errorMessage;
    return yield put(showErrorMessage(message));
  }

  orgs = res.payload;
  if (orgs.length) {
    convertedOrgs = orgs.map((org) => orgToFilteredOrgItem(org));
  }

  yield put({
    type: GET_ORGS_RETURNED,
    payload: { orgs: convertedOrgs },
  });
}

export function* getOrganizationTypesRequest() {
  let orgTypes = [];
  let message = "";
  let pathTemplate = "/api/org-types";
  try {
    const res = yield orgsApiClient.get(pathTemplate, {});
    if (res.success) {
      orgTypes = res.payload;
      return yield put({
        type: GET_ORGANIZATION_TYPES_SUCCESS,
        payload: orgTypes,
      });
    }
    yield put(showErrorMessage(message));
  } catch (error) {
    yield put(showErrorMessage(message));
  }
}

export function* getSpecialityByOrg(action) {
  const orgId = action.payload;
  let pathTemplate = `/api/orgs/${orgId}/speciality`;
  try {
    const res = yield orgsApiClient.get(pathTemplate, {});
    if (res.success) {
      return yield put({
        type: GET_SPECIALTIES_SUCCESS,
        payload: res.payload,
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* searchOrgs(action) {
  const keyword = action.payload;
  const pathTemplate = `/api/search/${keyword}`;
  try {
    const res = orgsApiClient.get(pathTemplate, {});
    if (res.success) {
      const orgs = res.payload;
      const convertedOrgs = orgs.map((org) => orgToFilteredOrgItem(org));
      return yield put({
        type: ORGS_SEARCH_RETURNED,
        payload: { orgs: convertedOrgs },
      });
    }
    yield put(showErrorMessage(errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* createOrgRequest(action) {
  const { clinic, adminCreate, authUser } = action.payload;
  const { adminInfo } = clinic;
  let message = "";
  let error = false;
  const newClinic = organizationToOrganizationListItem(clinic);
  const newClinicDB = {
    ...newClinic,
    created_by: adminCreate
      ? authUser
        ? authUser.basicInfo.firstName + " " + authUser.basicInfo.firstName
        : ""
      : "",
    created_at: moment.utc().format(),
  };
  const newClinicModified = {
    clinic: newClinicDB,
    adminInfo,
  };
  let body = newClinicModified;
  let pathTemplate = "/api";
  let method = "post";

  const apigClient = getOrgsApiGWClient("", "", "");
  yield apigClient
    .invokeApi({}, pathTemplate, method, {}, body)
    .then((response) => {
      if (response.data.success) {
        message = "messages.addClinicSuccess";
      } else {
        message = response.data.message ? response.data.message : errorMessage;
        error = true;
      }
    })
    .catch((e) => {
      message = e.message ? e.message : errorMessage;
      error = true;
    });

  if (error) {
    yield put(showErrorMessage(message));
  } else {
    yield put(showSweetSuccessMessage(message));
  }
  yield put({
    type: ORG_CREATED,
    payload: { error },
  });
}

export function* editOrg(action) {
  const clinic = action.payload.clinic;
  const newClinic = organizationToOrganizationListItem(clinic);
  const newClinicDB = {
    ...newClinic,
    created_by: clinic.createdBy,
    created_at: clinic.createdAt,
    id: clinic.id,
  };
  const newClinicModified = {
    clinic: newClinicDB,
    user: clinic.adminInfo,
  };
  const pathTemplate = "/api";
  const myInit = {
    body: newClinicModified,
  };
  try {
    const res = yield orgsApiClient.put(pathTemplate, myInit);
    if (res.success) {
      const message = "messages.editClinicSuccess";
      let adminInfo = clinic.adminInfo;
      if (adminInfo) {
        if (action.payload.loggedInUser.id === clinic.adminInfo.id) {
          let groupName = "";
          if (adminInfo.account_roles["orgAdmin"]) {
            groupName += "org-admins";
            groupName += "-";
          }
          if (adminInfo.account_roles["operator"]) {
            groupName += "operators";
            groupName += "-";
          }
          if (adminInfo.account_roles["doctor"]) {
            groupName += "doctors";
            groupName += "-";
          }
          let permissions = [...psConverter(groupName)];
          let newUser = { ...action.payload.loggedInUser, permissions };
          localStorage.setItem("loggedInUser", JSON.stringify(newUser));
          yield put(setLoggedInUser(newUser));
        }
      }
      yield put(showSuccessMessage(message));
    } else yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }

  yield put({
    type: EDIT_ORG_REQUEST_DONE,
    payload: { error: false },
  });
}

function* getUserDetails(id) {
  const pathTemplate = `/api/details/${id}`;
  const myInit = {};
  try {
    const res = yield usersApiClient.post(pathTemplate, myInit);
    if (res.success && res.payload.length) {
      const userDetails = res.payload;
      return {
        email: userDetails[0].email,
        firstName: userDetails[0].first_name,
        lastName: userDetails[0].last_name,
        // orgId: userDetails[0].Value,
        phoneNumber: userDetails[0].phone_number,
        account_roles: userDetails[0].account_roles || "",
      };
    }
    return { error: true };
  } catch (error) {
    return { error: true };
  }
}

export function* getOrgById(action) {
  const idObj = { id: action.payload };
  let org = null;
  let message = "";
  let error = false;
  let returnedOrg = null;
  let pathTemplate = "/api";
  const myInit = {
    queryStringParameters: {
      data: JSON.stringify(idObj),
    },
  };
  const res = yield orgsApiClient.get(pathTemplate, myInit);
  if (!res.success) {
    message = res.message || errorMessage;
    return yield put(showErrorMessage(message));
  }
  org = res.payload;
  if (!error && org.adminId) {
    const response = yield call(getUserDetails, org.adminId.user_id);
    let { error, ...adminInfo } = response;
    if (!error) {
      adminInfo.id = org.adminId.user_id;
      returnedOrg = {
        ...org,
        adminInfo,
      };
    } else {
      error = true;
      message = errorMessage;
    }
  }
  yield put({
    type: GET_ORG_BY_ID_RETURNED,
    payload: { org: returnedOrg, error },
  });
}

export function* deleteOrg(action) {
  const orgIdObj = { id: action.payload };
  const pathTemplate = "/api";
  const myInit = {
    queryStringParameters: {
      data: JSON.stringify(orgIdObj),
    },
  };
  try {
    const res = yield orgsApiClient.delete(pathTemplate, myInit);
    if (res.success) {
      const message = "messages.deleteClinicSuccess";
      yield put({
        type: DELETE_ORG_REQUEST_DONE,
        payload: { id: action.payload },
      });
      return yield put(showSuccessMessage(message));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getAllUsersInOrg(action) {
  let pathTemplate = "/api/users-in-org";
  let usersRetrieved = [];
  let message = "";
  const myInit = {
    queryStringParameters: { data: JSON.stringify(action.payload) },
  };
  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      usersRetrieved = res.payload;
    } else {
      message = res.message || errorMessage;
      return yield put(showErrorMessage(message));
    }
    if (usersRetrieved.length !== 0) {
      yield put({
        type: GET_ALL_USERS_IN_ORG_RETURNED,
        payload: usersRetrieved,
      });
    }
  } catch (e) {}
}
export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_ORGS_REQUEST, getOrgsRequest),
    yield takeEvery(GET_ORGANIZATION_TYPES, getOrganizationTypesRequest),
    yield takeEvery(GET_SPECIALTIES, getSpecialityByOrg),
    yield takeEvery(CREATE_ORG_REQUEST, createOrgRequest),
    yield takeEvery(GET_ORG_BY_ID, getOrgById),
    yield takeEvery(DELETE_ORG_REQUEST, deleteOrg),
    yield takeEvery(ORGS_SEARCH_REQUEST, searchOrgs),
    yield takeEvery(EDIT_ORG_REQUEST, editOrg),
    yield takeEvery(GET_ALL_USERS_IN_ORG, getAllUsersInOrg),
  ]);
}
