import {
  UPDATE_ORG_BASIC_INFO,
  UPDATE_ADMIN_INFO,
  CREATE_ORG_REQUEST,
  GET_ORGS_REQUEST,
  UPDATE_ORGS_FILTERS,
  GET_ORG_BY_ID,
  RESET_EDIT_ORG_MODE,
  DELETE_ORG_REQUEST,
  EDIT_ORG_REQUEST,
  UPDATE_CONFIRM_PASSWORD,
  SHOW_MESSAGE,
  ENABLE_ADMIN_CREATE,
  ORGS_SEARCH_REQUEST,
  CHECK_TERMS_CONDITIONS,
  RESET_ERROR,
  UPDATE_ADMIN_ERRORS,
  UPDATE_BASIC_ERRORS,
  ENABLE_ORG_ADMIN_EDIT,
  SHOW_EDIT_MESSAGE,
  GET_ORGANIZATION_TYPES,
  GET_SPECIALTIES,
  GET_ORGANIZATION_TYPES_SUCCESS,
  GET_SPECIALTIES_SUCCESS,
  GET_ALL_USERS_IN_ORG,
} from "../constants/actionTypes";

export const updateOrgBasicInfo = (orgInfo) => {
  return {
    type: UPDATE_ORG_BASIC_INFO,
    payload: orgInfo,
  };
};

export const updateAdminInfo = (adminInfo) => {
  return {
    type: UPDATE_ADMIN_INFO,
    payload: adminInfo,
  };
};

export const updateConfirmPassword = (confirmPassword) => {
  return {
    type: UPDATE_CONFIRM_PASSWORD,
    payload: confirmPassword,
  };
};

export const createOrganization = (org) => {
  return {
    type: CREATE_ORG_REQUEST,
    payload: org,
  };
};

export const getOrgs = (orgFilter) => {
  return {
    type: GET_ORGS_REQUEST,
    payload: orgFilter,
  };
};

export const searchOrgs = (orgName) => {
  return {
    type: ORGS_SEARCH_REQUEST,
    payload: orgName,
  };
};

export const updateOrgsFilters = (filterId) => {
  return {
    type: UPDATE_ORGS_FILTERS,
    payload: filterId,
  };
};

export const resetEditOrgMode = () => {
  return {
    type: RESET_EDIT_ORG_MODE,
  };
};

export const checkTerms = (value) => {
  return {
    type: CHECK_TERMS_CONDITIONS,
    payload: value,
  };
};

export const getOrgById = (id) => {
  return {
    type: GET_ORG_BY_ID,
    payload: id,
  };
};

export const deleteOrg = (id) => {
  return {
    type: DELETE_ORG_REQUEST,
    payload: id,
  };
};

export const editOrg = (org) => {
  return {
    type: EDIT_ORG_REQUEST,
    payload: org,
  };
};

export const enableAdminCreate = (enable) => {
  return {
    type: ENABLE_ADMIN_CREATE,
    payload: enable,
  };
};

export const showMessage = (obj) => {
  return {
    type: SHOW_MESSAGE,
    payload: obj,
  };
};

export const showEditMessage = (obj) => {
  return {
    type: SHOW_EDIT_MESSAGE,
    payload: obj,
  };
};

export const resetError = () => {
  return {
    type: RESET_ERROR,
  };
};

export const updateAdminErrors = (error) => {
  return {
    type: UPDATE_ADMIN_ERRORS,
    payload: error,
  };
};

export const updateBasicErrors = (error) => {
  return {
    type: UPDATE_BASIC_ERRORS,
    payload: error,
  };
};

export const enableOrgAdminEdit = (enable) => {
  return {
    type: ENABLE_ORG_ADMIN_EDIT,
    payload: enable,
  };
};
export const getOrganizationTypes = () => {
  return {
    type: GET_ORGANIZATION_TYPES,
  };
};
export const getOrganizationTypesSuccess = (orgTypes) => {
  return {
    type: GET_ORGANIZATION_TYPES_SUCCESS,
    payload: orgTypes,
  };
};

export const getSpecialtiesTypes = (orgTypeId) => {
  return {
    type: GET_SPECIALTIES,
    payload: orgTypeId,
  };
};

export const getSpecialtiesTypesSuccess = (specialties) => {
  return {
    type: GET_SPECIALTIES_SUCCESS,
    payload: specialties,
  };
};

export const getAllUsersInOrg = (payload) => {
  return {
    type: GET_ALL_USERS_IN_ORG,
    payload,
  };
};
