import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { pathConverter } from '../../app/core/utils/pathConverter';
import { withRouter } from 'react-router-dom';

const pageTitle = ({ history }) => {
  return (
      <h3 style={{ color: 'white' }}><IntlMessages id={pathConverter(history.location.pathname)} /></h3>
  );
};

export default withRouter(pageTitle);

