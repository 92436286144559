import React, { Component } from 'react';
import {
	withStyles,
	TextField,
	Divider,
	Grid,
	Link,
	Checkbox
} from '@material-ui/core';
import CvModal from '../../../../core/components/cv-modal/CvModal';
import CustomScrollbars from '../../../../../util/CustomScrollbars';
import { ReactComponent as Check } from '../../../../../assets/svg/check.svg';
import IntlMessages from '../../../../../util/IntlMessages';
import TermsConditionsEn from '../../../../core/utils/termsEn';

const styles = theme => ({
	component: {
		margin: theme.spacing(2)
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		marginRight: theme.spacing(2)
	},
	header: {
		margin: 0,
		marginLeft: theme.spacing(1)
	},
	check: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginBottom: theme.spacing(1)
	},
	inputLabel: {
		marginLeft: theme.spacing(4)
	},
	select: {
		marginLeft: theme.spacing(4)
	},
	divider: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2)
	},
	checkStyle: {
		width: 20,
		height: 20
	}
});

const defaultOrg = {
	basicInfo: {
		legalName: '',
		status: '',
		phoneNumber: '',
		clinicType: '',
		address1: '',
		address2: '',
		city: '',
		province: '',
		postalCode: '',
	},
	adminInfo: {
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
		password: ''
	}
}

class OrgSummary extends Component {
	constructor(props) {
		super(props);
		const draftOrg = props.draftOrg ? props.draftOrg : defaultOrg;
		const { basicInfo, adminInfo } = draftOrg;
		this.state = {
			draftOrg,
			basicInfo,
			adminInfo,
			open: false
		}
	}

	render() {
		const { classes, edit, adminCreate, width } = this.props;
		const web = width > 700 ? true : false;

		return (
			<React.Fragment>
				<CustomScrollbars className="module-list-scroll scrollbar"
					style={{ height: edit || adminCreate ? 'calc(100vh - 360px)' : 'calc(100vh - 270px)' }}>
					<div className={classes.component}>
						<div className={classes.check}>
							<Check className={classes.checkStyle} />
							<h3 className={classes.header}><b><IntlMessages id="org.info" /></b></h3>
						</div>
						<Grid
							container
							spacing={3}
						>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									label={<IntlMessages id="org.legalName" />}
									value={this.state.basicInfo.legalName}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-practices-name"
									label={<IntlMessages id="org.phoneNumber" />}
									value={this.state.basicInfo.phoneNumber}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-middle-1-name"
									label={<IntlMessages id="org.address1" />}
									value={this.state.basicInfo.address1.street}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-address-1-name"
									label={<IntlMessages id="org.address2" />}
									value={this.state.basicInfo.address2}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-country"
									label={<IntlMessages id="user.country" />}
									value={this.state.basicInfo.country}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-address-2-name"
									label={<IntlMessages id="org.city" />}
									value={this.state.basicInfo.city}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-state-name"
									label={<IntlMessages id="org.province" />}
									value={this.state.basicInfo.province}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-city-name"
									label={<IntlMessages id="org.postalCode" />}
									value={this.state.basicInfo.postalCode}
									fullWidth
									type="number"
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} className={classes.divider}>
							<Divider />
						</Grid>
						<div className={classes.check}>
							<Check className={classes.checkStyle} />
							<h3 className={classes.header}><b><IntlMessages id="org.adminInfo" /></b></h3>
						</div>
						<Grid
							container
							spacing={3}
						>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-name"
									label={<IntlMessages id="user.firstName" />}
									value={this.state.adminInfo.firstName}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-last-name"
									label={<IntlMessages id="user.lastName" />}
									value={this.state.adminInfo.lastName}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-email-name"
									label={<IntlMessages id="user.email" />}
									type="email"
									value={this.state.adminInfo.email}
									style={{ marginBottom: -15 }}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
							<Grid item xs={web ? 6 : 12}>
								<TextField
									id="standard-phoneNumber-name"
									label={<IntlMessages id="user.phoneNumber" />}
									value={this.state.adminInfo.phoneNumber}
									fullWidth
									InputProps={{ readOnly: true }}
									variant="standard"
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} className={classes.divider}>
							<Divider />
						</Grid>
						<div className={classes.check}>
							<Check className={classes.checkStyle} />
							<h3 className={classes.header} color="text-primary"><b><IntlMessages id="org.terms" /></b></h3>
						</div>
						<div style={{ marginLeft: -10, marginTop: 5 }}>
							<Checkbox
								color="primary"
								checked={this.props.termsChecked}
								onChange={(e) => this.props.checkTerms(e.target.checked)}
							/>
							<span><IntlMessages id="user.agreeWith" /> <Link component="button" variant="body2" onClick={() => this.setState({ open: true })}><IntlMessages id="org.terms" /></Link>.</span>
						</div>
					</div>
				</CustomScrollbars>
				<CvModal
					title="org.terms"
					open={this.state.open}
					showButtons={true}
					fullScreen={window.innerWidth > 700 ? false : true}
					cancelLabel="default.close"
					saveLabel="default.accept"
					isSaveEnabled={true}
					onClose={() => this.setState({ open: false })}
					onSave={() => this.setState({ open: false }, () => this.props.checkTerms(true))}
				>
					<TermsConditionsEn />
				</CvModal>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(OrgSummary);