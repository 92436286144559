import Amplify, { Auth } from "aws-amplify";
import { endpoints } from "./urls";
import { amplifyConfigurations } from './amplify-configurations';

const {
  resUrl,
  usersBaseUrl,
  eventsUrl,
  orgUrl,
  paymentUrl,
  coreUrl,
} = endpoints;

Amplify.configure({
  aws_project_region: "eu-west-2",
  Auth: amplifyConfigurations,
  API: {
    endpoints: [
      {
        name: "UsersEndPoint",
        endpoint: usersBaseUrl,
        custom_header: async () => getAuthorizationToken(),
      },
      {
        name: "OrgsEndPoint",
        endpoint: orgUrl,
        custom_header: async () => getAuthorizationToken(),
      },
      {
        name: "ResEndPoint",
        endpoint: resUrl,
        custom_header: async () => getAuthorizationToken(),
      },
      {
        name: "PaymentEndPoint",
        endpoint: paymentUrl,
        custom_header: async () => getAuthorizationToken(),
      },
      {
        name: "CoreEndPoint",
        endpoint: coreUrl,
        custom_header: async () => getAuthorizationToken(),
      },
      {
        name: "EventEndPoint",
        endpoint: eventsUrl,
        custom_header: async () => getAuthorizationToken(),
      },
    ],
  },
});

const getAuthorizationToken = async () => {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const stringifiedLang = localStorage.getItem("locale");
    const language =
      (stringifiedLang &&
        JSON.parse(stringifiedLang) &&
        JSON.parse(stringifiedLang).locale) ||
      "en";
    return {
      token: `Bearer ${token}`,
      language,
      platform: "web",
    };
  } catch (e) {
    console.log(e);
  }
};

export default Auth;
