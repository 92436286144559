import admin from '../app/modules/admin/state/service/adminSaga';
import { all } from 'redux-saga/effects';
import authUserSagas from './Auth';
import clinicsSaga from '../app/modules/my-health/clinics/state/service/clinicsSaga';
import countries from '../sagas/countries';
import eventsSaga from '../app/modules/my-health/events/state/service/eventsSaga';
import orgSaga from '../app/modules/organization/state/service/orgSaga';
import reservationsSaga from '../app/modules/reservation/state/service/reservationsSaga';
import summarySaga from '../app/modules/my-health/summary/state/service/summarySaga';
import transactions from '../app/modules/admin/state/service/transactionsSaga';
import dailyReservations from '../app/modules/admin/state/service/dailyReservationsSaga';
import dailyChatReservations from '../app/modules/admin/state/service/dailyChatReservationsSaga';
import userSaveSaga from '../app/modules/user/state/service/saveUserSaga';
import vitalsSaga from '../app/modules/my-health/events/state/service/vitalsSaga';
import wallet from '../app/modules/wallet/state/service/walletSaga';

export default function* rootSaga(getState) {
    yield all([
        userSaveSaga(),
        authUserSagas(),
        eventsSaga(),
        summarySaga(),
        clinicsSaga(),
        orgSaga(),
        reservationsSaga(),
        vitalsSaga(),
        countries(),
        admin(),
        transactions(),
        dailyReservations(),
        dailyChatReservations(),
        wallet()
    ]);
}
