import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import notifications from './Notifications';
import { countries } from './Countries';
import { users } from '../app/modules/user/state/reducer/users.reducer';
import { saveUser } from '../app/modules/user/state/reducer/userSave.reducer';
import { myHealthEvents } from '../app/modules/my-health/events/state/reducer/events.reducer';
import { mySummary } from '../app/modules/my-health/summary/state/reducer/summary.reducer';
import { clinics } from '../app/modules/my-health/clinics/state/reducer/clinics.reducer';
import { organization } from '../app/modules/organization/state/reducers/organization.reducer';
import { doctor } from '../app/modules/doctor/state/reducers/doctor.reducer';
import { reservations } from '../app/modules/reservation/state/reducers/reservations.reducer';
import { vitalsReducer } from '../app/modules/my-health/events/state/reducer/vitals.reducer';
import { admin } from '../app/modules/admin/state/reducers/admin.reducer';
import { transactionsStats } from '../app/modules/admin/state/reducers/transactionsStats.reducer';
import { dailyReservations } from '../app/modules/admin/state/reducers/dailyReservations.reducer';
import { dailyChatReservations } from '../app/modules/admin/state/reducers/dailyChatReservations.reducer';
import { walletReducer } from '../app/modules/wallet/state/reducer/walletReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  users,
  saveUser,
  myHealthEvents,
  mySummary,
  clinics,
  orgs: organization,
  doctor,
  reservations,
  vitals: vitalsReducer,
  notifications,
  countries,
  admin,
  transactionsStats,
  dailyReservations,
  dailyChatReservations,
  wallet: walletReducer
});
