import { all, call, takeEvery, put } from "redux-saga/effects";
import moment from "moment";
import axios from "axios";
import {
  GET_EVENTS_RETURNED,
  GET_EVENTS,
  ADD_EVENT,
  EVENT_ADDED,
  EVENT_EDIT_REQUEST,
  EVENT_UPDATED,
  NOTE_ADDED,
  ADD_NOTE,
  NOTE_DELETED,
  NOTE_DELETE_REQUEST,
  SAVE_EDIT_REQUEST,
  NOTE_EDITED,
  GET_NOTES_REQUEST,
  GET_NOTES_RETURNED,
  TRANSLATE_REQUEST,
  TRANSLATE_DONE,
  SAVE_COMMENT_REQUESTED,
  COMMENT_SAVED,
  GET_COMMENTS_REQUEST,
  COMMENTS_RETURNED,
  DELETE_COMMENT_REQUEST,
  COMMENT_DELETED,
  SEARCH_EVENTS,
  SEARCH_RESULT,
} from "../constants/ActionTypes";
import { resetNoteModal } from "../action/eventActions";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../actions/Notifications";
import { Event } from "../models/Event";
import Preconditions from "../../../../../core/utils/Preconditions";
import { eventToListItemConverter } from "./../converter/eventToListItemConverter";
import { noteToNoteListItemConverter } from "./../converter/noteToNoteListItemConverter";
import { commentToCommentListItemConverter } from "./../converter/commentToCommentItemConverter";
import {
  getTokens,
  getEventsApiGWClient,
  getUsersApiGWClient,
} from "../../../../../core/utils/auth-functions";

const errorMessage = "Something went wrong.";

/** EVENTS FUNCTIONS **/
function convertObject(data) {
  let obj = JSON.parse(JSON.stringify(data));
  return obj;
}

export function* addEventRequest(action) {
  Preconditions.shouldBeDefined(action, "Add Event Action must be defined");
  const { userId, createdBy, title, type } = action.payload;
  const event = new Event({
    title,
    type,
    createdBy,
    userId,
  });

  const createdEvent = { ...event };
  let msg = "";
  let error = false;

  let body = convertObject(event);
  let pathTemplate = "/api";
  let method = "post";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);

    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          msg = "messages.addCaseSuccess";
          createdEvent.id = response.data.payload.id;
        } else {
          error = true;
          msg = response.data.message ? response.data.message : errorMessage;
        }
      })
      .catch((e) => {
        error = true;
        msg = e.message ? e.message : errorMessage;
      });
  } else {
    error = true;
    msg = errorMessage;
  }

  if (error) {
    yield put(showErrorMessage(msg));
  } else {
    yield put(showSuccessMessage(msg));
  }

  yield put({
    type: EVENT_ADDED,
    payload: { event: createdEvent, error },
  });
}

export function* updateEvent(action) {
  Preconditions.shouldBeDefined(action, "Update Event Action must be defined");
  const event = action.payload;
  let msg = "";
  let error = false;

  let body = convertObject(event);
  let pathTemplate = "/api";
  let method = "put";
  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          msg = "Case updated successfully.";
        } else {
          error = true;
          msg = response.data.message ? response.data.message : errorMessage;
        }
      })
      .catch((_) => {
        error = true;
        msg = errorMessage;
      });
  } else {
    error = true;
    msg = errorMessage;
  }

  if (error) {
    yield put(showErrorMessage(msg));
  }

  yield put({
    type: EVENT_UPDATED,
    payload: { event, error },
  });
}

function* fetchEvents(id, filter, startDate, endDate) {
  let additionalParams = {
    queryParams: {
      data: JSON.stringify({ filter, id, startDate, endDate }),
    },
  };

  let pathTemplate = "/api";
  let method = "GET";
  let events = [];
  let error = false;

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient2 = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient2
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          events = response.data.payload;
        } else {
          error = true;
        }
      })
      .catch((_) => {
        error = true;
      });
  } else {
    error = true;
  }
  return [error, events];
}

function* getUserDetails(id) {
  let error = false;
  let userDetails = null;
  let code = "";

  let body = convertObject({ username: id });
  let pathTemplate = "/api/details";
  let method = "post";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClientUsers2 = getUsersApiGWClient(
      tokens[0],
      tokens[1],
      tokens[2]
    );
    yield apigClientUsers2
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          userDetails = response.data.payload;
        } else {
          error = true;
          code = response.data.code;
        }
      })
      .catch((e) => {
        error = true;
        code = e.code || "catch error";
      });
  } else {
    error = true;
    code = "getting tokens";
  }
  let lastName,
    firstName = "";
  if (userDetails && userDetails.length && !error) {
    firstName = userDetails[0].first_name;
    lastName = userDetails[0].last_name;
  }
  return [error, firstName + " " + lastName, code];
}

export function* getEventsRequest(action) {
  Preconditions.shouldBeDefined(action, "Get Event Action must be defined");
  const { userId, timeFilters, filters } = action.payload;
  const selectedFilter = filters[0].items.find((filter) => {
    return filter.isSelected === true;
  });
  let events = [];
  let message = "";
  let error = false;

  const response = yield fetchEvents(
    userId,
    selectedFilter.id,
    timeFilters.startDate,
    timeFilters.endDate
  );
  const respError = response[0];
  const resp = response[1];
  if (!respError[0]) {
    for (let i = 0; i < resp.length; i++) {
      const event = resp[i];
      if (
        moment(event.event_date).isBetween(
          timeFilters.startDate,
          timeFilters.endDate,
          "days",
          "[]"
        )
      ) {
        const eventsResp = yield call(getUserDetails, event.created_by);
        if (!eventsResp[0]) {
          const returnedEvent = event;
          returnedEvent.createdByName = eventsResp[1];
          const convertedEvent = eventToListItemConverter(returnedEvent);
          events.push(convertedEvent);
        } else {
          if (!(eventsResp[2] === "TooManyRequestsException")) {
            error = true;
            message = "Something went wrong fetching Events";
            break;
          }
        }
      }
    }
  } else {
    error = true;
    message = errorMessage;
  }

  if (error) {
    yield put(showErrorMessage(message));
  }

  yield put({
    type: GET_EVENTS_RETURNED,
    payload: { events, error },
  });
}

export function* searchEvents(action) {
  Preconditions.shouldBeDefined(action, "search events Action must be defined");
  const { name, userId } = action.payload;
  let message = "";
  let error = false;
  let events = [];
  let resp = [];

  let additionalParams = {
    queryParams: {
      data: JSON.stringify({ title: name, userId }),
    },
  };
  let pathTemplate = "/api/search";
  let method = "GET";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          resp = response.data.payload;
        } else {
          error = true;
          message = errorMessage;
        }
      })
      .catch((_) => {
        error = true;
        message = errorMessage;
      });

    for (let i = 0; i < resp.length; i++) {
      const event = resp[i];
      const response = yield call(getUserDetails, event.created_by);
      if (!response[0]) {
        const returnedEvent = event;
        returnedEvent.createdByName = response[1];
        const convertedEvent = eventToListItemConverter(returnedEvent);
        events.push(convertedEvent);
      } else {
        if (!(response[2] === "TooManyRequestsException")) {
          error = true;
          message = "Something went wrong fetching Events";
          break;
        }
      }
    }
  } else {
    error = true;
    message = errorMessage;
  }

  if (error) {
    yield put(showErrorMessage(message));
  }

  yield put({
    type: SEARCH_RESULT,
    payload: { events, error },
  });
}

/***************************** NOTES FUNCTIONS *********************************/

export function* addNoteRequest(action) {
  Preconditions.shouldBeDefined(action, "Add Note Action must be defined");
  const { note, event, user } = action.payload;

  const newNote = {
    ...note,
    createdBy: user.id,
    eventId: event.id,
    userId: event.userId,
  };

  let createdNote;
  let message = "";
  let error = false;
  let convertedNote = null;

  let body = convertObject({ newNote, type: "general" });

  let pathTemplate = "/api/notes";
  let method = "post";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          message = "messages.addNoteSuccess";
          createdNote = response.data.payload;
        } else {
          message = errorMessage;
          error = true;
        }
      })
      .catch((_) => {
        message = errorMessage;
        error = true;
      });
    const createdByName =
      user.basicInfo.firstName + " " + user.basicInfo.lastName;
    const listItemNote = { ...createdNote, createdByName };
    convertedNote = noteToNoteListItemConverter(listItemNote);
  } else {
    message = errorMessage;
    error = true;
  }

  if (error) {
    yield put(showErrorMessage(message));
  } else {
    yield put(showSuccessMessage(message));
  }

  yield put({
    type: NOTE_ADDED,
    payload: { convertedNote, error },
  });

  yield put(resetNoteModal());
}

function* fetchNotesDB(generalNotes) {
  generalNotes.sort((left, right) => {
    return moment.utc(right.created_at).diff(moment.utc(left.created_at));
  });

  let convertedNotes = [];
  let error = false;
  for (let i = 0; i < generalNotes.length; i++) {
    const response = yield call(getUserDetails, generalNotes[i].created_by);
    if (!response[0]) {
      const listItemNote = {
        ...generalNotes[i],
        createdByName: response[1],
        commentsLength: generalNotes[i].commentsLength,
        comments: [],
      };
      const convertedNote = noteToNoteListItemConverter(listItemNote);
      convertedNotes.push(convertedNote);
    } else {
      if (!(response[2] === "TooManyRequestsException")) {
        error = true;
        break;
      }
    }
  }
  return [error, convertedNotes];
}

function* fetchVitalsNotesDB(vitalsRecords) {
  let records = [];
  let note = {};
  let convertedNotes = [];
  let error = false;
  for (let i = 0; i < vitalsRecords.length; i++) {
    let current = vitalsRecords[i];
    if (
      i === vitalsRecords.length - 1 ||
      current.note_id !== vitalsRecords[i + 1].note_id
    ) {
      records.push(current);
      note = {
        id: current.note_id,
        createdAt: current.created_at,
        createdBy: current.created_by,
        records,
      };
      let response = yield call(getUserDetails, current.created_by);
      if (!response[0]) {
        const listItemNote = { ...note, createdByName: response[1] };
        convertedNotes.push(listItemNote);
        records = [];
        note = {};
      } else {
        if (!(response[2] === "TooManyRequestsException")) {
          error = true;
          break;
        }
      }
    } else {
      records.push(current);
    }
  }

  convertedNotes.sort((left, right) => {
    return moment.utc(right.created_at).diff(moment.utc(left.created_at));
  });

  return [error, convertedNotes];
}

export function* getNotes(action) {
  Preconditions.shouldBeDefined(action, "get Notes Action must be defined");
  const { eventId } = action.payload;
  let notes = [];
  let vitals = [];
  let generalNotes = [];
  let vitalsNotes = [];
  let message = "";

  let additionalParams = {
    queryParams: {
      data: JSON.stringify({ eventId }),
    },
  };
  let pathTemplate = "/api/notes";
  let method = "GET";
  let error = false;
  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          notes = response.data.payload.notes;
          vitals = response.data.payload.vitalsNotes;
        } else {
          message = response.data.message
            ? response.data.message
            : "Something went wrong  getting notes.";
          error = true;
        }
      })
      .catch((e) => {
        message = e.message
          ? e.message
          : "Something went wrong  getting notes. try again later.";
        error = true;
      });
  } else {
    message = errorMessage;
    error = true;
  }
  if (!error) {
    const response1 = yield call(fetchNotesDB, notes);
    const response2 = yield call(fetchVitalsNotesDB, vitals);
    if (!response1[0] && !response2[0]) {
      generalNotes = response1[1];
      vitalsNotes = response2[1];
    } else {
      error = true;
      message = "Something went wrong getting notes. try again later.";
    }
  }

  if (error) {
    yield put(showErrorMessage(message));
  }

  yield put({
    type: GET_NOTES_RETURNED,
    payload: {
      notes: generalNotes,
      vitalsNotes,
      error,
    },
  });
}

export function* editNote(action) {
  Preconditions.shouldBeDefined(action, "Edit Note Action must be defined");
  const { note, type } = action.payload;

  let message = "";
  let error = false;
  let updatedNote;
  let convertedNote = null;

  let body = convertObject({ newNote: note, type });
  let pathTemplate = "/api/notes";
  let method = "put";
  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          message = "messages.editNoteSuccess";
          updatedNote = response.data.payload;
        } else {
          message = response.data.message
            ? response.data.message
            : errorMessage;
          error = true;
        }
      })
      .catch((_) => {
        message = errorMessage;
        error = true;
      });

    const listItemNote = { ...updatedNote, createdByName: note.createdByName };
    if (type === "general") {
      listItemNote.comments = note.comments;
      listItemNote.commentsLength = note.commentsLength;
      convertedNote = noteToNoteListItemConverter(listItemNote);
    }
  } else {
    message = errorMessage;
    error = true;
  }

  if (error) {
    yield put(showErrorMessage(message));
  } else {
    yield put(showSuccessMessage(message));
  }

  yield put({
    type: NOTE_EDITED,
    payload: { convertedNote, type, error },
  });

  yield put(resetNoteModal());
}

export function* deleteNote(action) {
  Preconditions.shouldBeDefined(action, "Delete Note Action must be defined");
  const { note, type } = action.payload;
  const request = {
    id: note.id,
    type,
  };
  let message = "";
  let error = false;

  let additionalParams = {
    queryParams: {
      data: JSON.stringify(request),
    },
  };
  let pathTemplate = "/api/notes";
  let method = "delete";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (!response.data.success) {
          message = response.data.message
            ? response.data.message
            : errorMessage;
          error = true;
        } else {
          message = "messages.deleteNoteSuccess";
        }
      })
      .catch((_) => {
        message = errorMessage;
        error = true;
      });
  } else {
    message = errorMessage;
    error = true;
  }

  if (error) {
    yield put(showErrorMessage(message));
  } else {
    yield put(showSuccessMessage(message));
  }

  yield put({
    type: NOTE_DELETED,
    payload: { note, type, error },
  });
}

export function* translate(action) {
  let message = "";
  let error = false;
  const { text, source, target } = action.payload;
  const translateObject = { q: text, source, target };

  let translatedText = "";
  yield axios
    .post(
      "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCNH9l1X8xyi-KHVeiAVIoXbFgkExtpws0",
      convertObject(translateObject)
    )
    .then((response) => {
      translatedText = response.data.data.translations[0].translatedText;
    })
    .catch((e) => {
      error = true;
      message = e.message
        ? e.message
        : "Something went wrong translating text.";
    });

  if (error) {
    yield put(showErrorMessage(message));
  }

  yield put({
    type: TRANSLATE_DONE,
    payload: translatedText,
  });
}

/**************************** COMMENTS FUNCTIONS ************************************/

export function* addCommentRequest(action) {
  Preconditions.shouldBeDefined(action, "Add Comment Action must be defined");
  const { text, noteId, user } = action.payload;
  const newComment = {
    text,
    noteId,
    createdBy: user.id,
  };
  let message = "";
  let error = false;
  let createdComment;
  let convertedComment = null;

  let body = convertObject(newComment);
  let pathTemplate = "/api/notes/comments";
  let method = "post";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          message = "Comment added successfully.";
          createdComment = response.data.payload;
        } else {
          message = response.data.message
            ? response.data.message
            : errorMessage;
          error = true;
        }
      })
      .catch((_) => {
        message = errorMessage;
        error = true;
      });

    const createdByName =
      user.basicInfo.firstName + " " + user.basicInfo.lastName;
    const listItemComment = { ...createdComment, createdByName };
    convertedComment = commentToCommentListItemConverter(listItemComment);
  } else {
    message = errorMessage;
    error = true;
  }

  if (error) {
    yield put(showErrorMessage(message));
  }

  yield put({
    type: COMMENT_SAVED,
    payload: { convertedComment, error },
  });
}

function* fetchCommentsDB(comments) {
  comments.sort((left, right) => {
    return moment.utc(left.created_at).diff(moment.utc(right.created_at));
  });

  let convertedComments = [];
  let error = false;
  for (let i = 0; i < comments.length; i++) {
    const response = yield call(getUserDetails, comments[i].created_by);
    if (!response[0]) {
      const listItemComment = { ...comments[i], createdByName: response[1] };
      const convertedComment = commentToCommentListItemConverter(
        listItemComment
      );
      convertedComments.push(convertedComment);
    } else {
      if (!(response[2] === "TooManyRequestsException")) {
        error = true;
        break;
      }
    }
  }

  return [error, convertedComments];
}

export function* getComments(action) {
  Preconditions.shouldBeDefined(action, "get Comments Action must be defined");
  const noteId = action.payload;
  let comments = [];
  let message = "";
  let error = false;

  let additionalParams = {
    queryParams: {
      data: JSON.stringify({ noteId }),
    },
  };
  let pathTemplate = "/api/notes/comments";
  let method = "GET";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);

    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          comments = response.data.payload;
          message = "comments fetched successfully";
        } else {
          message = response.data.message
            ? response.data.message
            : errorMessage;
          error = true;
        }
      })
      .catch((_) => {
        message = errorMessage;
        error = true;
      });
  } else {
    message = errorMessage;
    error = true;
  }
  if (!error) {
    const response = yield call(fetchCommentsDB, comments);
    if (!response[0]) {
      comments = response[1];
    } else {
      error = true;
      message = errorMessage;
    }
  }

  if (error) {
    yield put(showErrorMessage(message));
  }

  yield put({
    type: COMMENTS_RETURNED,
    payload: { comments, noteId, error },
  });
}

export function* deleteCommentRequest(action) {
  Preconditions.shouldBeDefined(
    action,
    "Delete Comment Action must be defined"
  );
  const { comment, type } = action.payload;
  let message = "";
  let error = false;

  let additionalParams = {
    queryParams: {
      data: JSON.stringify({ id: comment.id }),
    },
  };
  let pathTemplate = "/api/notes/comments";
  let method = "delete";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);

    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (!response.data.success) {
          message = response.data.message
            ? response.data.message
            : errorMessage;
          error = true;
        }
      })
      .catch((_) => {
        message = errorMessage;
        error = true;
      });
  } else {
    message = errorMessage;
    error = true;
  }

  if (error) {
    yield put(showErrorMessage(message));
  }

  yield put({
    type: COMMENT_DELETED,
    payload: { comment, type, error },
  });
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_EVENTS, getEventsRequest),
    yield takeEvery(ADD_EVENT, addEventRequest),
    yield takeEvery(EVENT_EDIT_REQUEST, updateEvent),
    yield takeEvery(ADD_NOTE, addNoteRequest),
    yield takeEvery(NOTE_DELETE_REQUEST, deleteNote),
    yield takeEvery(SAVE_EDIT_REQUEST, editNote),
    yield takeEvery(GET_NOTES_REQUEST, getNotes),
    yield takeEvery(TRANSLATE_REQUEST, translate),
    yield takeEvery(SAVE_COMMENT_REQUESTED, addCommentRequest),
    yield takeEvery(DELETE_COMMENT_REQUEST, deleteCommentRequest),
    yield takeEvery(GET_COMMENTS_REQUEST, getComments),
    yield takeEvery(SEARCH_EVENTS, searchEvents),
  ]);
}
