import {
  GET_TRANSACTIONS_STATS,
  GET_TRANSACTIONS_STATS_SUCCESS,
  GET_TRANSACTIONS_STATS_FAILED,
  GET_TRANSACTION_BY_ID_REQUEST,
  GET_TRANSACTION_BY_ID_SUCCESS,
  GET_TRANSACTION_BY_ID_FAILED,
  REFUND_TRANSACTION_REQUEST,
  REFUND_TRANSACTION_SUCCESS,
  REFUND_TRANSACTION_FAILED,
  PAYOUT_TRANSACTION_REQUEST,
  PAYOUT_TRANSACTION_SUCCESS,
  PAYOUT_TRANSACTION_FAILED
} from "../constants/actionTypes";

const initialState = {
  // transactions stats
  transactions: [],
  transactionsLoading: false,
  tableFields: [
    {
      label: 'TrStatstable.username'
    },
    {
      label: 'TrStatstable.userPhone'
    },
    {
      label: 'TrStatstable.providerName'
    },
    {
      label: 'TrStatstable.providerPhone'
    },
    {
      label: 'TrStatstable.amount'
    },
    {
      label: 'TrStatstable.paymentMethod'
    },
    {
      label: 'TrStatstable.status'
    },
    {
      label: 'TrStatstable.createdOn'
    }
  ],
  offset: 0,

  // get transaction by id
  transactionByIdLoading: false,
  transactionDetails: null
}

export const transactionsStats = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_STATS:
      return {
        ...state,
        transactionsLoading: true
      };

    case GET_TRANSACTIONS_STATS_SUCCESS:
      return {
        ...state,
        transactionsLoading: false,
        transactions: action.payload
      };

    case GET_TRANSACTIONS_STATS_FAILED:
      return {
        ...state,
        transactionsLoading: false
      };

    /*** get transaction by id types ***/
    case GET_TRANSACTION_BY_ID_REQUEST:
      return {
        ...state,
        transactionByIdLoading: true
      }

    case GET_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state,
        transactionByIdLoading: false,
        transactionDetails: action.payload
      }

    case GET_TRANSACTION_BY_ID_FAILED:
      return {
        ...state,
        transactionByIdLoading: false
      }

    /*** refund transaction by id types ***/
    case REFUND_TRANSACTION_REQUEST:
      return {
        ...state
      }

    case REFUND_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionDetails: action.payload
      }

    case REFUND_TRANSACTION_FAILED:
      return {
        ...state
      }

    /*** refund transaction by id types ***/
    case PAYOUT_TRANSACTION_REQUEST:
      return {
        ...state
      }

    case PAYOUT_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionDetails: action.payload
      }

    case PAYOUT_TRANSACTION_FAILED:
      return {
        ...state
      }

    default:
      return state;
  }
}