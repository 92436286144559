import {
  ADD_USERS_TO_QUEUE_REQUESTED,
  ADD_USERS_TO_QUEUE_RETURNED,
  CHANGE_USERS_STATUS_REQUEST,
  CHANGE_USERS_STATUS_RETURNED,
  DELETE_QUEUE_USER_REQUESTED,
  DELETE_QUEUE_USER_RETURNED,
  DELETE_USER_REQUEST,
  DELETE_USER_RETURNED,
  FINAL_RESERVE,
  GET_ADMIN_FILTERED_USERS,
  GET_DR_DATA_REQUESTED,
  GET_DR_DATA_RETURNED,
  GET_ORG_USERS_REQUEST,
  GET_USER,
  GET_USER_EDIT,
  GET_USER_EDIT_RETURNED,
  GET_USER_RETURNED,
  QUEUE_USERS_REQUESTED,
  QUEUE_USERS_RETURNED,
  RESERVED,
  RESERVE_TIME,
  SAVE_USER,
  SEARCH_USERS,
  TIMINGS_RETURNED,
  UPDATE_USER_EDIT,
  USERS_RETURNED,
  USERS_SEARCH_RETURNED,
  USER_UPDATED,
} from "../action/actionTypes";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  getOrgsApiGWClient,
  getReservationsApiGWClient,
  getTokens,
  getUsersApiGWClient,
} from "../../../../core/utils/auth-functions";
import {
  usersApiClient,
  orgsApiClient,
} from "../../../../core/utils/restClient";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../actions/Notifications";

import { BasicInfo } from "../model/BasicInfo";
import Preconditions from "../../../../core/utils/Preconditions";
import { Reservation } from "../../../reservation/state/model/Reservation";
import { User } from "../model/User";
import { account_roles as ar } from "../../../organization/state/model/organization";
import { checkInternetConnection } from "../../../../core/utils/utils";
import moment from "moment";
import { saveUserSuccess } from "../action/userSaveActions";
import { setLoggedInUser } from "../../../../../actions/Auth";

const errorMessage = "Something went wrong. please try again later.";
const networkMessage = "Please check you internet connection and try again.";

function convertObject(data) {
  let obj = JSON.parse(JSON.stringify(data));
  return obj;
}

export function* searchUsers(action) {
  Preconditions.shouldBeDefined(action, "Search Users Action must be defined");

  let users = [];
  let finalUsers = [];
  let message = "";
  let error = false;

  const keyword = action.payload;

  let additionalParams = {};
  let pathTemplate = `/api/searchusers/${keyword}`;
  let method = "GET";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          users = response.data.payload;
        } else {
          error = true;
          message = response.data.message
            ? response.data.message
            : errorMessage;
        }
      })
      .catch((_) => {
        error = true;
        message = errorMessage;
      });
  } else {
    error = true;
    message = "Authentication error. please refresh the page.";
  }

  if (error) {
    yield put(showErrorMessage(message));
  }

  users.forEach((user) => {
    const basicInfo = new BasicInfo({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phoneNumber: user.phone_number,
      gender: user.gender,
      city: user.city,
    });

    let newUser = null;

    newUser = new User({ id: user.user_id, basicInfo, role: user.role });
    finalUsers.push(newUser);
  });

  yield put({
    type: USERS_SEARCH_RETURNED,
    payload: { users: finalUsers, error },
  });
}

function* createOrgUserWithEmailPassword(
  user,
  password,
  orgId,
  orgName,
  role,
  checked
) {
  let message = errorMessage;
    let group = "";
    let account_roles = {};
    switch (role) {
      case "user":
        group = "users";
        account_roles = new ar({ user: true });
        break;
      case "doctor":
        group = "doctors";
        account_roles = new ar({ doctor: true });
        break;
      case "operator":
        group = "operators";
        account_roles = new ar({ operator: true });
        break;
      default:
        group = "users";
        account_roles = new ar({ user: true });
        break;
    }

    let addedUser = null;
    let error = false;

    const myInit = {
      body: {
        user,
        password,
        group,
        orgName,
        role,
        orgId,
        checked,
        account_roles: JSON.stringify(account_roles),
      },
    };

    let pathTemplate = "/api/adduser";

    try {
      const res = yield usersApiClient.post(pathTemplate, myInit);
      if (res.success) {
        addedUser = res.payload;
        return [
          "User added successfully. Email should be verified before signing in!",
          addedUser,
          account_roles,
        ];
      } else {
        error = true;
        message = res.message;
        return [message, addedUser];
      }
    } catch (err) {
      return [err.message, null];
    }
}

export function* saveUserRequest(action) {
  const { user, role, orgId, password, orgName, checked } = action.payload;

  let msg = "";
  let error = true;
  let orgUser = null;
  let newUser = null;
  if (!checked) {
    user.basicInfo.email = "";
  }

  const values = yield call(
    createOrgUserWithEmailPassword,
    user,
    password,
    orgId,
    orgName,
    role,
    checked
  );

  msg = values[0];
  orgUser = values[1];
  let account_roles = values[2];

  const user2 = { ...user, checked };
  if (orgUser) {
    error = false;
    const userMapping = {
      userId: orgUser.userSub,
      orgId,
      role,
      status: "enrolled",
      user: user2,
      account_roles,
    };

    let body = convertObject(userMapping);
    let pathTemplate = "/api/users";
    let method = "post";

    const tokens = yield call(getTokens);
    if (!tokens[3]) {
      const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
      yield apigClient
        .invokeApi({}, pathTemplate, method, {}, body)
        .then((response) => {
          if (response.data.success) {
            msg = "messages.addUserSuccess";
            orgUser.status = "enrolled";
          } else {
            msg = response.data.message ? response.data.message : errorMessage;
            error = true;
          }
        })
        .catch((_) => {
          msg = errorMessage;
          error = true;
        });
    } else {
      msg = errorMessage;
      error = true;
    }
    if (!error) {
      if (!checked) {
        newUser = {
          ...user,
          id: orgUser.userSub,
          status: orgUser.status,
          canAccess: "false",
        };
      } else {
        newUser = {
          ...user,
          id: orgUser.userSub,
          status: orgUser.status,
          canAccess: "true",
        };
      }
    }
  }

  if (error) {
    yield put(showErrorMessage(msg));
  } else {
    yield put(showSuccessMessage(msg));
  }
  yield put(saveUserSuccess({ error, user: newUser, role }));
}

export function* getUserRequest(action) {
  Preconditions.shouldBeDefined(
    action,
    "GetUser request action should be defined"
  );
  const response = yield call(getUserDetails, action);
  if (!response[1]) {
    yield localStorage.setItem("selectedUser", JSON.stringify(response[0]));
  }

  if (response[1]) {
    yield put(showErrorMessage(response[2]));
  }

  yield put({
    type: GET_USER_RETURNED,
    payload: { newUser: response[0], error: response[1] },
  });
}

export function* getUserForEditRequest(action) {
  Preconditions.shouldBeDefined(
    action,
    "User details action should be defined"
  );
  const response = yield call(getUserDetails, action);

  if (response[1]) {
    yield put(showErrorMessage(response[2]));
  }

  yield put({
    type: GET_USER_EDIT_RETURNED,
    payload: { newUser: response[0], error: response[1] },
  });
}

function* getUserDetails(action) {
  let msg = "";
  let error = false;
  let userDetails = null;
  let newUser = null;
  let status = action.payload.status;
  let pathTemplate = `/api/details/${action.payload.id}`;
  const myInit = {};
  const res = yield usersApiClient.post(pathTemplate, myInit);
  if (!res.success) {
    error = true;
    return [null, true, res.message || errorMessage];
  }
  msg = "User edited successfully";
  userDetails = res.payload;

  if (userDetails && userDetails.length) {
    const basicInfo = new BasicInfo({
      firstName: userDetails[0].first_name,
      middleName: userDetails[0].middle_name,
      lastName: userDetails[0].last_name,
      phoneNumber: userDetails[0].phone_number,
      email: userDetails[0].email,
      gender: userDetails[0].gender,
      dateOfBirth: userDetails[0].birth_date,
      country: userDetails[0].country,
      state: userDetails[0].state,
      city: userDetails[0].city,
    });
    basicInfo.role = userDetails[0].role;
    newUser = new User({
      id: userDetails[0].user_id,
      basicInfo,
      role: userDetails[0].role,
      canAccess: userDetails[0].can_access,
      status,
      account_roles: userDetails[0].account_roles,
    });
  }
  return [newUser, error, msg];
}

export function* updateUserEdit(action) {
  const { user, loggedInUser } = action.payload;
  let msg = "";
  const pathTemplate = "/api/edituser";
  const myInit = {
    body: { 
      user
    }
  };

  try {
    const res = yield usersApiClient.post(pathTemplate, myInit);
    if (!res.success) {
      msg = res.message || errorMessage;
      return yield put(showErrorMessage(msg));
    }
    msg = "messages.editUserSuccess";

    const orgObject = {
      user_id: user.id,
      first_name: user.basicInfo.firstName,
      last_name: user.basicInfo.lastName,
      phone_number: user.basicInfo.phoneNumber,
      // TODO: 
      // Bug Fix: Date of birth is not saved in backend 
      dateOfBirth: user.basicInfo.birth_date,
      city: user.basicInfo.city ? user.basicInfo.city : null,
      country: user.basicInfo.country ? user.basicInfo.country : null,
      state: user.basicInfo.state ? user.basicInfo.state : null,
      gender: user.basicInfo.gender,
    };
    const pathTemplate2 = "/api/users";
    const myInit2 = {
      body: orgObject,
    };
    const res2 = yield orgsApiClient.put(pathTemplate2, myInit2);
    if (!res2.success) {
      msg = res2.message || errorMessage;
      return yield put(showErrorMessage(msg));
    }

    if (loggedInUser.id === user.id) {
      const { basicInfo } = user;
      const newUser = new User({
        ...loggedInUser,
        basicInfo,
      });
      localStorage.setItem("loggedInUser", JSON.stringify(newUser));
      yield put(setLoggedInUser(newUser));
    }

    yield put(showSuccessMessage(msg));
    yield put({
      type: USER_UPDATED,
      payload: { user },
    });
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

// NOT USED
export function* deleteUser(action) {
  const id = action.payload.id;
  const orgId = action.payload.orgId;
  let msg = "";
  const pathTemplate = "/api/users";
  const myInit = {
    queryStringParameters:{
      data: JSON.stringify({
        orgId,
        userId: id,
      })
    }
  }

  try {
    const res = yield orgsApiClient.delete(pathTemplate, myInit);
    if (res.success) {
      msg = "messages.deleteUserSuccess";
      yield put(showSuccessMessage(msg));
      return yield put({
        type: DELETE_USER_RETURNED,
        payload: { id, msg, error: false, deleted: true },
      });
    }
    yield put(showErrorMessage(msg || errorMessage));
  } catch (e) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function polishUsers(usersReturned, list) {
  let finalUsers = [];
  for (let i = 0; i < usersReturned.length; i++) {
    let newUser = null;
    const userAttributes = list
      ? usersReturned[i].Attributes
      : usersReturned[i].UserAttributes;
    let email,
      sub,
      city,
      firstName,
      lastName,
      phoneNumber,
      gender,
      role,
      canAccess,
      account_roles = "";

    // WTF!
    for (let j = 0; j < userAttributes.length; j++) {
      switch (userAttributes[j].Name) {
        case "sub":
          sub = userAttributes[j].Value;
          break;
        case "email":
          email = userAttributes[j].Value;
          break;
        case "name":
          firstName = userAttributes[j].Value;
          break;
        case "family_name":
          lastName = userAttributes[j].Value;
          break;
        case "phone_number":
          phoneNumber = userAttributes[j].Value;
          break;
        case "gender":
          gender = userAttributes[j].Value;
          break;
        case "custom:role":
          role = userAttributes[j].Value;
          break;
        case "custom:city":
          city = userAttributes[j].Value;
          break;
        case "custom:canAccess":
          canAccess = userAttributes[j].Value;
          break;
        case "custom:account_roles":
          account_roles = JSON.parse(userAttributes[j].Value);
          break;
        default:
          break;
      }
    }
    const basicInfo = new BasicInfo({
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      city,
    });

    newUser = new User({ id: sub, basicInfo, role });
    newUser.status = usersReturned[i].status;
    newUser.canAccess = canAccess === "true" ? true : false;
    newUser.account_roles = account_roles;
    if (!list && usersReturned[i].reservation_id) {
      const { reservation_id, start_time, end_time } = usersReturned[i];
      newUser = { ...newUser, reservation_id, start_time, end_time };
    }
    finalUsers.push(newUser);
  }
  return finalUsers;
}

function* getUsersDetails(request) {
  let usersReturned = [];
  let finalUsers = [];
  let error = false;
  
  const myInit = {
    body: { users: request.users, queue: request.flag },
  };
  let pathTemplate = "/api/listusers";

  try {
    const res = yield usersApiClient.post(pathTemplate, myInit);
    if (res.success) {
      usersReturned = res.payload[0];
    }
  }catch (err) {
    yield put(showErrorMessage(err.message));
  }

  finalUsers = polishUsers(usersReturned, false);
  return [error, finalUsers];
}

export function* getDrs(action) {
  let users = [];
  let msg = "";
  let error = false;

  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({ orgId: action.payload }),
    }
  };

  let pathTemplate = "/api/queue";

  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      users = res.payload;

      let response = [true, []];
      if (users.length === 0) {
        response = [false, []];
      } else {
        response = yield call(getUsersDetails, { users, flag: false });
      }

      yield put({
        type: GET_DR_DATA_RETURNED,
        payload: { drs: response[1], error },
      });
      
      yield put(showSuccessMessage(res.message || msg));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
  }

}

function* getUserDetailsRes(id) {
  let error = false;
  let userDetails = null;

  let body = convertObject({ username: id });
  let pathTemplate = `/api/details/${id}`;
  let method = "post";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClientUsers = getUsersApiGWClient(
      tokens[0],
      tokens[1],
      tokens[2]
    );

    yield apigClientUsers
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          userDetails = response.data.payload;
        } else {
          error = true;
        }
      })
      .catch((_) => {
        error = true;
      });
  } else {
    error = true;
  }
  let lastName,
    firstName,
    email,
    phoneNumber,
    canAccess,
    gender = "";
  if (userDetails && userDetails.length && !error) {
    email = userDetails[0].email;
    firstName = userDetails[0].first_name;
    lastName = userDetails[0].last_name;
    phoneNumber = userDetails[0].phone_number;
    canAccess = userDetails[0].can_access;
    gender = userDetails[0].gender;
  }
  return [
    error,
    { firstName, lastName, phoneNumber, email, canAccess, gender },
  ];
}

export function* addUsersToQueue(action) {
  const {
    orgId,
    user,
    drId,
    walkIn,
    queueDrs,
    drReserve,
    loggedInUserId,
    start_time,
    end_time,
    id,
  } = action.payload;
  let msg = "";
  let error = false;

  const queueObj = {
    org_id: orgId,
    provider_id: drId,
    patient_id: user.id,
    start_time,
    end_time,
    reservation_id: id,
  };

  let objectToBeSent = {
    queueObj,
    walkIn,
    queueDrs,
    drReserve,
    date: moment().format(),
    loggedInUserId,
  };

  let body = convertObject(objectToBeSent);
  let pathTemplate = "/api/resqueue";
  let res = null;
  let method = "post";
  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getReservationsApiGWClient(
      tokens[0],
      tokens[1],
      tokens[2]
    );
    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          msg = "messages.addToQueueSuccess";
          res = response.data.payload;
        } else {
          error = true;
          msg = response.data.message ? response.data.message : errorMessage;
        }
      })
      .catch((_) => {
        error = true;
        msg = errorMessage;
      });
  } else {
    error = true;
    msg = errorMessage;
  }

  if (walkIn && res) {
    const response = yield call(getUserDetailsRes, res.patient_id);
    if (response[0]) {
      error = true;
      msg = errorMessage;
    }

    const drResponse = yield call(getUserDetailsRes, res.provider_id);
    if (drResponse[0]) {
      error = true;
      msg = errorMessage;
    }
    if (!error) {
      const result = {
        ...res,
        ...response[1],
        drDetails: drResponse[1],
      };
      res = new Reservation(result);
    }
  }

  let response = yield call(getUsersDetails, {
    users: [{ patient_id: user.id }],
    flag: true,
  });
  if (response[0]) {
    msg = "Something went wrong. Try again later.";
    error = true;
  }
  let queueUser = {
    ...response[1][0],
    reservation_id: res ? res.id : action.payload.id,
    start_time: res ? res.start_time : action.payload.start_time,
    end_time: res ? res.end_time : action.payload.end_time,
  };

  if (error) {
    yield put(showErrorMessage(msg));
  } else {
    yield put(showSuccessMessage(msg));
  }

  yield put({
    type: ADD_USERS_TO_QUEUE_RETURNED,
    payload: {
      user,
      error,
      walkIn,
      res,
      id: action.payload.id,
      queueUser,
      drId,
    },
  });
}

export function* getOrgUsersRequest(action) {
  Preconditions.shouldBeDefined(
    action,
    "Get Org Users Action should be defined"
  );
  if (!checkInternetConnection()) {
    yield put(showErrorMessage(networkMessage));
    yield put({
      type: USERS_RETURNED,
      payload: { users: [], error: true },
    });
    return;
  }

  const request = {
    orgId: action.payload.orgId,
    status: action.payload.status,
    userId: action.payload.userId,
  };
  let msg = "";
  let error = false;
  let users = [];

  let additionalParams = {
    queryParams: {
      data: JSON.stringify(request),
    },
  };
  let pathTemplate = "/api/filters";
  let method = "get";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          const respUsers = response.data.payload;
          users = respUsers.map((user) => {
            let basicInfo = new BasicInfo({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              phoneNumber: user.phone_number,
              gender: user.gender,
              city: user.city,
            });
            return {
              id: user.user_id,
              basicInfo,
              role: user.role,
              status: user.status,
              canAccess: user.can_access,
              account_roles: user.account_roles,
            };
          });
        } else {
          error = true;
          msg = response.data.message ? response.data.message : errorMessage;
        }
      })
      .catch((_) => {
        error = true;
        msg = errorMessage;
      });
  } else {
    error = true;
    msg = errorMessage;
  }

  if (error) {
    yield put(showErrorMessage(msg));
  }

  yield put({
    type: USERS_RETURNED,
    payload: { users, error },
  });
}

export function* getAdminFilteredUsers(action) {
  Preconditions.shouldBeDefined(
    action,
    "Get admin filtered users action should be defined"
  );
  let users = [];
  let nextToken = null;
  let pathTemplate = "/api/allusers";
  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({
        group: action.payload.group,
        // error: Unexpected key 'PaginationToken' found in param
        // nextToken: action.payload.nextToken,
      }),
    },
  };
  try {
    const res = yield usersApiClient.get(pathTemplate, myInit);
    if (!res.success) {
      return yield put(showErrorMessage(errorMessage));
    }
    users = res.payload.Users;
    // nextToken = res.payload.PaginationToken;
    const finalUsers = polishUsers(users, true);
    yield put({
      type: USERS_RETURNED,
      payload: { users: finalUsers, nextToken },
    });
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getQueueUsers(action) {
  let users = [];
  let error = false;
  let msg = "";

  const myInit = {
    queryStringParameters: {
      data: JSON.stringify(action.payload),
    },
  };

  let pathTemplate = "/api/resqueue";

  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      users = res.payload;

      let response = [true, []];
      response = yield call(getUsersDetails, { users, flag: true });

      yield put({
        type: QUEUE_USERS_RETURNED,
        payload: { queueUsers: response[1], error },
      });
     
      yield put(showSuccessMessage(res.message || msg));
    }
  } catch (err) {
    yield put(showErrorMessage(err.message));
  }
}

export function* finalReserve(action) {
  let msg = "";
  let error = false;
  let newReservationId = null;

  let body2 = convertObject(action.payload);
  let pathTemplate2 = "/api/reserve";
  let method = "post";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getReservationsApiGWClient(
      tokens[0],
      tokens[1],
      tokens[2]
    );
    yield apigClient
      .invokeApi({}, pathTemplate2, method, {}, body2)
      .then((response) => {
        if (response.data.success) {
          msg = "messages.reserveSuccess";
          newReservationId = response.data.payload
            ? response.data.payload.id
            : null;
        } else {
          error = true;
          msg = response.data.message ? response.data.message : errorMessage;
        }
      })
      .catch((e) => {
        error = true;
        msg = e.message;
      });
  } else {
    error = true;
    msg = errorMessage;
  }

  if (error) {
    yield put(showErrorMessage(msg));
  } else {
    yield put(showSuccessMessage(msg));
  }

  yield put({
    type: RESERVED,
    payload: { newReservationId, error },
  });
}

export function* gettimings(action) {
  Preconditions.shouldBeDefined(
    action,
    "Reserve Time action should be defined"
  );
  let org = "";
  let msg = "";
  let slots = [];
  let availableDate = null;
  let availableDays = [];
  let error = false;
  let reservedSlot = null;
  let newDate = moment(action.payload.date + " " + moment().format("HH:mm"))
    .utc()
    .format("DD MMMM, YYYY");
  let day = moment
    .utc(action.payload.date)
    .format("dddd")
    .toLowerCase();
  let localTime = moment().format("HH:mm");

  let timeDiff = new Date().getTimezoneOffset();
  let newActionPayload = {
    ...action.payload,
    date: newDate,
    day,
    timeDiff,
    dateLocal: action.payload.date,
    localTime,
  };

  const id = { id: action.payload.userOrgId };
  let additionalParams = {
    queryParams: {
      data: JSON.stringify(id),
    },
  };
  let pathTemplate = "/api";
  let method = "GET";

  let body1 = convertObject(newActionPayload);
  let pathTemplate1 = "/api/checkuser";
  let method1 = "post";

  let body2 = convertObject(newActionPayload);
  let pathTemplate2 = "/api/allslots";
  let method2 = "post";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigOrgClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigOrgClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          org = response.data.payload;
        } else {
          msg = errorMessage;
          error = true;
        }
      })
      .catch((_) => {
        msg = errorMessage;
        error = true;
      });

    if (action.payload.selectedDrReserveId) {
      const apigClient = getReservationsApiGWClient(
        tokens[0],
        tokens[1],
        tokens[2]
      );
      yield apigClient
        .invokeApi({}, pathTemplate1, method1, {}, body1)
        .then((response) => {
          if (response.data.success) {
            reservedSlot = response.data.payload;
            msg = response.data.message;
          } else {
            error = true;
            msg = response.data.message;
          }
        })
        .catch((e) => {
          error = true;
          msg = e.message;
        });
    }

    if (!reservedSlot) {
      const apigClient = getReservationsApiGWClient(
        tokens[0],
        tokens[1],
        tokens[2]
      );
      yield apigClient
        .invokeApi({}, pathTemplate2, method2, {}, body2)
        .then((response) => {
          if (response.data.success) {
            slots = response.data.payload.slots;
            msg = "Slots returned successfully";
            availableDate = response.data.payload.date;
            availableDays = response.data.payload.availableDays;
          } else {
            error = true;
            msg = response.data.message;
          }
        })
        .catch((e) => {
          error = true;
          msg = e.message;
        });
    }
  } else {
    error = true;
    msg = errorMessage;
  }

  if (error) {
    yield put(showErrorMessage(msg));
  }

  yield put({
    type: TIMINGS_RETURNED,
    payload: { error, slots, org, reservedSlot, availableDate, availableDays },
  });
}

export function* changeUsersStatusRequest(action) {
  Preconditions.shouldBeDefined(
    action,
    "Change user status action should be defined"
  );
  let msg = "";
  let body = convertObject(action.payload);

  let pathTemplate = "/api/filters";
  let method = "put";
  let error = false;

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getOrgsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient
      .invokeApi({}, pathTemplate, method, {}, body)
      .then((response) => {
        if (response.data.success) {
          msg = "messages.changeStatusSuccess";
        } else {
          error = true;
          msg = response.data.message ? response.data.message : errorMessage;
        }
      })
      .catch((_) => {
        msg = errorMessage;
        error = true;
      });
  } else {
    msg = errorMessage;
    error = true;
  }

  if (error) {
    yield put(showErrorMessage(msg));
  } else {
    yield put(showSuccessMessage(msg));
  }

  yield put({
    type: CHANGE_USERS_STATUS_RETURNED,
    payload: { error },
  });
}

export function* deleteQueueUser(action) {
  Preconditions.shouldBeDefined(
    action,
    "Delete user from queue action should be defined"
  );
  let msg = "";
  let error = false;
  let additionalParams = {
    queryParams: {
      data: JSON.stringify(action.payload),
    },
  };
  let pathTemplate = "/api/resqueue";
  let method = "delete";

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getReservationsApiGWClient(
      tokens[0],
      tokens[1],
      tokens[2]
    );
    yield apigClient
      .invokeApi({}, pathTemplate, method, additionalParams, {})
      .then((response) => {
        if (response.data.success) {
          msg = "messages.removeQueueSuccess";
        } else {
          msg = response.data.message ? response.data.message : errorMessage;
          error = true;
        }
      })
      .catch((_) => {
        msg = errorMessage;
        error = true;
      });
  } else {
    msg = errorMessage;
    error = true;
  }

  if (error) {
    yield put(showErrorMessage(msg));
  }

  yield put({
    type: DELETE_QUEUE_USER_RETURNED,
    payload: {
      userId: action.payload.userId,
      error,
      delete: action.payload.delete,
      reservation_id: action.payload.reservation_id,
    },
  });
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(SAVE_USER, saveUserRequest),
    yield takeEvery(GET_USER, getUserRequest),
    yield takeEvery(GET_USER_EDIT, getUserForEditRequest),
    yield takeEvery(SEARCH_USERS, searchUsers),
    yield takeEvery(UPDATE_USER_EDIT, updateUserEdit),
    yield takeEvery(GET_ORG_USERS_REQUEST, getOrgUsersRequest),
    yield takeEvery(DELETE_USER_REQUEST, deleteUser),
    yield takeEvery(GET_DR_DATA_REQUESTED, getDrs),
    yield takeEvery(ADD_USERS_TO_QUEUE_REQUESTED, addUsersToQueue),
    yield takeEvery(QUEUE_USERS_REQUESTED, getQueueUsers),
    yield takeEvery(DELETE_QUEUE_USER_REQUESTED, deleteQueueUser),
    yield takeEvery(CHANGE_USERS_STATUS_REQUEST, changeUsersStatusRequest),
    yield takeEvery(GET_ADMIN_FILTERED_USERS, getAdminFilteredUsers),
    yield takeEvery(RESERVE_TIME, gettimings),
    yield takeEvery(FINAL_RESERVE, finalReserve),
  ]);
}
