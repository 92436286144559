import appLocaleData from 'react-intl/locale-data/ar';
import saMessages from '../locales/ar_SA.json';
import flattenJSON from './flatten-obj'

const saLang = {
  messages: {
    ...flattenJSON(saMessages)
  },
  locale: 'ar-SA',
  data: appLocaleData
};
export default saLang;
