import {
  GET_DAILY_CHAT_RESERVATIONS,
  GET_DAILY_CHAT_RESERVATIONS_SUCCESS,
  GET_DAILY_CHAT_RESERVATIONS_FAILED,
} from "../constants/actionTypes";

const initialState = {
  chatReservations: [],
  chatReservationsLoading: false,
  tableFields: [
    {
      label: 'daily_reservations_activity.table.user_name'
    },
    {
      label: 'daily_reservations_activity.table.user_phone_number'
    },
    {
      label: 'daily_reservations_activity.table.user_email'
    },
    {
      label: 'daily_reservations_activity.table.provider_name'
    },
    {
      label: 'daily_reservations_activity.table.provider_phone_number'
    },
    {
      label: 'daily_reservations_activity.table.organization_address'
    },
    {
      label: 'daily_reservations_activity.table.reservation_type'
    },
    {
      label: 'daily_reservations_activity.table.reservation_status'
    },
    {
      label: 'daily_reservations_activity.table.initiated_on'
    }
  ],
}

export const dailyChatReservations = (state = initialState, action) => {
  switch (action.type) {
    case GET_DAILY_CHAT_RESERVATIONS:
      return {
        ...state,
        chatReservationsLoading: true
      };

    case GET_DAILY_CHAT_RESERVATIONS_SUCCESS:
      return {
        ...state,
        chatReservationsLoading: false,
        chatReservations: action.payload
      };

    case GET_DAILY_CHAT_RESERVATIONS_FAILED:
      return {
        ...state,
        chatReservationsLoading: false
      };

    default:
      return state;
  }
}