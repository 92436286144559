import React, { Component } from 'react';
import { resetError, resetSuccess } from '../actions/Notifications';
import { connect } from 'react-redux';
import CustomizedSnackBar from '../app/core/components/cv-snackbars/CustomizedSnackBar';

class Notifier extends Component {
  render() {
    const { error, success, resetSuccess, resetError, alertMessage, isDirectionRTL } = this.props;
    return (
      <React.Fragment>
        <CustomizedSnackBar open={error} variant={'error'} reset={resetError} message={alertMessage} horizontal={isDirectionRTL? 'left': 'right'} />
        <CustomizedSnackBar open={success} variant={'success'} reset={resetSuccess} message={alertMessage} horizontal={isDirectionRTL? 'left': 'right'} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ notifications, settings }) => {
  const { success, error, alertMessage } = notifications;
  return {
    success, error, alertMessage, isDirectionRTL: settings.isDirectionRTL
  }
}

export default connect(mapStateToProps, { resetError, resetSuccess })(Notifier);