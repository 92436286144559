import moment from 'moment';

export class Reservation {
	constructor({
		id,
		patient_id,
		provider_id,
		created_at,
		firstName,
		drDetails,
		title,
		lastName,
		start_time,
		reservation_date,
		end_time,
		canAccess,
		phoneNumber,
		status
	}) {
		const tags = [];
		const reTags = [
			{
				label: "reservation.drName",
				value: drDetails.first_name + " " + drDetails.last_name
			},
			{
				label: "reservation.reservation_date",
				value: moment.utc(start_time).local().format('Do MMM YYYY, hh:mm A')
			},
			{
				label: "user.phoneNumber",
				value: phoneNumber
			},
			{
				label: "reservation.title",
				value: title
			}
		]
		this.id = id;
		this.name = firstName + ' ' + lastName;
		this.tags = tags;
		this.doctorId = provider_id;
		this.patientId = patient_id;
		this.reTags = reTags;
		this.canAccess = canAccess;
		this.status = status;
		this.start_time = start_time;
		this.end_time = end_time;
		this.reservation_date = reservation_date;
		this.created_at = created_at;
	}
}
