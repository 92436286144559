import React from 'react';
import { OrgFilteredItem } from '../model/orgFilteredItem';
import IntlMessages from '../../../../../util/IntlMessages';

export const orgToFilteredOrgItem = organization => {
  const { basicInfo, createdAt, createdBy, id } = organization;
  const { clinicType, legalName, status,speciality } = basicInfo;
  let mainSpecialty = clinicType ? clinicType : speciality ? speciality[0]: null;
  const typeName = <IntlMessages id={`org.type.${mainSpecialty}`} />
  const statusName = status? <IntlMessages id="org.status.active" /> : <IntlMessages id="org.status.inactive" />;
  
  const tags = [
    {
      label: "default.type",
      value: typeName
    },
    {
      label: "org.status.default",
      value: statusName
    }
  ]

	return new OrgFilteredItem({
    id,
    name: legalName,
		createdAt,
		createdByName: createdBy,
    tags,
    model: organization
	})
}