const validatePhoneNumber2 = require('validate-phone-number-node-js');

export const convertToArray = snapshot => {
  if (!snapshot) {
    return []
  }
  let returnArr = [];
  snapshot.forEach(function (childSnapshot) {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });
  return returnArr;
};

export const convertToModel = fbObject => {
  for (let i in fbObject) {
    return {
      id: i,
      ...fbObject[i]
    }
  }
}

export const validatePhoneNumber = (number) => {
  return validatePhoneNumber2.validate(number);
}

export const checkInternetConnection = () => {
  return navigator.onLine;
}