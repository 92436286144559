import { NoteListItem } from './../models/NoteListItem';

export const noteToNoteListItemConverter = note => {
	const { id, type, original_text, translated_text, created_at, createdByName, shared, comments, commentsLength } = note;
	return new NoteListItem({
		id,
		noteType: type,
		originalText: original_text,
		translatedText: translated_text,
		createdAt: created_at,
		createdByName,
		shared,
		comments,
		commentsLength,
		model: note
	});
}