import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright CALLVITA LLC &copy; 2020</span>
      <Link to="/privacy-policy">
        <IntlMessages IntlMessages id={"privacyPolicy"} />
      </Link>
    </footer>
  );
};
export default Footer;
