import React, { Component } from 'react';
import { withStyles, Grid, TextField, FormControl, FormHelperText } from '@material-ui/core';
import IntlMessages from '../../../../../util/IntlMessages';
import { CustomizedTooltip } from '../../../../core/components/cv-tooltips/CustomizedTooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import CustomScrollbars from '../../../../../util/CustomScrollbars';
import * as EmailValidator from 'email-validator';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { account_roles as ar } from '../../state/model/organization'

const styles = theme => ({
	component: {
		margin: theme.spacing(2)
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		marginRight: theme.spacing(2)
	},
	phoneInput: {
		borderBottomStyle: 'solid',
		borderWidth: 1,
		borderColor: '#707B7C',
		height: 30
	}
});

const defaultAdminInfo = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
	email: '',
	password: '',
	confirmPassword: '',
	account_roles: new ar({ orgAdmin: true })
}

class AdminInfo extends Component {
	constructor(props) {
		super(props);
		const adminInfo = props.adminInfo ? props.adminInfo : defaultAdminInfo;
		this.state = {
			adminInfo,
			emailError: '',
			open: false,
			confirmPasswordError: '',
			passwordLength: '',
			phoneNumberError: ''
		}
	}

	handleIconButtonRequestClose = () => {
		this.setState({ open: false });
	};

	handleIconButtonRequestOpen = () => {
		this.setState({ open: true });
	};

	updateErrors = () => {
		const { emailError, confirmPasswordError, passwordLength, phoneNumberError } = this.state;
		const invalid = emailError || confirmPasswordError || passwordLength || phoneNumberError;
		this.props.onUpdateAdminErrors(invalid ? true : false);
	};

	handleChange = name => event => {
		const adminInfo = {
			...this.state.adminInfo,
		}

		if (name === 'operator') {
			let account_roles = adminInfo.account_roles;
			account_roles.operator = event.target.checked;
			this.setState({ adminInfo }, () => {
				this.props.onAdminInfoUpdate(this.state.adminInfo);
			});
		} else if (name === 'doctor') {
			let account_roles = adminInfo.account_roles;
			account_roles.doctor = event.target.checked;
			this.setState({ adminInfo }, () => {
				this.props.onAdminInfoUpdate(this.state.adminInfo);
			});
		} else {
			if (name === 'email') {
				if (EmailValidator.validate(event.target.value)) {
					this.setState({ emailError: '' }, () => this.updateErrors())
				}
				else {
					this.setState({ emailError: <IntlMessages id="validations.emailError" /> }, () => this.updateErrors())
				}
			}

			adminInfo[name] = event.target.value;
			this.setState({ adminInfo }, () => {
				this.props.onAdminInfoUpdate(this.state.adminInfo);
				if (name === 'confirmPassword') {
					this.validateConfirmPassword();
				}
			});
		}
	};

	validateConfirmPassword = () => {
		const adminInfo = {
			...this.state.adminInfo,
		}

		if (adminInfo.confirmPassword === adminInfo.password) {
			this.setState({ confirmPasswordError: '' }, () => this.updateErrors());
			this.props.onConfirmPasswordUpdate(false);
		}
		else {
			this.setState({ confirmPasswordError: "Passwords don't match" }, () => this.updateErrors());
			this.props.onConfirmPasswordUpdate(true);
		}
	}

	handlePasswordChange = name => event => {
		const adminInfo = {
			...this.state.adminInfo,
		}
		if (adminInfo.confirmPassword === event.target.value) {
			this.setState({ confirmPasswordError: '' }, () => this.updateErrors())
			this.props.onConfirmPasswordUpdate(false);
		} else {
			this.setState({ confirmPasswordError: "Passwords don't match" }, () => this.updateErrors());
			this.props.onConfirmPasswordUpdate(true);
		}

		if (event.target.value.length >= 8) {
			adminInfo[name] = event.target.value;
			this.setState({ adminInfo, passwordLength: '' }, () => {
				this.props.onAdminInfoUpdate(this.state.adminInfo);
				this.updateErrors();
			})
		}
		else {
			adminInfo[name] = event.target.value;
			this.setState({ adminInfo, passwordLength: <IntlMessages id="password.errorMessage" /> }, () => {
				this.props.onAdminInfoUpdate(this.state.adminInfo);
				this.updateErrors();
			});
		}
	}

	handlePhoneChange = event => {
		const adminInfo = {
			...this.state.adminInfo
		}
		if (isValidPhoneNumber(event) || !event) {
			this.setState({ phoneNumberError: '' }, () => this.updateErrors());
		} else {
			this.setState({ phoneNumberError: <IntlMessages id="validations.phoneError" /> }, () => this.updateErrors());
		}
			adminInfo['phoneNumber'] = event;
		this.setState({ adminInfo }, () => this.props.onAdminInfoUpdate(this.state.adminInfo));
	}

	render() {
		const { classes, edit, adminCreate, width, canEditAdminInfo } = this.props;
		const web = width > 700 ? true : false;
		return (
			<React.Fragment>
				<CustomScrollbars className="module-list-scroll scrollbar"
					style={{ height: edit || adminCreate ? 'calc(100vh - 360px)' : 'calc(100vh - 270px)' }}>
					<div className={classes.component}>
						<div className={classes.container} >
							<h3><b><IntlMessages id="org.adminInfo" /></b></h3>
							<CustomizedTooltip
								id="tooltip-controlled"
								title={<IntlMessages id="org.adminInfoTip" />}
								onClose={this.handleIconButtonRequestClose}
								enterDelay={100}
								leaveDelay={100}
								onOpen={this.handleIconButtonRequestOpen}
								open={this.state.open}
								placement="bottom"
							>
								<InfoOutlinedIcon style={{ width: 20, height: 20, color: 'black' }} />
							</CustomizedTooltip>
						</div>
						{(edit && !canEditAdminInfo) && 
						<Grid container justify="center" alignItems="center" style={{ marginTop: 24 }}>
							<IntlMessages id="org.cannotEditAdminInfo" />
						</Grid>
						}
						<Grid
							container
							spacing={3}
						>
							{(!edit || (edit && canEditAdminInfo)) &&
								<Grid item xs={web ? 6 : 12}>
									<TextField
										id="standard-name"
										label={<IntlMessages id="user.firstName" />}
										value={this.state.adminInfo.firstName || ''}
										onChange={this.handleChange('firstName')}
										fullWidth
										required
									/>
								</Grid>
							}
							{(!edit || (edit && canEditAdminInfo)) &&
								<Grid item xs={web ? 6 : 12}>
									<TextField
										id="standard-last-name"
										label={<IntlMessages id="user.lastName" />}
										value={this.state.adminInfo.lastName || ''}
										onChange={this.handleChange('lastName')}
										fullWidth
										required
									/>
								</Grid>
							}
							{!this.props.edit &&
								<Grid item xs={web ? 6 : 12}>
									<FormControl fullWidth>
										<TextField
											id="standard-email-name"
											label={<IntlMessages id="user.email" />}
											error={this.state.emailError ? true : false}
											type="email"
											value={this.state.adminInfo.email}
											onChange={this.handleChange('email')}
											fullWidth
											required
										/>
										{this.state.emailError &&
											<FormHelperText id="component-error-text" error>{this.state.emailError}</FormHelperText>}
									</FormControl>
								</Grid>
							}
							{(!edit || (edit && canEditAdminInfo)) &&
								<Grid item xs={web ? 6 : 12}>
									<FormControl fullWidth>
										<span className="mb-0 jr-fs-sm" style={{ color: 'gray' }}><IntlMessages id="user.phoneNumber" /></span>
										<PhoneInput
											international={true}
											withCountryCallingCode={true}
											style={{ width: '100%' }}
											inputClassName={classes.phoneInput}
											countries={["EG", "US", "AU"]}
											value={this.state.adminInfo.phoneNumber || ''}
											onChange={(event) => this.handlePhoneChange(event)}
										/>
										{this.state.phoneNumberError &&
											<FormHelperText id="component-error-text" error>{this.state.phoneNumberError}</FormHelperText>}
									</FormControl>
								</Grid>
							}
							{!this.props.edit &&
								<Grid item xs={web ? 6 : 12}>
									<FormControl fullWidth>
										<TextField
											id="standard-password"
											label={<IntlMessages id="user.password" />}
											type="password"
											error={this.state.passwordLength ? true : false}
											value={this.state.adminInfo.password}
											onChange={this.handlePasswordChange('password')}
											fullWidth
											required
										/>
										{this.state.passwordLength &&
											<FormHelperText id="component-error-text" error>{this.state.passwordLength}</FormHelperText>}
									</FormControl>
								</Grid>
							}
							{!this.props.edit &&
								<Grid item xs={web ? 6 : 12}>
									<FormControl fullWidth>
										<TextField
											id="standard-confirm-password"
											label={<IntlMessages id="user.confirmPassword" />}
											error={this.state.confirmPasswordError ? true : false}
											type="password"
											value={this.state.adminInfo.confirmPassword}
											onChange={this.handleChange('confirmPassword')}
											fullWidth
											required
										/>
										{this.state.confirmPasswordError &&
											<FormHelperText style={{ marginTop: 20 }} id="component-error-text" error>{this.state.confirmPasswordError}</FormHelperText>}
									</FormControl>
								</Grid>
							}
							{(!edit || (edit && canEditAdminInfo)) &&
								<Grid item xs={12}>
									<FormControl component="fieldset" fullWidth>
										<FormLabel component="legend"><IntlMessages id="org.assignRole" /></FormLabel>
										<FormGroup>
											<FormControlLabel
												control={<Checkbox checked={this.state.adminInfo.account_roles.orgAdmin} disabled color='primary' onChange={this.handleChange('orgAdmin')} value="orgAdmin" />}
												label={<IntlMessages id="org.admin" />}
											/>
											<FormControlLabel
												control={<Checkbox checked={this.state.adminInfo.account_roles.doctor} color='primary' onChange={this.handleChange('doctor')} value="doctor" />}
												label={<IntlMessages id="org.doctor" />}
											/>
											<FormControlLabel
												control={<Checkbox checked={this.state.adminInfo.account_roles.operator} color='primary' onChange={this.handleChange('operator')} value="operator" />}
												label={<IntlMessages id="org.operator" />}
											/>
										</FormGroup>
									</FormControl>
								</Grid>
							}
						</Grid>
					</div>
				</CustomScrollbars>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(AdminInfo);