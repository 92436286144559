import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import CardListItem from '../../app/core/components/cv-cards/CardListItem';
import {
	Select,
	Input,
	MenuItem,
	AppBar,
	Button,
	// Avatar,
	Toolbar,
	IconButton,
	Grid,
	CircularProgress,
	Icon,
	FormControl,
	InputLabel
} from '@material-ui/core';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { BELOW_THE_HEADER, COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { queueUsersRequested, deleteQueueUser, setDrIdAppsModal, getDrs } from '../../app/modules/user/state/action/userSaveActions'
import { getUser } from '../../app/modules/user/state/action/usersActions';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import PageTitle from './pageTitle';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
// import UserInfoPopup from 'components/UserInfo/UserInfoPopup';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: undefined,
			searchBox: false,
			searchText: '',
			mailNotification: false,
			userInfo: false,
			langSwitcher: false,
			appNotification: false,
			openDrawer: false,
			type: '',
			drName: '',
			redirectProfile: false,
			selectedUser: {},
			apps: false,
			drChosen: false,
			queueDrs: [],
			loggedInUserDr: false
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.queueDrs.length !== prevProps.queueDrs.length && this.props.loggedInUser.permissions.includes("USERS_QUEUE_READ")) {
			let queueDrs = this.props.queueDrs;
			if (queueDrs.length > 0) {
				if (this.props.loggedInUser.permissions.includes("VIEW_MYSELF_QUEUE")) {
					this.setState({ loggedInUserDr: true });
					queueDrs = this.props.queueDrs.filter(dr => {
						return dr.id === this.props.loggedInUser.id;
					});
				}
				const dr = queueDrs[0];
				const drName = dr.basicInfo.firstName + ' ' + dr.basicInfo.lastName;
				this.setState({ drName }, () => {
					this.props.setDrIdAppsModal(dr.id);
					this.props.queueUsersRequested({ drId: dr.id, orgId: this.props.loggedInUser.orgId });
				});
			}
		}
	}

	onAppNotificationSelect = () => {
		this.setState({
			appNotification: !this.state.appNotification
		})
	};

	onMailNotificationSelect = () => {
		this.setState({
			mailNotification: !this.state.mailNotification
		})
	};

	onLangSwitcherSelect = (event) => {
		this.setState({
			langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
		})
	};

	onSearchBoxSelect = () => {
		this.setState({
			searchBox: !this.state.searchBox
		})
	};

	onAppsSelect = () => {
		this.setState({
			apps: !this.state.apps
		})
	};

	onUserInfoSelect = () => {
		this.setState({
			userInfo: !this.state.userInfo
		})
	};

	handleRequestClose = () => {
		this.setState({
			langSwitcher: false,
			userInfo: false,
			mailNotification: false,
			appNotification: false,
			searchBox: false,
			apps: false
		});
	};

	onToggleCollapsedNav = (e) => {
		const val = !this.props.navCollapsed;
		this.props.toggleCollapsedNav(val);
	};

	updateSearchText(evt) {
		this.setState({
			searchText: evt.target.value,
		});
	}

	Apps = () => {
		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Button
					size="small"
					style={{ color: "#eceff1" }}
					disabled={this.props.loadingAddUserToQueue}
					onClick={() => this.setState({ openDrawer: true }, () => this.props.getDrs(this.props.loggedInUser.orgId))}>
					<IntlMessages id="myApps.queue" />
				</Button>
			</div>
		)
	};

	handleTypeChange = (e, key) => {
		this.setState({ drName: e.target.value }, () => {
			this.props.setDrIdAppsModal(key.key);
			this.props.queueUsersRequested({ drId: key.key, orgId: this.props.loggedInUser.orgId });
		});
	}

	onMenuItemClicked = (option, item, history) => {
		const { selectedDrIdAppsModal } = this.props;
		const { orgId } = this.props.loggedInUser;

		switch (option.id) {
			case "VIEW":
				if (item.id) {
					this.props.getUser(item.id);
				}
				setTimeout(() => {
					history.push('/app/clinic/user-profile')
				}, 500);
				break;

			case "SUMMARY":
				if (item.id) {
					this.props.getUser(item.id);
				}
				setTimeout(() => {
					history.push('/app/my-health/summary')
				}, 500);
				break;

			case "EVENTS":
				if (item.id) {
					this.props.getUser(item.id);
				}
				setTimeout(() => {
					history.push('/app/my-health/events')
				}, 500);
				break;

			case "REMOVE":
				this.props.deleteQueueUser({ orgId, userId: item.id, drId: selectedDrIdAppsModal, reservation_id: item.reservation_id, delete: true });
				break;

			default:
				break;
		}
	}

	renderQueueModal() {
		const { queueDrs, queueUsers, selectedDrIdAppsModal, loading, getUser } = this.props;
		const web = window.innerWidth > 700;
		const midScreen = window.innerWidth > 1000;
		const largeScreen = window.innerWidth > 2000;

		return (
			<Drawer
				anchor='right'
				open={this.state.openDrawer}
				onClose={() => this.setState({ openDrawer: false })}>
				<div style={{ width: largeScreen ? window.innerWidth * 0.24 : (midScreen ? window.innerWidth * 0.34 : (web ? window.innerWidth * 0.38 : window.innerWidth)) }}>
					<Grid container direction="row" justify="space-between" alignItems="center" style={{ margin: 8 }}>
						<Grid item xs={10} md={8} lg={6} xl={5}>
							<FormControl fullWidth>
								<InputLabel><IntlMessages id="reservation.provider" /></InputLabel>
								<Select
									value={this.state.drName}
									onChange={(e, key) => this.handleTypeChange(e, key)}
									fullWidth
									disabled={this.state.loggedInUserDr}
									input={<Input name="type" id="owner-helper" />}
								>
									{
										queueDrs.map(doctor => {
											const { firstName, lastName } = doctor.basicInfo;
											const name = firstName + ' ' + lastName;
											return <MenuItem key={doctor.id} value={name}>{name}</MenuItem>
										})
									}
								</Select>
							</FormControl>
						</Grid>
						{web && <Grid item>
							<div className="media-body">
								<span className="mb-0 text-grey jr-fs-sm"><IntlMessages id="dashboard.today" /></span>
								<p className="mb-0">{moment().format("dddd, Do MMM YYYY")}</p>
							</div>
						</Grid>}
						<Grid item>
							<IconButton onClick={() => this.setState({ openDrawer: false })} >
								<Icon>close</Icon>
							</IconButton>
						</Grid>
					</Grid>
					{!web &&
						<div className="media-body" style={{ margin: 8 }}>
							<span className="mb-0 text-grey jr-fs-sm"><IntlMessages id="reservation.reservation_date" /></span>
							<p className="mb-0">{moment().format("dddd, Do MMM YYYY")}</p>
						</div>
					}
					{
						loading ?
							<div className="loader-view">
								<CircularProgress />
							</div>
							:
							(selectedDrIdAppsModal ?
								(queueUsers.length > 0 ?
									queueUsers.map((user, index) => (
										<CardListItem
											model={user}
											key={index}
											getUser={getUser}
											closeDrawer={() => {
												this.setState({ openDrawer: false });
											}}
											selectedDrIdAppsModal={selectedDrIdAppsModal}
											onMenuItemClicked={(option, item, history) => {
												if (option.id !== "REMOVE") {
													this.setState({ openDrawer: false });
												}
												this.onMenuItemClicked(option, item, history);
											}}
											index={index}
											completeReservation={() => {
												this.props.deleteQueueUser({
													orgId: this.props.loggedInUser.orgId,
													userId: user.id, drId: selectedDrIdAppsModal, reservation_id: user.reservation_id, delete: false
												})
											}}
											loggedInUser={this.props.loggedInUser}
										/>
									))
									:
									<div className="loader-view" style={{ marginTop: 16 }}>
										<IntlMessages id="reservation.queue.noUsers" />
									</div>
								) :
								<div className="loader-view" style={{ marginTop: 16 }}>
									<IntlMessages id="reservation.queue.noSelectedDr" />
								</div>
							)
					}
				</div>
			</Drawer>
		);
	}

	render() {
		const { drawerType, navigationStyle, horizontalNavPosition, locale } = this.props;
		const { permissions } = this.props.loggedInUser;
		const web = window.innerWidth > 700;
		const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

		return (
			<AppBar
				className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
				<Toolbar className="app-toolbar" disableGutters={false}>
					{navigationStyle === HORIZONTAL_NAVIGATION ?
						<div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
							<span className="jr-menu-icon">
								<span className="menu-icon" />
							</span>
						</div>
						:
						<IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
							onClick={this.onToggleCollapsedNav}>
							<span className="menu-icon" />
						</IconButton>
					}

					<Grid container spacing={1} alignItems="center">
						{web &&
							<Grid item xs={4}>
								<Link className="mr-2 d-none d-sm-block" to="/">
									<img src={require("assets/images/srvy-white.png")} alt="Srvy" title="Srvy" height={50}/>
								</Link>
							</Grid>}
						{!web &&
							<Grid item xs={permissions.includes("USERS_QUEUE_READ")? 9: 11}>
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: -16, marginLeft: permissions.includes("USERS_QUEUE_READ") ? -4 : -32, width: '100%' }}>
									<PageTitle />
								</div>
							</Grid>
						}

						{/* {permissions.includes("USERS_QUEUE_READ") &&
							<ul className="header-notifications list-inline ml-auto">
								{permissions.includes("USERS_QUEUE_READ") &&
									<li className="list-inline-item"> */}
						{/* <Dropdown
									className="quick-menu app-notification"
									isOpen={this.state.apps}
									toggle={this.onAppsSelect.bind(this)}> */}

						{/* <DropdownToggle
										className="d-inline-block"
										tag="span"
										data-toggle="dropdown">
										<span className="app-notification-menu">
											<span><IntlMessages id="default.myApps" /></span>
										</span>
									</DropdownToggle> */}

						{/* <DropdownMenu> */}
						{/* {this.Apps()} */}
						{/* </DropdownMenu> */}
						{/* </Dropdown> */}
						{/* </li> */}

						<Grid item xs={web? 8 : permissions.includes("USERS_QUEUE_READ")? 3 : 1}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
								{permissions.includes("USERS_QUEUE_READ") && this.Apps()}
								{/* {navigationStyle === HORIZONTAL_NAVIGATION &&
									<li className="list-inline-item user-nav">
										<Dropdown
											className="quick-menu"
											isOpen={this.state.userInfo}
											toggle={this.onUserInfoSelect.bind(this)}>

											<DropdownToggle
												className="d-inline-block"
												tag="span"
												data-toggle="dropdown">
												<IconButton className="icon-btn size-30">
													<Avatar
														alt='...'
														src={'https://via.placeholder.com/150x150'}
														className="size-30"
													/>
												</IconButton>
											</DropdownToggle>

											<DropdownMenu right>
												<UserInfoPopup />
											</DropdownMenu>
										</Dropdown>
									</li>
								}
							</ul>
						} */}
								{/* <ul className="header-notifications list-inline ml-auto">
							<li className="list-inline-item"> */}

								<Dropdown
									className="quick-menu"
									isOpen={this.state.langSwitcher}
									toggle={this.onLangSwitcherSelect.bind(this)}>

									<DropdownToggle
										className="d-inline-block"
										tag="span"
										data-toggle="dropdown">
										<IconButton className="icon-btn">
											<i className={`flag flag-24 flag-${locale.icon}`} />
										</IconButton>
									</DropdownToggle>

									<DropdownMenu right className="w-50">
										<LanguageSwitcher switchLanguage={this.props.switchLanguage}
											handleRequestClose={this.handleRequestClose} />
									</DropdownMenu>
								</Dropdown>
							</div>
						</Grid>
						{/* </li>
						</ul> */}
					</Grid>
					{this.renderQueueModal()}
				</Toolbar>
			</AppBar>
		);
	}
}

const mapStateToProps = ({ settings, users, auth }) => {
	const { drawerType, locale, navigationStyle, horizontalNavPosition } = settings;
	const { queueDrs, selectedDrIdAppsModal, queueUsers, queueLoading, loadingAddUserToQueue } = users;
	const { loggedInUser } = auth;
	return {
		drawerType, locale, navigationStyle, horizontalNavPosition,
		queueDrs, loggedInUser, selectedDrIdAppsModal, queueUsers,
		loading: queueLoading, loadingAddUserToQueue
	}
};

export default withRouter(connect((mapStateToProps), {
	toggleCollapsedNav, switchLanguage, queueUsersRequested,
	deleteQueueUser, setDrIdAppsModal, getUser, getDrs
})(Header));