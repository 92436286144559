import { all, takeEvery, put } from "redux-saga/effects";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../actions/Notifications";
import {
  orgsApiClient,
  paymentApiClient,
} from "../../../../core/utils/restClient";
import {
  GET_TOTAL_STATS,
  TOTAL_STATS_RETURNED,
  ORGS_AVTIVITY_RETURNED,
  GET_ORGS_ACTIVITY,
  GET_CALLVITA_FEES_REQUEST,
  CREATE_CALLVITA_FEES_REQUEST,
  EDIT_CALLVITA_FEES_REQUEST,
  DELETE_CALLVITA_FEES_REQUEST,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_FAILED,
  GET_CURRENCY,
  SEND_NOTIFICATION,
} from "../constants/actionTypes";
import {
  getCallvitaFeesSuccess,
  getCallvitaFeesFailed,
  createCallvitaFeesSuccess,
  createCallvitaFeesFailed,
  editCallvitaFeesSuccess,
  editCallvitaFeesFailed,
  deleteCallvitaFeesSuccess,
  deleteCallvitaFeesFailed,
} from "../actions/adminActions";

const errorMessage = "Something went wrong, please try again later.";

export function* getTotalStats(action) {
  let message = "";
  let orgsStats = [];
  let totalStats = [];
  try {
    let myInit = {
      queryStringParameters: {
        data: JSON.stringify(action.payload),
      },
    };
    let pathTemplate = "/api/total-stats";
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      orgsStats = res.payload;
      message = res.message || errorMessage;
      totalStats = [...orgsStats];
      return yield put({
        type: TOTAL_STATS_RETURNED,
        payload: { totalStats },
      });
    }
    yield put(showErrorMessage(message));
  } catch (e) {
    yield put(showErrorMessage(message || errorMessage));
  }
}

export function* getOrgsActivity(action) {
  let message = "";
  let orgsActivity = [];
  let pathTemplate = "/api/orgs-activity";
  let myInit = {
    queryStringParameters: {
      data: JSON.stringify(action.payload),
    },
  };
  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      orgsActivity = res.payload;
      return yield put({
        type: ORGS_AVTIVITY_RETURNED,
        payload: { orgsActivity },
      });
    }
    message = res.message || errorMessage;
    yield put(showErrorMessage(message));
  } catch (error) {
    yield put(showErrorMessage(message || errorMessage));
  }
}

export function* getCallvitaFeesRequest(action) {
  let pathTemplate = `/api/fees`;
  let message = "";
  try {
    const res = yield paymentApiClient.get(pathTemplate, {});
    if (res.success) {
      return yield put(getCallvitaFeesSuccess(res.payload));
    }
    message = res.message || errorMessage;
    yield put(getCallvitaFeesFailed());
    yield put(showErrorMessage(message));
  } catch (e) {
    yield put(getCallvitaFeesFailed());
    yield put(showErrorMessage(message || errorMessage));
  }
}

export function* createCallvitaFeesRequest(action) {
  let pathTemplate = "/api/fees";
  let myInit = {
    body: {
      ...action.payload,
    },
  };
  try {
    const res = yield paymentApiClient.post(pathTemplate, myInit);
    if (res.success) {
      return yield put(createCallvitaFeesSuccess(res.payload));
    }
    yield put(createCallvitaFeesFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(createCallvitaFeesFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export function* editCallvitaFeesRequest(action) {
  let pathTemplate = `/api/fees`;
  let message = "";
  let myInit = {
    body: action.payload,
  };
  try {
    const res = yield paymentApiClient.put(pathTemplate, myInit);
    if (res.success) {
      return yield put(editCallvitaFeesSuccess(res.payload));
    }
    message = res.message || errorMessage;
    yield put(editCallvitaFeesFailed());
    yield put(showErrorMessage(message));
  } catch (e) {
    yield put(editCallvitaFeesFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export function* deleteCallvitaFeesRequest(action) {
  let pathTemplate = `/api/fees`;
  let myInit = {
    queryStringParameters: {
      id: action.payload,
    },
  };

  try {
    const res = yield paymentApiClient(pathTemplate, myInit);
    if (res.success) {
      return yield put(deleteCallvitaFeesSuccess(action.payload));
    }
    yield put(deleteCallvitaFeesFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(deleteCallvitaFeesFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getCurrencies() {
  let pathTemplate = "/api/currency";
  try {
    const res = yield paymentApiClient.get(pathTemplate, {});
    if (res.success) {
      return yield put({
        type: GET_CURRENCY_SUCCESS,
        payload: res.payload,
      });
    }

    yield put(showErrorMessage(errorMessage));
    yield put({
      type: GET_CURRENCY_FAILED,
    });
  } catch (e) {
    yield put({
      type: GET_CURRENCY_FAILED,
    });
    yield put(showErrorMessage(errorMessage));
  }
}

export function* sendNotifications(action) {
  const pathTemplate = `/api/notification/broadcast`;
  const myInit = {
    body: action.payload,
  };
  try {
    yield orgsApiClient.post(pathTemplate, myInit);
    if (action.payload.receiver == "providers") {
      yield put(
        showSuccessMessage("notification sent to providers successfully")
      );
    } else if (action.payload.receiver == "users") {
      yield put(showSuccessMessage("notification sent to users successfully"));
    }
  } catch (e) {
    yield put(showErrorMessage(e));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_TOTAL_STATS, getTotalStats),
    takeEvery(GET_ORGS_ACTIVITY, getOrgsActivity),
    takeEvery(GET_CALLVITA_FEES_REQUEST, getCallvitaFeesRequest),
    takeEvery(CREATE_CALLVITA_FEES_REQUEST, createCallvitaFeesRequest),
    takeEvery(EDIT_CALLVITA_FEES_REQUEST, editCallvitaFeesRequest),
    takeEvery(DELETE_CALLVITA_FEES_REQUEST, deleteCallvitaFeesRequest),
    takeEvery(GET_CURRENCY, getCurrencies),
    takeEvery(SEND_NOTIFICATION, sendNotifications),
  ]);
}
