import { organizationListItem, basicInfoListItem, addressListItem } from '../model/organizationListItem';

const basicInfoToBasicInfoListItem = basicInfo => {
	const {
		legalName,
		orgType,
		speciality,
		phoneNumber
	} = basicInfo;
	return new basicInfoListItem({
		legalName,
		orgType,
		speciality,
		phoneNumber
	})
}

const addressToAdrressListItem = address => {
	const {
		city,
		country,
		province,
		postalCode,
		address1,
		address2
	} = address
	const add = new addressListItem({
		city,
		country,
		province,
		postalCode,
		address1,
		address2
	});

	return add;
}

export const organizationToOrganizationListItem = organization => {
	const { basicInfo } = organization;
	const address = addressToAdrressListItem(basicInfo);
	const basic_info = basicInfoToBasicInfoListItem(basicInfo);

	return new organizationListItem({
		basic_info,
		status: basicInfo.status,
		address
	})
}

