import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Card, CardContent, Menu, MenuItem, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import IntlMessages from 'util/IntlMessages';
import { Badge } from 'reactstrap';
import moment from 'moment';

const styles = theme => ({
  card: {
    margin: theme.spacing(1)
  }
})

class CardListItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined,
      menuState: false
    }
  }

  onContactOptionSelect = event => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  handleMenuRequestClose = () => {
    this.setState({ menuState: false });
  };

  handleMenuClick = (option, item, history) => {
    this.props.onMenuItemClicked(option, item, history);
    this.setState({
      menuState: false
    });
  }

  handleVisibility(id) {
    const { permissions } = this.props.loggedInUser;
    switch (id) {
      case 'QUEUE':
        return permissions.includes("CAN_ADD_USER_TO_QUEUE");
      case 'EVENTS':
        return permissions.includes("EVENTS_READ");
      case 'SUMMARY':
        return permissions.includes("SUMMARY_READ");
      default:
        return true;
    }
  }

  render() {
    const { history } = this.props;
    const { firstName, lastName } = this.props.model.basicInfo;
    const { start_time, id } = this.props.model;
    const options = [
      {
        label: "user.profile",
        id: "VIEW"
      },
      {
        label: "user.healthEvents",
        id: "EVENTS"
      },
      {
        label: "user.healthSummary",
        id: "SUMMARY"
      },
      {
        label: "user.remove",
        id: "REMOVE"
      }
    ]

    return (
      <Card className={this.props.classes.card}>
        <CardContent>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ textTransform: 'capitalize' }}>{firstName}  {lastName}</label>
              <label style={{ textTransform: 'capitalize' }}>{moment.utc(start_time).local().format('hh:mm A')}  </label>
            </div>

            <div>
              {this.props.index === 0 && <Badge color="success" pill><IntlMessages id="reservation.queue.inService" /></Badge>}

              <Button variant="contained" size="small" style ={{marginRight: 8}} onClick={() => {
                this.props.getUser(id)
                this.props.closeDrawer()
                history.push('/app/my-health/events')
              }}>
                <IntlMessages id="reservation.queue.start" />
              </Button>

              <Button variant="contained" size="small" onClick={() => this.props.completeReservation()}>
                <IntlMessages id="reservation.queue.complete" />
              </Button>
              <IconButton className="icon-btn p-2" onClick={this.onContactOptionSelect}>
                <i className="zmdi zmdi-more-vert" />
              </IconButton>
              <Menu id="long-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.menuState}
                onClose={this.handleMenuRequestClose}
                MenuListProps={{
                  style: {
                    width: 150
                  }
                }}>
                {options.map(option => {
                  return (this.handleVisibility(option.id) &&
                    <MenuItem
                      key={option.id}
                      onClick={() => this.handleMenuClick(option, this.props.model, this.props.history)}
                    >
                      <IntlMessages id={option.label} />
                    </MenuItem>
                  )
                })}
              </Menu>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(withRouter(CardListItem));