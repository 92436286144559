import { GET_DR_DATA_RETURNED, SET_DR_ID, UPDATE_FILTERS } from '../../../user/state/action/actionTypes';
import {
  GET_PROVIDER_TDS_RETURNED,
  TIME_SAVED,
  UPDATE_PROVIDER_TD_RETURNED,
  DELETE_PROVIDER_TD_RETURNED,
  GET_PROVIDER_TDS_REQUEST,
  GET_TIME_SLOT_RETURNED,
  RESET_PROVIDERS_CALENDAR,
  GET_RESERVATIONS_RETURNED,
  GET_RESERVATIONS,
  MY_RESERVATIONS,
  MYRESERVATIONS_RETURNED,
  SET_SEARCH,
  CHANGE_RESERVATION_STATUS_RETURNED,
  SEARCH_RETURNED,
  SET_FILTER,
  GET_ALL_DOCTORS_RETURNED,
  GET_ALL_DOCTORS,
  GET_SLOTS_RETURNED,
  GET_SLOTS,
  RESET_DR_SLOTS,
  RESERVE_RETURN,
  RESET_ALL_DOCTORS,
  QUEUE_WARNING,
  RESET_FILTERS,
  RESET_STATUS,
  RESET_SEARCH,
  UPDATE_RES_FILTERS,
  SORT,
  RESET_REDIRECT,
  RESET_ORG_RES_FILTERS,
  FIND_A_DOCTOR_RESET
} from '../constants/ActionTypes';
import { ADD_USERS_TO_QUEUE_RETURNED, DELETE_QUEUE_USER_RETURNED, GET_DR_DATA_REQUESTED, RESERVED } from '../../../user/state/action/actionTypes';
import { SIGNOUT_USER_SUCCESS } from '../../../../../constants/ActionTypes';
import { orgFiltersItems } from '../../../organization/state/reducers/org-filters';
import moment from 'moment';

const daysOrder = {
  sunday: 1,
  monday: 2,
  tuesday: 3,
  wednesday: 4,
  thursday: 5,
  friday: 6,
  saturday: 7
};

const initialState = {
  /** Find a doctor **/
  allDrs: [],
  statusReturned: false,
  pageLoading: false,
  nextToken: null,
  drSlots: [],
  reset: false,
  resetSearch: false,
  reservedSlot: null,
  availableDate: null,
  availableDays: [],
  findADoctorReset: false,
  findADoctorFilters:
    [
      {
        id: 'ORGS_TYPES',
        title: 'default.type',
        items: orgFiltersItems
      }],
  /** ------- **/
  reserveRedirect: false,
  newReservationId: null,
  loading: false,
  selectedDrId: '',
  timeShifts: [],
  timeSlot: 0,
  drs: [],
  reservations: [],
  myreservations: [],
  search: [],
  filterOptions: [{
    id: 'RESERVATIONS_TYPES',
    title: 'reservation.types',
    items: [
      {
        id: 'ALL',
        title: 'reservation.all',
        isSelected: true
      }, {
        id: 'BOOKED',
        title: 'reservation.booked',
        isSelected: false
      },
      {
        id: 'CANCELLED',
        title: 'reservation.cancelled',
        isSelected: false
      },
      {
        id: 'CHECKEDIN',
        title: 'reservation.checkedIn',
        isSelected: false
      },
    ]
  }],
  myReservationsFilters:
    [{
      id: 'RES_TYPES',
      title: 'event.filters.default',
      items: [
        {
          id: 'Booked',
          title: 'reservation.booked',
          isSelected: true
        },
        {
          id: 'Cancelled',
          title: 'reservation.cancelled',
          isSelected: false
        },
        {
          id: 'Completed',
          title: 'reservation.completed',
          isSelected: false
        }
      ]
    }],
  queueWarning: false
}

export const reservations = (state = initialState, action) => {
  switch (action.type) {
    case GET_DR_DATA_REQUESTED:
      return {
        ...state,
        loading: true
      }

    case GET_DR_DATA_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          drs: action.payload.drs,
          loading: false
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }

    case RESET_STATUS:
      return {
        ...state,
        statusReturned: false
      }

    case MY_RESERVATIONS:
      return {
        ...state,
        loading: true
      }

    case CHANGE_RESERVATION_STATUS_RETURNED:
      if (action.payload.error) {
        return {
          ...state
        }
      }
      else {
        if (!action.payload.cancelRes) {
          if (action.payload.myRes) {
            let newRes = [...state.myreservations]
            const index = state.myreservations.findIndex(item => item.id === action.payload.ids[0]);
            newRes[index].status = action.payload.status
            return {
              ...state,
              myreservations: newRes
            }
          } else {
            let newRes = [...state.reservations]
            let reserveIds = action.payload.ids;
            newRes.forEach(reservation => {
              if (reserveIds.includes(reservation.id)) {
                reservation.status = action.payload.status
              }
            })
            return {
              ...state,
              reservations: newRes
            }
          }
        } else {
          return {
            ...state,
            statusReturned: true
          }
        }
      }

    case SET_DR_ID:
      return {
        ...state,
        selectedDrId: action.payload
      }

    case SEARCH_RETURNED:
      return {
        ...state,
        search: action.payload.results
      }

    case GET_PROVIDER_TDS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case MYRESERVATIONS_RETURNED:
      return {
        ...state,
        loading: false,
        myreservations: action.payload.myreservations
      }

    case GET_PROVIDER_TDS_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          timeShifts: action.payload.timeShifts,
          loading: false
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }

    case UPDATE_RES_FILTERS:
      let newResFilter = [...action.payload.filters]
      const index = newResFilter[0].items.findIndex(x => x.id === action.payload.id)
      newResFilter[0].items[index].isSelected = !newResFilter[0].items[index].isSelected;

      const oneTrue = newResFilter[0].items.reduce((accum, item) => {
        accum = accum || item.isSelected
        return accum;
      }, false)
      if (!oneTrue) {
        newResFilter[0].items[0].isSelected = true;
      }
      return {
        ...state,
        myReservationsFilters: newResFilter
      }
    case UPDATE_PROVIDER_TD_RETURNED:
      if (!action.payload.error) {
        const newTimeShifts = [...state.timeShifts];
        const timeShiftIndex = newTimeShifts.findIndex(timeShift => {
          return timeShift.id === action.payload.timeShift.id;
        });
        newTimeShifts[timeShiftIndex] = action.payload.timeShift;
        newTimeShifts.sort(function (a, b) {
          if (daysOrder[a.day] < daysOrder[b.day] || (daysOrder[a.day] === daysOrder[b.day] && moment(a.startTime, "HH:mm") < moment(b.startTime, "HH:mm"))) {
            return -1;
          }
          if (daysOrder[a.day] > daysOrder[b.day]) {
            return 1;
          }
          return 0;
        });

        return {
          ...state,
          timeShifts: newTimeShifts
        }
      } else {
        return {
          ...state
        }
      }

    case TIME_SAVED:
      if (!action.payload.error) {
        const timeShiftsNew = [...state.timeShifts]
        timeShiftsNew.push(action.payload.timeShift);
        timeShiftsNew.sort(function (a, b) {
          if (daysOrder[a.day] < daysOrder[b.day] || (daysOrder[a.day] === daysOrder[b.day] && moment(a.startTime, "HH:mm") < moment(b.startTime, "HH:mm"))) {
            return -1;
          }
          if (daysOrder[a.day] > daysOrder[b.day]) {
            return 1;
          }
          return 0;
        });

        return {
          ...state,
          timeShifts: timeShiftsNew
        }
      } else {
        return {
          ...state
        }
      }

    case DELETE_PROVIDER_TD_RETURNED:
      if (!action.payload.error) {
        const deletedTimeShift = action.payload.timeShiftId;
        let newTimeShiftsAfterDelete = [...state.timeShifts]
        newTimeShiftsAfterDelete = newTimeShiftsAfterDelete.filter(timeShift => {
          return timeShift.id !== deletedTimeShift;
        });
        return {
          ...state,
          timeShifts: newTimeShiftsAfterDelete
        }
      } else {
        return {
          ...state
        }
      }

    case GET_TIME_SLOT_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          timeSlot: action.payload.timeSlot
        }
      }
      return {
        ...state
      }

    case RESET_PROVIDERS_CALENDAR:
      return initialState;

    case GET_RESERVATIONS: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_RESERVATIONS_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          loading: false,
          reservations: action.payload.reservations
        }
      }
      return {
        ...state,
        loading: false,
      }

    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload
      }
    }

    case UPDATE_FILTERS:
      const id = action.payload;
      const newFilters = [...state.filterOptions];
      newFilters.forEach(filter => {
        filter.items.forEach(item => {
          item.isSelected = false;
        })
      });
      newFilters.forEach(filter => {
        filter.items.forEach(item => {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        })
      });
      return {
        ...state,
        filterOptions: newFilters
      }

    case ADD_USERS_TO_QUEUE_RETURNED:
      if (!action.payload.error && !action.payload.walkIn) {
        let newReservations = [...state.reservations]
        const index = state.reservations.findIndex(item => item.id === action.payload.id);
        newReservations[index].status = 'checkedIn'
        return {
          ...state,
          reservations: newReservations
        }
      }
      else if (!action.payload.error && action.payload.walkIn) {
        let newReservations = [...state.reservations];
        newReservations.push(action.payload.res)
        return {
          ...state,
          reservations: newReservations
        }
      }
      else {
        return {
          ...state
        }
      }

    case DELETE_QUEUE_USER_RETURNED:
      let newRes = [...state.reservations];
      if (!action.payload.delete) {
        const newResAfterComp = newRes.filter(res => {
          return res.id !== action.payload.reservation_id;
        });

        return {
          ...state,
          reservations: newResAfterComp
        }
      } else {
        return {
          ...state
        }
      }

    case GET_ALL_DOCTORS:
      if (state.nextToken) {
        return {
          ...state,
          pageLoading: true
        }
      }
      return {
        ...state,
        loading: true
      }

    case GET_ALL_DOCTORS_RETURNED:
      if (action.payload.error) {
        return {
          ...state,
          loading: false,
          pageLoading: false
        }
      }
      else if (!action.payload.search) {
        return {
          ...state,
          allDrs: [...state.allDrs, ...action.payload.drs],
          nextToken: action.payload.nextToken,
          loading: false,
          pageLoading: false
        }
      }
      else {
        return {
          ...state,
          allDrs: action.payload.drs,
          nextToken: action.payload.nextToken,
          loading: false,
          pageLoading: false
        }
      }

    case RESET_ALL_DOCTORS:
      return {
        ...state,
        allDrs: []
      }

    case GET_SLOTS:
      return {
        ...state,
        slotsLoading: true,
        availableDate: null,
        availableDays: []
      }

    case GET_SLOTS_RETURNED:
      return {
        ...state,
        drSlots: action.payload.slots,
        reservedSlot: action.payload.reservedSlot,
        slotsLoading: false,
        availableDate: action.payload.availableDate,
        availableDays: action.payload.availableDays
      }

    case RESET_DR_SLOTS:
      return {
        ...state,
        drSlots: [],
        reservedSlot: null
      }

    case RESERVED:
      if (action.payload.error) {
        return {
          ...state
        }
      }
      return {
        ...state,
        reserveRedirect: true,
        newReservationId: action.payload.newReservationId
      }

    case RESERVE_RETURN:
      if (action.payload.error) {
        return {
          ...state,
          drSlots: []
        }
      }
      return {
        ...state,
        reserveRedirect: true,
        newReservationId: action.payload.newReservationId,
        drSlots: []
      }

    case SET_FILTER:
      const filter_id = action.payload;
      const newFilter = [...state.myReservationsFilters];
      newFilter.forEach(filter => {
        filter.items.forEach(item => {
          item.isSelected = false;
        })
      });
      if (filter_id) {
        newFilter.forEach(filter => {
          filter.items.forEach(item => {
            if (item.id === filter_id) {
              item.isSelected = !item.isSelected;
            }
          })
        });
      }
      return {
        ...state,
        myReservationsFilters: newFilter
      };

    case SORT:
      let newSearch = state.search;
      switch (action.payload) {
        case "ReservationAsc":
          newSearch = newSearch.sort(function (a, b) {
            if (moment(a.reservation_date) < moment(b.reservation_date) || (a.reservation_date === b.reservation_date && moment(a.start_time, "HH:mm") < moment(b.start_time, "HH:mm"))) {
              return -1;
            }
            if (moment(a.reservation_date) > moment(b.reservation_date) || (a.reservation_date === b.reservation_date && moment(a.start_time, "HH:mm") > moment(b.start_time, "HH:mm"))) {
              return 1;
            }
            return 0;
          });
          break;

        case "ReservationDesc":
          newSearch = newSearch.sort(function (a, b) {
            if (moment(a.reservation_date) > moment(b.reservation_date) || (a.reservation_date === b.reservation_date && moment(a.start_time, "HH:mm") > moment(b.start_time, "HH:mm"))) {
              return -1;
            }
            if (moment(a.reservation_date) < moment(b.reservation_date) || (a.reservation_date === b.reservation_date && moment(a.start_time, "HH:mm") < moment(b.start_time, "HH:mm"))) {
              return 1;
            }
            return 0;
          });
          break;

        case "Newest":
          newSearch = newSearch.sort(function (a, b) {
            if (moment(a.created_at) > moment(b.created_at)) {
              return -1;
            }
            if (moment(a.created_at) < moment(b.created_at)) {
              return 1;
            }
            return 0;

          });
          break;

        default:
          break;
      }
      return {
        ...state,
        search: newSearch
      }

    case QUEUE_WARNING:
      return {
        ...state,
        queueWarning: action.payload
      }

    case RESET_FILTERS:
      return {
        ...state,
        reset: action.payload
      }

    case RESET_SEARCH:
      return {
        ...state,
        resetSearch: action.payload
      }

    case RESET_REDIRECT:
      return {
        ...state,
        reserveRedirect: false,
        newReservationId: null
      }

    case RESET_ORG_RES_FILTERS:
      return {
        ...state,
        filterOptions: initialState.filterOptions
      }

    case SIGNOUT_USER_SUCCESS:
      return initialState;

    case FIND_A_DOCTOR_RESET:
      return { ...state, findADoctorReset: action.payload }

    default:
      return state;
  }
}