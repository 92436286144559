export const MY_CLINICS_REQUESTED = "MY_CLINICS_REQUESTED";
export const MY_CLINICS_RETURNED = "MY_CLINICS_RETURNED";
export const GET_ALL_CLINICS_REQUESTED = "GET_ALL_CLINICS_REQUESTED";
export const GET_ALL_CLINICS_RETURNED = "GET_ALL_CLINICS_RETURNED";
export const SEARCH_REQUESTED = "SEARCH_REQUESTED";
export const SEARCH_RETURNED = "SEARCH_RETURNED";
export const ADD_CLINIC_TO_USER_REQUEST = "ADD_CLINIC_TO_USER_REQUEST";
export const ADD_USER_CLINIC_RETURNED = "ADD_USER_CLINIC_RETURNED";
export const DELETE_CLINIC_REQUESTED = "DELETE_CLINIC_REQUESTED";
export const DELETE_CLINIC_RETURNED = "DELETE_CLINIC_RETURNED";
export const RESET_WARNING = "RESET_WARNING";
export const RESET_ERROR = "RESET_ERROR";