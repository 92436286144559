export const orgFiltersItems = [
  {
    id: 'GENERAL_SURGERY',
    title: 'org.type.generalSurgery',
    isSelected: false,
    value: 'generalSurgery'
  },
  {
    id: 'GYNECOLOGY',
    title: 'org.type.obstertricsGynecology',
    isSelected: false,
    value: 'obstertricsGynecology'
  },
  {
    id: 'CARDIOLOGY',
    title: 'org.type.cardiology',
    isSelected: false,
    value: 'cardiology'
  },
  {
    id: 'PEDIATRIC',
    title: 'org.type.pediatric',
    isSelected: false,
    value: 'pediatric'
  },
  {
    id: 'EAR_NOSE_THROAT',
    title: 'org.type.earNoseThroat',
    isSelected: false,
    value: 'earNoseThroat'
  },
  {
    id: 'HEPATOLOGY',
    title: 'org.type.gastroenterologyHepatology',
    isSelected: false,
    value: 'gastroenterologyHepatology'
  },
  {
    id: 'NEUROLOGY',
    title: 'org.type.neurology',
    isSelected: false,
    value: 'neurology'
  },
  {
    id: 'HEMATOLOGY',
    title: 'org.type.hematology',
    isSelected: false,
    value: 'hematology'
  },
  {
    id: 'ONCOLOGY',
    title: 'org.type.oncology',
    isSelected: false,
    value: 'oncology'
  },
  {
    id: 'ANDROLOGY',
    title: 'org.type.andrology',
    isSelected: false,
    value: 'andrology'
  },
  {
    id: 'DERMATOLOGY',
    title: 'org.type.dermatologyVenereology',
    isSelected: false,
    value: 'dermatologyVenereology'
  },
  {
    id: 'PLASTIC_SURGERY',
    title: 'org.type.plasticSurgery',
    isSelected: false,
    value: 'plasticSurgery'
  },
  {
    id: 'NEPHROLOGY',
    title: 'org.type.nephrology',
    isSelected: false,
    value: 'nephrology'
  },
  {
    id: 'PULMNOLOGY',
    title: 'org.type.pulmonology',
    isSelected: false,
    value: 'pulmonology'
  },
  {
    id: 'OPTHALMOLOGY',
    title: 'org.type.ophthalmology',
    isSelected: false,
    value: 'ophthalmology'
  },
  {
    id: 'GENERAL_DOCTOR',
    title: 'org.type.generalDoctor',
    isSelected: false,
    value: 'generalDoctor'
  },
  {
    id: 'ORTHOPEDICS',
    title: 'org.type.orthopedics',
    isSelected: false,
    value: 'orthopedics'
  },
  {
    id: 'ANESTHESIA',
    title: 'org.type.anesthesia',
    isSelected: false,
    value: 'anesthesia'
  },
  {
    id: 'SUGAR',
    title: 'org.type.sugarEndocrinology',
    isSelected: false,
    value: 'sugarEndocrinology'
  },
  {
    id: 'INTERNAL_MEDICINE',
    title: 'org.type.internalMedicine',
    isSelected: false,
    value: 'internalMedicine'
  },
  {
    id: 'DENTISTRY',
    title: 'org.type.dentistry',
    isSelected: false,
    value: 'dentistry'
  },
  {
    id: 'VASCULAR_MEDICINE',
    title: 'org.type.vascularMedicine',
    isSelected: false,
    value: 'vascularMedicine'
  },
  {
    id: 'PHYSIOTHERAPY',
    title: 'org.type.physiotherapyRehabilitation',
    isSelected: false,
    value: 'physiotherapyRehabilitation'
  },
  {
    id: 'NEONATOLOGY',
    title: 'org.type.neonatology',
    isSelected: false,
    value: 'neonatology'
  },
  {
    id: 'IMMUNOLOGY',
    title: 'org.type.rheumatologyImmunology',
    isSelected: false,
    value: 'rheumatologyImmunology'
  },
  {
    id: 'PAIN',
    title: 'org.type.painMedicine',
    isSelected: false,
    value: 'painMedicine'
  },
  {
    id: 'GERIATRICS',
    title: 'org.type.geriatrics',
    isSelected: false,
    value: 'geriatrics'
  },
  {
    id: 'PSYCHATRY',
    title: 'org.type.psychiatry',
    isSelected: false,
    value: 'psychiatry'
  },
  {
    id: 'RADIOLOGY',
    title: 'org.type.radiology',
    isSelected: false,
    value: 'radiology'
  },
  {
    id: 'CONCENTRATION',
    title: 'org.type.concentration',
    isSelected: false,
    value: 'concentration'
  },
  {
    id: 'ANATOMY',
    title: 'org.type.anatomy',
    isSelected: false,
    value: 'anatomy'
  },
  {
    id: 'UROLOGY',
    title: 'org.type.urology',
    isSelected: false,
    value: 'urology'
  }
];