import Preconditions from '../../../../../core/utils/Preconditions';
import moment from 'moment';
import {
  EVENT_ADDED,
  GET_EVENTS_RETURNED,
  EVENT_UPDATED,
  ITEM_SELECTED,
  UPDATE_NOTE_TYPE,
  NOTE_ADDED,
  VITAL_NOTE_ADDED,
  UPDATE_SELECTED_NOTE,
  NOTE_EDITED,
  NOTE_DELETED,
  GET_NOTES_RETURNED,
  GET_NOTES_REQUEST,
  TRANSLATE_DONE,
  GET_EVENTS,
  COMMENT_SAVED,
  COMMENTS_RETURNED,
  COMMENT_DELETED,
  RESET_NOTE_MODAL,
  UPDATE_NOTE_INFO,
  UPDATE_EVENT_FILTERS,
  UPDATE_TIME_FILTERS,
  RESET_EDIT_EVENT_MODE,
  GET_COMMENTS_REQUEST,
  SEARCH_RESULT,
  UPDATE_DRAFT_VITAL_NOTE,
  SEARCH_EVENTS,
  VITAL_NOTE_EDITED
} from './../constants/ActionTypes';
import { SIGNOUT_USER_SUCCESS } from '../../../../../../constants/ActionTypes';

const initialState = {
  events: [],
  eventFilters:
    [{
      id: 'EVENT_TYPES',
      title: 'event.filters.default',
      items: [
        {
          id: 'ALL',
          title: 'event.filters.all',
          isSelected: true
        },
        {
          id: 'VISITS',
          title: 'event.filters.visits',
          isSelected: false
        },
        {
          id: 'EXTERNAL',
          title: 'event.filters.external',
          isSelected: false
        }
      ]
    }],
  timeFilters: {
    startDate: moment().locale('en').subtract(1, 'months').format(),
    endDate: moment().locale('en').format()
  },
  selectedEvent: null,
  draftNote: null,
  draftVitalNote: null,
  noteType: null,
  vitalsNote: null,
  isEdit: false,
  generalNotes: [],
  loading: false,
  loader: false,
  commentsLoading: false
}

export const myHealthEvents = (state = initialState, action) => {
  Preconditions.shouldBeDefined(action);
  switch (action.type) {
    case SEARCH_EVENTS: {
      return {
        ...state,
        loader: true
      }
    }

    case SEARCH_RESULT:
      if (!action.payload.error) {
        return {
          ...state,
          events: action.payload.events,
          loader: false
        }
      } else {
        return {
          ...state,
          loader: false
        }
      }

    case GET_EVENTS:
      return {
        ...state,
        loader: true
      }

    case UPDATE_EVENT_FILTERS:
      const id = action.payload;
      const newFilters = [...state.eventFilters];
      newFilters.forEach(filter => {
        filter.items.forEach(item => {
          item.isSelected = false;
        })
      });
      newFilters.forEach(filter => {
        filter.items.forEach(item => {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        })
      });
      return {
        ...state,
        eventFilters: newFilters
      };

    case UPDATE_TIME_FILTERS:
      return {
        ...state,
        timeFilters: action.payload
      }

    case GET_EVENTS_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          events: action.payload.events,
          loader: false
        }
      } else {
        return {
          ...state,
          loader: false
        }
      }

    case EVENT_ADDED:
      if (!action.payload.error) {
        return {
          ...state,
          selectedEvent: action.payload.event
        }
      } else {
        return {
          ...state
        }
      }

    case EVENT_UPDATED:
      if (!action.payload.error) {
        return {
          ...state,
          selectedEvent: action.payload.event
        }
      } else {
        return {
          ...state
        }
      }

    case ITEM_SELECTED:
      return {
        ...state,
        selectedEvent: action.payload
      }

    case RESET_EDIT_EVENT_MODE:
      return {
        ...state,
        selectedEvent: null,
        generalNotes: []
      }

    case GET_NOTES_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_NOTES_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          generalNotes: action.payload.notes,
          loading: false
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }

    case UPDATE_NOTE_INFO:
      return {
        ...state,
        draftNote: action.payload
      }

    case UPDATE_DRAFT_VITAL_NOTE:
      return {
        ...state,
        draftVitalNote: action.payload
      }

    case UPDATE_NOTE_TYPE:
      return {
        ...state,
        noteType: action.payload
      }

    case VITAL_NOTE_ADDED:
      if (!action.payload.error) {
        return {
          ...state,
          noteType: null
        }
      }
      return {
        ...state
      }

    case NOTE_ADDED:
      if (!action.payload.error) {
        const newGeneralNotes = [...state.generalNotes];
        newGeneralNotes.unshift(action.payload.convertedNote);
        return {
          ...state,
          generalNotes: newGeneralNotes,
          draftNote: null,
          noteType: null,
          loadingAddNoteModal: false
        }
      } else {
        return {
          ...state,
          loadingAddNoteModal: false
        }
      }

    case UPDATE_SELECTED_NOTE:
      if (action.payload.type === "general") {
        return {
          ...state,
          draftNote: action.payload.note,
        }
      }
      return { ...state, isEdit: true };

    case NOTE_EDITED:
      if (!action.payload.error) {
        const editedNote = action.payload.convertedNote;
        const newNotes = [...state.generalNotes];
        const noteIndex = newNotes.findIndex(note => {
          return note.id === editedNote.id;
        });
        newNotes[noteIndex] = editedNote;

        return {
          ...state,
          draftNote: null,
          isEdit: false,
          generalNotes: newNotes,
        }
      }
      else {
        return {
          ...state
        }
      }

    case RESET_NOTE_MODAL:
      return {
        ...state,
        draftNote: null,
        draftVitalNote: null,
        isEdit: false
      }

    case NOTE_DELETED:
      if (!action.payload.error) {
        const deletedNote = action.payload.note;
        const newNotes = state.generalNotes.filter(note => {
          return note.id !== deletedNote.id;
        });
        return {
          ...state,
          generalNotes: newNotes
        }
      } else {
        return {
          ...state
        }
      }

    case VITAL_NOTE_EDITED:
      if (action.payload.error) {
        return {
          ...state
        }
      }
      return {
        ...state,
        isEdit: false
      }

    case TRANSLATE_DONE:
      const newNote = { ...state.draftNote };
      newNote.translatedText.text = action.payload;
      return {
        ...state,
        draftNote: newNote
      }

    case COMMENT_SAVED:
      if (!action.payload.error) {
        const addedComment = action.payload.convertedComment;
        const notes = [...state.generalNotes];
        const i = notes.findIndex(note => {
          return note.id === addedComment.model.note_id;
        });

        notes[i].comments.push(addedComment);
        notes[i].commentsLength = notes[i].comments.length;

        return {
          ...state,
          generalNotes: notes
        }
      } else {
        return {
          ...state
        }
      }

    case COMMENT_DELETED:
      if (!action.payload.error) {
        const deletedComment = action.payload.comment;
        const newNotes = [...state.generalNotes];
        const index = newNotes.findIndex(note => {
          return note.id === deletedComment.model.note_id;
        });

        const newComments = newNotes[index].comments.filter(comment => {
          return comment.id !== deletedComment.id;
        });

        newNotes[index].comments = newComments;
        newNotes[index].commentsLength = newComments.length;

        return {
          ...state,
          generalNotes: newNotes
        }
      } else {
        return {
          ...state
        }
      }

    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        commentsLoading: true
      }

    case COMMENTS_RETURNED:
      if (!action.payload.error) {
        const notesModified = [...state.generalNotes];
        const noteId = action.payload.noteId;
        const comments = action.payload.comments;
        const noteIndex = notesModified.findIndex(note => {
          return note.id === noteId;
        })
        notesModified[noteIndex].comments = comments;

        return {
          ...state,
          generalNotes: notesModified,
          commentsLoading: false
        }
      } else {
        return {
          ...state,
          commentsLoading: false
        }
      }

    case SIGNOUT_USER_SUCCESS:
      return initialState;

    default:
      return state;
  }
}


