import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  SAVE_TIMING,
  TIME_SAVED,
  GET_PROVIDER_TDS_REQUEST,
  GET_PROVIDER_TDS_RETURNED,
  UPDATE_PROVIDER_TD,
  UPDATE_PROVIDER_TD_RETURNED,
  DELETE_PROVIDER_TD,
  DELETE_PROVIDER_TD_RETURNED,
  SAVE_TIME_SLOT,
  SAVE_TIME_SLOT_DONE,
  GET_TIME_SLOT_RETURNED,
  GET_TIME_SLOT,
  GET_RESERVATIONS,
  GET_RESERVATIONS_RETURNED,
  CHANGE_RESERVATION_STATUS,
  CHANGE_RESERVATION_STATUS_RETURNED,
  MY_RESERVATIONS,
  MYRESERVATIONS_RETURNED,
  SEARCH,
  SEARCH_RETURNED,
  GET_ALL_DOCTORS,
  GET_ALL_DOCTORS_RETURNED,
  GET_SLOTS_RETURNED,
  GET_SLOTS,
  COMPLETE_RESERVATION,
  RESERVE_RETURN,
  GET_SPECIFIC_DR,
} from "../constants/ActionTypes";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../actions/Notifications";
import Preconditions from "../../../../core/utils/Preconditions";
import {
  getTokens,
  getReservationsApiGWClient,
  getUsersApiGWClient,
  getOrgsApiGWClient,
} from "../../../../core/utils/auth-functions";
import { TimeShiftListItem } from "../model/TimeShiftListItem";
import { Reservation } from "../model/Reservation";
import moment from "moment";

import {
  usersApiClient,
  orgsApiClient,
} from "../../../../core/utils/restClient";
const errorMessage = "Something went wrong. Please try again later";

export function* saveTimeShiftRequest(action) {
  const myInit = {
    body: action.payload,
  };
  const pathTemplate = "/api/providercalendar";

  try {
    const res = yield orgsApiClient.post(pathTemplate, myInit);
    if (res.success) {
      const msg = "messages.addTimeShiftSuccess";
      const timeShift = new TimeShiftListItem(res.payload);
      yield put({
        type: TIME_SAVED,
        payload: { timeShift },
      });
      yield put(showSuccessMessage(res.message || msg));
    }
  } catch (err) {
    yield put(showErrorMessage(err.message));
  }
}

export function* saveTimeSlotRequest(action) {
  let msg = "";

  const pathTemplate = "/api/timeslot";
  const myInit = {
    body: action.payload,
  };

  try {
    const res = yield orgsApiClient.post(pathTemplate, myInit);
    if (res.success) {
      msg = "messages.timeSlotSuccess";
      yield put({
        type: SAVE_TIME_SLOT_DONE,
        payload: { },
      });
    }
    yield put(showErrorMessage(res.message || msg));
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getTimeShifts(action) {
  let message = "";
  let timeShifts = [];

  const myInit = {
    queryStringParameters: {
        data: JSON.stringify({ id: action.payload }),
      },
  };
  const pathTemplate = "/api/providercalendar";

  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      timeShifts = res.payload;
      message = "time shifts fetched successfully";
    }

    let timeShiftsConverted = [];
    let order = {
      sunday: 1,
      monday: 2,
      tuesday: 3,
      wednesday: 4,
      thursday: 5,
      friday: 6,
      saturday: 7,
    };

    for (let i = 0; i < timeShifts.length; i++) {
      timeShiftsConverted.push(new TimeShiftListItem(timeShifts[i]));
    }
    timeShiftsConverted.sort(function(a, b) {
      return order[a.day] - order[b.day];
    });

    yield put({
      type: GET_PROVIDER_TDS_RETURNED,
      payload: { timeShifts: timeShiftsConverted },
    });

    yield put(showErrorMessage(res.message || errorMessage));
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getTimeSlot(action) {
  let message = "";
  let timeSlot = 0;

  const myInit = {
    queryStringParameters: {
        data: JSON.stringify({ id: action.payload }),
      },
  };
  let pathTemplate = "/api/timeslot";

  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      message = "time slot fetched successfully";
      timeSlot = new TimeShiftListItem(res.payload);
    }else {
      message = res.message
        ? res.message
        : errorMessage;
    }
    yield put({
      type: GET_TIME_SLOT_RETURNED,
      payload: { timeSlot },
    });

    yield put(showErrorMessage(res.message || errorMessage));
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* editTimeShiftRequest(action) {
  let msg = errorMessage;
  let timeShift = null;
 
  const myInit = {
    body: action.payload
  };
  const pathTemplate = "/api/providercalendar";
  
  try {
    const res = yield orgsApiClient.put(pathTemplate, myInit);
    if (res.success) {
      msg = "messages.updateTimeShiftSuccess";
      timeShift = new TimeShiftListItem(res.payload);

      yield put({
        type: UPDATE_PROVIDER_TD_RETURNED,
        payload: { timeShift },
      });
      yield put(showSuccessMessage(res.message || msg));
    }
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
    yield put({
      type: UPDATE_PROVIDER_TD_RETURNED,
      payload: { timeShift },
    });
  }
}

export function* deleteTimeShiftRequest(action) {
  let msg = "";
  let timeShiftId = "";

  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({ id: action.payload }),
    }
  };
  const pathTemplate = "/api/providercalendar";

  try {
    const res = yield orgsApiClient.delete(pathTemplate, myInit);
    if (res.success) {
      msg = "messages.deleteTimeShiftSuccess";
      timeShiftId = res.payload;

      yield put({
        type: DELETE_PROVIDER_TD_RETURNED,
        payload: { timeShiftId },
      });

      yield put(showSuccessMessage(res.message || msg));
    } else {
      msg = res.message ? res.message : errorMessage;
      yield put(showErrorMessage(res.message || msg));
    }
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getReservations(action) {
  let message = "";
  let reservations = [];

  const myInit = {
    queryStringParameters: {
      data: JSON.stringify(action.payload),
    }
  };
  const pathTemplate = "/api/reservations";

  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      reservations = res.payload;
      message = "Reservations returned successfully";

      let reservationsWithUserDetails = [];
      reservationsWithUserDetails = reservations.map((res) => {
        return new Reservation(res);
      });
      reservationsWithUserDetails.sort((a, b) => {
        return moment.utc(b.created_at).diff(moment.utc(a.created_at));
      });

      yield put({
        type: GET_RESERVATIONS_RETURNED,
        payload: { reservations: reservationsWithUserDetails },
      });

    }
  yield put(showSuccessMessage(res.message || message));
  } catch (err) {
    yield put(showErrorMessage(err.message));
  }
}

function* getDoctorDetails(doctorIds) {
  let msg = "";
  let users = null;

  const pathTemplate = "/api/usercache";
  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({ ids: doctorIds }),
    }
  };

  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      users = res.payload;
    }

  } catch (err) {
    msg = errorMessage;
  }

  return [msg, users];
}

function* getOrgDetails(orgIds) {
  let orgs = null;
  let msg = "";
  
  const myInit = {
    body: { ids: orgIds },
  };
  const pathTemplate = "/api/orgsdetails";

  try {
    const res = yield orgsApiClient.post(pathTemplate, myInit);
    if (res.success) {
      orgs = res.payload;
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (err) {
    msg = errorMessage;
  }

  return [msg, orgs];
}

export function* myReservations(action) {
  let items = action.payload.filters[0].items;
  let stats = items.reduce((temp, x) => {
    if (x.isSelected) {
      temp.push(x.id.toLowerCase());
    }
    return temp;
  }, []);
  let msg = errorMessage;

  const pathTemplate = "/api/myreservations";

  let results = [];
  let myreservations = [];
  let myreservation = null;

  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({ patient_id: action.payload.id, stats }),
    }
  };
  
  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      msg = "Reservations returned successfully";
      results = res.payload;
      
      let drIds = [];
      let orgIds = [];
      for (const reservation of results) {
        if (!drIds.includes(reservation.provider_id)) {
          drIds.push(reservation.provider_id);
        }
        if (!orgIds.includes(reservation.org_id)) {
          orgIds.push(reservation.org_id);
        }
      }

      const orgResp = yield call(
        getOrgDetails,
        orgIds
      );

      const drResp = yield call(
        getDoctorDetails,
        drIds
      );

      for (const reservation of results) {
        let drName = "";
        let org = null;

        let dr = drResp[2].find((y) => y.user_id === reservation.provider_id);
        drName = dr.first_name + " " + dr.last_name;
        org = orgResp[2].find((x) => x.id === reservation.org_id);
        if (!drResp[0] && !orgResp[0]) {
          let { legalName, clinicType, address1, phoneNumber } = org;
          myreservation = {
            ...reservation,
            drName,
            clinicName: legalName,
            type: clinicType,
            address: address1,
            phone: phoneNumber,
            speciality: clinicType,
          };
          myreservations.push(myreservation);
        } else {
          msg = drResp[1] ? drResp[1] : orgResp[1];
          break;
        }
      }

      myreservations.sort(function(a, b) {
        if (
          moment(a.reservation_date) < moment(b.reservation_date) ||
          (a.reservation_date === b.reservation_date &&
            moment(a.start_time, "HH:mm") < moment(b.start_time, "HH:mm"))
        ) {
          return -1;
        }
        if (moment(a.reservation_date) > moment(b.reservation_date)) {
          return 1;
        }
        return 0;
      });

      yield put({
        type: MYRESERVATIONS_RETURNED,
        payload: { myreservations },
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* changeReservationStatus(action) {
  let msg = errorMessage;

  const myInit = {
    body: action.payload,
  };
  const pathTemplate = "/api/reservations";

  try {
    const res = yield orgsApiClient.post(pathTemplate, myInit);
    if (!res.success) {
      msg = res.message ? res.message : errorMessage;
    }
    const { status, ids, myRes, cancelRes } = action.payload;
    if (myRes || cancelRes) {
      msg = "messages.cancelResSuccess";
    } else if (!myRes) {
      if (status === "cancelled") {
        msg = "messages.cancelRes(s)Success";
      } else {
        msg = "messages.changeResStatusSuccess";
      }
    }

  yield put({
    type: CHANGE_RESERVATION_STATUS_RETURNED,
    payload: { status, ids, myRes, cancelRes },
  });

  yield put(showSuccessMessage(res.message || msg));
  } catch (err) {
    yield put(showErrorMessage(err.message));
  }
}

// NO NEED
export function* search(action) {
  Preconditions.shouldBeDefined(action, "search action should be defined");
  let results = action.payload.search;
  let type_search = action.payload.type;
  let name_search = action.payload.name;

  if (action.payload.date) {
    let time_search = moment(action.payload.date)
      .locale("en")
      .format("DD MMMM, YYYY");
    results = results.filter((item) => {
      let time_item = moment
        .utc(item.start_time)
        .local()
        .format("DD MMMM, YYYY");
      return time_search === time_item;
    });
  }

  if (type_search && type_search !== "all") {
    results = results.filter((item) => {
      return item.type === type_search;
    });
  }

  if (name_search) {
    results = results.filter((item) => {
      return item.clinicName.toLowerCase().includes(name_search.toLowerCase());
    });
  }

  yield put({
    type: SEARCH_RETURNED,
    payload: { results },
  });
}

// done
// TODO: fix filtration issue
export function* getAllDoctorsRequest(action) {
  Preconditions.shouldBeDefined(
    action,
    "Get all doctors action should be defined"
  );
  let msg = errorMessage;
  const drs = [];
  const pathTemplate = "/api/alldoctors";
  const myInit = {};

  const { clinicType, city, country, state, name, nextToken } = action.payload;
  myInit.queryStringParameters = {
    data: JSON.stringify({
      ...(nextToken ? { nextToken } : []),
      ...(name ? { name } : []),
      ...(country ? { country } : []),
      ...(city ? { city } : []),
      ...(state ? { state } : []),
      ...(clinicType ? { speciality: clinicType } : []),
    }),
  };

  try {
    const res = yield usersApiClient.get(pathTemplate, myInit);
    if (res.success) {
      drs = res.payload.drs;
      let nextToken = res.payload.nextToken;
      msg = "Doctors retrieved successfully";
      yield put({
        type: GET_ALL_DOCTORS_RETURNED,
        payload: { nextToken, drs, search: action.payload.search },
      });
    } else {
      msg = res.message ? res.message : errorMessage;
      yield put(showErrorMessage(msg));
    }
  } catch (error) {
    yield put(showErrorMessage(error.message || errorMessage));
  }
}

export function* getDrSlots(action) {
  const slots = [];
  let availableDate = action.payload.date;
  let msg = "";
  let reservedSlot = null;
  const availableDays = [];

  let newDate = moment(
    action.payload.date +
      " " +
      moment()
        .locale("en")
        .format("HH:mm")
  )
    .utc()
    .locale("en")
    .format("DD MMMM, YYYY");
  let day = moment
    .utc(action.payload.date)
    .locale("en")
    .format("dddd")
    .toLowerCase();
  let localTime = moment()
    .locale("en")
    .format("HH:mm");
  let timeDiff = new Date().getTimezoneOffset();
  let newActionPayload = {
    ...action.payload,
    date: newDate,
    day,
    timeDiff,
    dateLocal: action.payload.date,
    localTime,
  };
  const pathTemplate = "/api/checkuser";

  const myInit = {
    body: newActionPayload,
  };

  try {
    const res = yield orgsApiClient.post(pathTemplate, myInit);
    if (res.success) {
      reservedSlot = res.payload;
      msg = res.message;

      if (!reservedSlot) {
        const pathTemplate = "/api/allslots";
        const res = yield orgsApiClient.post(pathTemplate, myInit);
        if (res.success) {
          slots = res.payload.slots;
          msg = res.message;
          availableDate = res.payload.date;
          availableDays = res.payload.availableDays;
        }
      }
      
      yield put({
        type: GET_SLOTS_RETURNED,
        payload: { slots, reservedSlot, availableDate, availableDays },
      });
      yield put(showSuccessMessage(res.message || msg));
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (err) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* completeUserReservation(action) {
  let msg = "";
  let optedInSuccess = false;
  let newReservationId = null;

  let req = {
    userId: action.payload.selectedUserName,
    orgId: action.payload.userOrgId,
    role: action.payload.user.role,
    status: "enrolled",
    account_roles: action.payload.user.account_roles,
    user: action.payload.user,
  };

  const myInit = {
    body: req,
  };
  const pathTemplate = "/api/users";

  try {
    const res = yield orgsApiClient.post(pathTemplate, myInit);
    if (res.success) {
      msg = "messages.reserveSuccess";
      optedInSuccess = true;

      const myInit = {
        body: action.payload,
      };
      const pathTemplate = "/api/reserve";

      const res = yield orgsApiClient.post(pathTemplate, myInit);
      if (res.success) {
        msg = "messages.reserveSuccess";
        newReservationId = res.payload
          ? res.payload.id
          : null;
      }

      yield put({
        type: RESERVE_RETURN,
        payload: { newReservationId },
      });
    }
  yield put(showSuccessMessage(res.message || msg));
  } catch (err) {
    yield put(showErrorMessage(err.message));
  }
}

export function* getSpecificDr(action) {
  const drReturned = {};
  const drs = [];
  const pathTemplate = "/api/getspecificdr";
  let msg = "";

  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({ drId: action.payload.drId }),
    },
  };

  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      msg = "Doctors retrieved successfully";
      drReturned = res.payload;

      if (drReturned) {
        drs.push(drReturned);
      }

      yield put({
        type: GET_ALL_DOCTORS_RETURNED,
        payload: { nextToken: null, msg, drs, search: true },
      });
    }
  yield put(showSuccessMessage(res.message || msg));
  } catch (err) {
    yield put(showErrorMessage(err.message));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(SAVE_TIMING, saveTimeShiftRequest),
    yield takeEvery(GET_PROVIDER_TDS_REQUEST, getTimeShifts),
    yield takeEvery(UPDATE_PROVIDER_TD, editTimeShiftRequest),
    yield takeEvery(DELETE_PROVIDER_TD, deleteTimeShiftRequest),
    yield takeEvery(SAVE_TIME_SLOT, saveTimeSlotRequest),
    yield takeEvery(GET_TIME_SLOT, getTimeSlot),
    yield takeEvery(GET_RESERVATIONS, getReservations),
    yield takeEvery(CHANGE_RESERVATION_STATUS, changeReservationStatus),
    yield takeEvery(MY_RESERVATIONS, myReservations),
    yield takeEvery(SEARCH, search),
    yield takeEvery(GET_ALL_DOCTORS, getAllDoctorsRequest),
    yield takeEvery(GET_SLOTS, getDrSlots),
    yield takeEvery(GET_SPECIFIC_DR, getSpecificDr),
    yield takeEvery(COMPLETE_RESERVATION, completeUserReservation),
  ]);
}
