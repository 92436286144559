import {
  ADD_USERS_TO_QUEUE_REQUESTED,
  ADD_USERS_TO_QUEUE_RETURNED,
  CHANGE_USERS_STATUS_RETURNED,
  DELETE_QUEUE_USER_RETURNED,
  DELETE_USER_REQUEST,
  DELETE_USER_RETURNED,
  GET_ADMIN_FILTERED_USERS,
  GET_DR_DATA_RETURNED,
  GET_ORG_USERS_REQUEST,
  GET_USER_RETURNED,
  QUEUE_USERS_REQUESTED,
  QUEUE_USERS_RETURNED,
  RESERVED,
  RESERVE_OPEN,
  RESERVE_TIME,
  RESET_DELETE_ENABLE,
  RESET_DR_ID_APPS_MODAL,
  RESET_QUEUE_ENABLE,
  RESET_RESERVE,
  RESET_RESERVE_DRAWER,
  RESET_TIME_DURATION,
  RESET_USERS,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SEARCH,
  SET_DELETE_MODE,
  SET_DR_ID,
  SET_DR_ID_APPS_MODAL,
  SET_RESERVE_DR_ID,
  SET_SELECT_QUEUE_MODE,
  SET_SELECT_RESERVE_MODE,
  SLOT_SELECTED,
  TIMINGS_RETURNED,
  UPDATE_ADMIN_FILTERS,
  UPDATE_FILTERS,
  UPDATE_USER_EDIT,
  USERS_RETURNED,
  USERS_SEARCH_RETURNED,
  USER_SELECTED,
  USER_UPDATED,
} from "../action/actionTypes";

import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { SIGNOUT_USER_SUCCESS } from "../../../../../constants/ActionTypes";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import moment from "moment";

const initialState = {
  nextToken: null,
  pageLoading: false,
  group: null,
  selectedTime: "",
  selectedDuration: "",
  slotsLoading: false,
  timeEnable: false,
  orgName: "",
  orgPhone: "",
  reset: false,
  drSelectedName: "",
  users: [],
  selectedUser: JSON.parse(localStorage.getItem("selectedUser")),
  deleteUserEnable: false,
  deletedUserId: "",
  queueDrs: [],
  queueUserId: "",
  queueUsersModalEnable: false,
  reserveUsersModalEnable: false,
  reserveUserId: "",
  selectedDrId: "",
  selectedUserName: "",
  selectedDrReserveId: "",
  queueUsers: [],
  warning: false,
  selectedDrIdAppsModal: "",
  loading: false,
  queueLoading: false,
  date: moment().format(),
  time: null,
  timings: [],
  availableDays: [],
  reserveTimeEnable: false,
  loadingAddUserToQueue: false,
  filterOptions: [
    {
      id: "USERS_TYPES",
      title: "org.status.default",
      items: [
        {
          id: "ALL",
          title: "user.status.all",
          icon: (
            <SupervisedUserCircleIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: true,
        },
        {
          id: "PENDING",
          title: "user.status.pending",
          icon: (
            <RemoveCircleIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: false,
        },
        {
          id: "ENROLLED",
          title: "user.status.enrolled",
          icon: (
            <CheckCircleIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: false,
        },
        {
          id: "DENIED",
          title: "user.status.denied",
          icon: (
            <CancelIcon style={{ width: 20, height: 20, marginRight: 5 }} />
          ),
          isSelected: false,
        },
      ],
    },
  ],
  adminFilterOptions: [
    {
      id: "USERS_TYPES",
      title: "user.group.default",
      items: [
        {
          id: "ALL",
          title: "user.group.all",
          icon: (
            <SupervisedUserCircleIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: true,
        },
        {
          id: "USERS",
          title: "user.group.users",
          icon: (
            <AccountCircleRoundedIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: false,
        },
        {
          id: "DOCTORS",
          title: "user.group.doctors",
          icon: (
            <AccountCircleRoundedIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: false,
        },
        {
          id: "OPERATORS",
          title: "user.group.operators",
          icon: (
            <AccountCircleRoundedIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: false,
        },
        {
          id: "ORG_ADMINS",
          title: "user.group.orgadmins",
          icon: (
            <AccountCircleRoundedIcon
              style={{ width: 20, height: 20, marginRight: 5 }}
            />
          ),
          isSelected: false,
        },
      ],
    },
  ],
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          users: [...state.users, ...action.payload.users],
          loading: false,
          pageLoading: false,
          nextToken: action.payload.nextToken,
        };
      } else {
        return {
          ...state,
          loading: false,
          pageLoading: false,
        };
      }

      case USERS_SEARCH_RETURNED:
        if (!action.payload.error) {
          return {
          ...state,
          users: action.payload.users,
          };
        } else {
          return {
          ...state,
        };
      }

    case SLOT_SELECTED: {
      return {
        ...state,
        selectedTime: action.payload.time,
        selectedDuration: action.payload.duration,
        reserveUsersModalEnable: true,
        timeEnable: true,
        reserveTimeEnable: false,
        timings: [],
        drSelectedName: action.payload.drSelectedName,
      };
    }

    case GET_ADMIN_FILTERED_USERS:
      return {
        ...state,
        pageLoading: true,
        group: action.payload.group,
      };

    case SEARCH:
      return {
        ...state,
        users: action.payload,
      };

    case TIMINGS_RETURNED:
      if (action.payload.error) {
        return {
          ...state,
          slotsLoading: false,
        };
      }

      return {
        ...state,
        timings: action.payload.slots,
        orgName: action.payload.org.basicInfo.clinicType,
        orgPhone: action.payload.org.basicInfo.phoneNumber,
        slotsLoading: false,
        date: action.payload.availableDate,
        availableDays: action.payload.availableDays,
        reservedSlot: action.payload.reservedSlot,
      };

    case USER_SELECTED:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case GET_USER_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          selectedUser: action.payload.newUser,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }

    case UPDATE_USER_EDIT:
      return {
        ...state,
        loading: true,
      };

    case USER_UPDATED:
      if (!action.payload.error) {
        const editedUser = action.payload.user;
        const newUsersEdited = [...state.users];
        const userIndex = newUsersEdited.findIndex((user) => {
          return user.id === editedUser.id;
        });
        newUsersEdited[userIndex] = editedUser;
        return {
          ...state,
          users: newUsersEdited,
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }

    case SAVE_USER:
      return {
        ...state,
        loading: true,
      };

    case RESERVE_TIME:
      return {
        ...state,
        date: action.payload.date,
        slotsLoading: true,
      };

    case RESERVE_OPEN:
      return {
        ...state,
        reserveTimeEnable: true,
      };

    case SAVE_USER_SUCCESS:
      if (!action.payload.error) {
        const newUsers = [...state.users];
        newUsers.push(action.payload.user);
        return {
          ...state,
          users: newUsers,
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }

    case SET_DELETE_MODE:
      return {
        ...state,
        deleteUserEnable: true,
        deletedUserId: action.payload,
      };

    case DELETE_USER_RETURNED:
      if (!action.payload.error) {
        let newWarning = state.warning;
        let newusersAfterDelete = [...state.users];
        if (action.payload.deleted) {
          const deletedUser = action.payload.id;
          newusersAfterDelete = state.users.filter((user) => {
            return user.id !== deletedUser;
          });
        } else {
          newWarning = true;
        }
        return {
          ...state,
          deleteUserEnable: false,
          deletedUserId: "",
          users: newusersAfterDelete,
          warning: newWarning,
        };
      } else {
        return {
          ...state,
        };
      }

    case DELETE_USER_REQUEST:
      return {
        ...state,
        deleteUserEnable: false,
      };

    case SET_SELECT_QUEUE_MODE:
      return {
        ...state,
        queueUsersModalEnable: true,
        queueUserId: action.payload,
      };

    case SET_SELECT_RESERVE_MODE:
      return {
        ...state,
        reserveUserId: action.payload.id,
        selectedUserName: action.payload.name,
      };

    case ADD_USERS_TO_QUEUE_REQUESTED:
      return {
        ...state,
        loadingAddUserToQueue: true,
      };

    case ADD_USERS_TO_QUEUE_RETURNED:
      if (!action.payload.error) {
        if (action.payload.drId === state.selectedDrIdAppsModal) {
          let queueUsersNewAfterAdd = [...state.queueUsers];
          queueUsersNewAfterAdd.push(action.payload.queueUser);
          queueUsersNewAfterAdd.sort(function(a, b) {
            return a.start_time.localeCompare(b.start_time);
          });
          return {
            ...state,
            queueUsersModalEnable: false,
            queueUserId: "",
            selectedDrId: "",
            loadingAddUserToQueue: false,
            queueUsers: queueUsersNewAfterAdd,
          };
        }

        return {
          ...state,
          queueUsersModalEnable: false,
          queueUserId: "",
          selectedDrId: "",
          loadingAddUserToQueue: false,
        };
      }
      return {
        ...state,
        queueUsersModalEnable: false,
        loadingAddUserToQueue: false,
      };

    case SET_DR_ID:
      return {
        ...state,
        selectedDrId: action.payload,
      };

    case SET_RESERVE_DR_ID:
      return {
        ...state,
        selectedDrReserveId: action.payload.id,
        drSelectedName: action.payload.name,
      };

    case GET_DR_DATA_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          queueDrs: action.payload.drs,
        };
      } else {
        return {
          ...state,
        };
      }

    case RESET_DELETE_ENABLE:
      return {
        ...state,
        deleteUserEnable: false,
        deletedUserId: "",
        warning: false,
      };

    case RESET_QUEUE_ENABLE:
      return {
        ...state,
        queueUsersModalEnable: false,
        queueUserId: "",
        selectedDrId: "",
      };

    case RESET_TIME_DURATION:
      return {
        ...state,
        selectedTime: null,
        selectedDuration: null,
      };

    case RESET_RESERVE:
      return {
        ...state,
        reserveUsersModalEnable: false,
        date: moment().format(),
        time: null,
        timings: [],
        queueDrs: [],
        reserveTimeEnable: false,
        selectedTime: null,
        selectedDuration: null,
        selectedDrReserveId: "",
        timeEnable: false,
        reset: !state.reset,
        drSelectedName: "",
      };

    case RESET_RESERVE_DRAWER:
      return {
        ...state,
        reserveTimeEnable: false,
      };

    case RESET_DR_ID_APPS_MODAL:
      return {
        ...state,
        selectedDrIdAppsModal: "",
        reserveUsersModalEnable: false,
      };

    case QUEUE_USERS_RETURNED:
      if (!action.payload.error) {
        const queueUsersNew = action.payload.queueUsers;
        queueUsersNew.sort(function(a, b) {
          return a.start_time.localeCompare(b.start_time);
        });
        return {
          ...state,
          queueUsers: queueUsersNew,
          queueLoading: false,
        };
      } else {
        return {
          ...state,
          queueLoading: false,
        };
      }

    case SET_DR_ID_APPS_MODAL:
      return {
        ...state,
        selectedDrIdAppsModal: action.payload,
      };

    case DELETE_QUEUE_USER_RETURNED:
      if (!action.payload.error) {
        const deletedQueueUser = action.payload.userId;
        const newQueueusersAfterDelete = state.queueUsers.filter((user) => {
          return user.id !== deletedQueueUser;
        });
        return {
          ...state,
          queueUsers: newQueueusersAfterDelete,
        };
      } else {
        return {
          ...state,
        };
      }

    case UPDATE_FILTERS:
      const id = action.payload;
      const newFilters = [...state.filterOptions];
      newFilters.forEach((filter) => {
        filter.items.forEach((item) => {
          item.isSelected = false;
        });
      });
      newFilters.forEach((filter) => {
        filter.items.forEach((item) => {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        });
      });
      return {
        ...state,
        users: [],
        filterOptions: newFilters,
      };

    case UPDATE_ADMIN_FILTERS:
      const filterid = action.payload;
      const newAdminFilters = [...state.adminFilterOptions];
      newAdminFilters.forEach((filter) => {
        filter.items.forEach((item) => {
          item.isSelected = false;
        });
      });
      newAdminFilters.forEach((filter) => {
        filter.items.forEach((item) => {
          if (item.id === filterid) {
            item.isSelected = !item.isSelected;
          }
        });
      });

      return {
        ...state,
        nextToken: null,
        loading: true,
        users: [],
        adminFilterOptions: newAdminFilters,
      };

    case GET_ORG_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case QUEUE_USERS_REQUESTED:
      return {
        ...state,
        queueLoading: true,
      };

    case CHANGE_USERS_STATUS_RETURNED:
      return {
        ...state,
      };

    case RESERVED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        reserveUsersModalEnable: false,
        selectedTime: "",
        selectedDuration: "",
        drSelectedName: "",
        selectedDrReserveId: "",
        timeEnable: false,
        date: moment().format(),
        time: null,
        timings: [],
      };

    case RESET_USERS:
      return {
        ...state,
        users: [],
        queueDrs: [],
        drSelectedName: "",
        timings: [],
      };

    case SIGNOUT_USER_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
