import moment from 'moment';

export class BasicInfo {
	constructor({
		firstName = '',
		lastName = '',
		middleName = '',
		middleName2 = '',
		address1 = '',
		address2 = '',
		city = '',
		state = '',
		postalCode = '',
		phoneNumber = '',
		email = '',
		gender = '',
		maritalStatus = '',
		dateOfBirth = '',
		ssn = '',
		occupation = '',
		weight = 0,
		height = 0,
		country = '',
		bloodType = '',
		createDate = moment.now()
	}) {
		this.firstName = firstName;
		this.lastName = lastName;
		this.middleName = middleName;
		this.middleName2 = middleName2;
		this.address1 = address1;
		this.address2 = address2;
		this.city = city;
		this.state = state;
		this.postalCode = postalCode;
		this.phoneNumber = phoneNumber;
		this.email = email;
		this.gender = gender;
		this.maritalStatus = maritalStatus;
		this.dateOfBirth = dateOfBirth;
		this.ssn = ssn;
		this.bloodType = bloodType;
		this.country = country;
		this.occupation = occupation;
		this.weight = weight;
		this.height = height;
		this.createDate = createDate;
	}
}