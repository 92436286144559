import moment from 'moment';
import Preconditions from '../../../../../core/utils/Preconditions';

export class OriginalText {
	constructor({ text, language, isVoiceUsed }) {
		Preconditions.shouldBeDefined(text, "note text must be defined");
		this.text = text;
		this.language = language;
		this.isVoiceUsed = isVoiceUsed;
	}
}

export class TranslatedText {
	constructor({ text, language }) {
		this.text = text;
		this.language = language;
	}
}

export class NoteListItem {
	constructor({
		id,
		noteType = '',
		originalText = {},
		translatedText = {},
		createdAt = moment.utc().format(),
		createdByName = '',
		shared = false,
		comments = [],
		commentsLength = 0,
		model
	}) {
		Preconditions.shouldBeDefined(noteType, "note Type must be defined");
		Preconditions.shouldBeDefined(originalText, "original Text must be defined");
		Preconditions.shouldBeDefined(createdAt, "created At must be defined");
		Preconditions.shouldBeDefined(originalText, "note originalText must be defined");
		Preconditions.shouldBeDefined(shared, "shared must be defined");
		this.id = id;
		this.noteType = noteType;
		this.originalText = originalText;
		this.translatedText = translatedText;
		this.createdAt = createdAt;
		this.createdByName = createdByName;
		this.shared = shared;
		this.comments = comments;
		this.commentsLength = commentsLength;
		this.model = model;
	}
}