import "assets/vendors/style"

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AppLocale from '../lngProvider';
import ForgetPassword from './ForgetPassword';
import { IntlProvider } from 'react-intl'
import MainApp from 'app/index';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Notifier from './Notifier';
import OrgSignUp from './OrgSignUp';
import RTL from 'util/RTL';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';
import PrivacyPolicy from '../components/PrivacyPolicy'

import asyncComponent from 'util/asyncComponent';
import { changeLocale } from '../app/core/utils/moment-setup';
import { connect } from 'react-redux';
import defaultTheme from './themes/defaultTheme';
import { setInitUrl } from '../actions/Auth';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />}
  />;

class App extends Component {

  componentDidMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale.locale !== this.props.locale.locale) {
      changeLocale(this.props.locale.locale);
      window.location.reload();
    }
  }

  render() {
    const { match, location, locale, authUser, initURL, isDirectionRTL, loggedInUser } = this.props;
    const permissions = loggedInUser ? loggedInUser.permissions : [];
    if (location.pathname === '/') {
      if (authUser === null) {
        return (<Redirect to={'/signin'} />);
      }
      else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/signup') {
        if (permissions.includes("FIND_A_DOCTOR")) {
          if (this.props.searchDrId) {
            this.props.history.push({
              pathname: '/app/reservations/find-doctor',
              search: `?id=${this.props.searchDrId}`
            })
          } else {
            return (<Redirect to={'/app/reservations/find-doctor'} />);
          }
        }
        else if (permissions.includes("VIEW_ORG_DETAILED_USERS")) {
          return (<Redirect to={'/app/clinic'} />);
        }
        else if (permissions.includes("VIEW_DETAILED_ORGS")) {
          return (<Redirect to={'/app/adminpanel'} />);
        }
        else if (permissions.includes("VIEW_OWN_PATIENTS_QUEUE")) {
          return (<Redirect to={'/app/doctorpanel'} />);
        }
      }
      else {
        return (<Redirect to={initURL} />);
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <div
        style={{
          width: '100%'
        }}
      >
        <RTL>
          <MuiThemeProvider theme={applyTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
                // Quick fix to remove errors messages when no translation found
                onError={() => { }}
              >
                <div className="app-main">
                  <Switch>
                    <RestrictedRoute
                      path={`${match.url}app`}
                      authUser={authUser}
                      component={MainApp}
                    />
                    <Route path="/signin" component={SignIn} />
                    {/* <Route path="/signup" component={SignUp} /> */}
                    <Route path="/clinic_signup" component={OrgSignUp} />
                    <Route path="/forget_password" component={ForgetPassword} />
                    <Route path="/reset_password" component={ResetPassword} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route
                      component={asyncComponent(() =>
                        import("components/Error404")
                      )}
                    />
                  </Switch>
                  <Notifier />
                </div>
              </IntlProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </RTL>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, initURL, loggedInUser, searchDrId } = auth;
  return { sideNavColor, locale, isDirectionRTL, authUser, initURL, loggedInUser, searchDrId }
};

export default connect(mapStateToProps, { setInitUrl })(App);
