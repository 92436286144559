import React, { Component } from 'react';
import OrgSignUpStepper from '../app/modules/organization/view/hoc/OrgSignUpStepper';

class OrgSignUp extends Component {
    render() { 
        return (
          <div className="blue-bg">
            <div className="app-clinic-signup-container animated slideInUpTiny animation-duration-3">
              <div className="app-clinic-signup-main-content">
                <OrgSignUpStepper />
              </div>
            </div>
          </div>
        );
    }
}
 
export default OrgSignUp;