import {
  GET_COUNTRIES_RETURNED,
  GET_STATES_RETURNED,
  GET_CITIES_RETURNED,
  CHANGE_COUNTRY_ID,
  CHANGE_STATE_ID,
  GET_COUNTRIES_REQUEST,
  GET_STATES_REQUESTED
} from '../constants/ActionTypes';

const initialState = {
  countries: [],
  states: [],
  cities: [],
  countryId: '',
  stateId: ''
}

export const countries = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES_RETURNED:
      return {
        ...state,
        countries: action.payload.countries
      }
    case GET_STATES_RETURNED:
      return {
        ...state,
        states: action.payload.states
      }
    case GET_CITIES_RETURNED:
      return {
        ...state,
        cities: action.payload.cities
      }

    case CHANGE_COUNTRY_ID:
      return {
        ...state,
        countryId: action.payload
      }

    case CHANGE_STATE_ID:
      return {
        ...state,
        stateId: action.payload
      }

    case GET_STATES_REQUESTED:
      return {
        ...state,
        cities: []
      }

    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        states: [],
        cities: []
      }
    default:
      return { ...state };
  }
}