import {
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_SWEET_SUCCESS_MESSAGE,
  RESET_ERROR,
  RESET_SUCCESS,
  RESET_SWEET_SUCCESS
} from '../constants/ActionTypes';

const initialState = {
  success: false,
  sweetSuccess: false,
  error: false,
  alertMessage: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        error: true,
        alertMessage: action.payload
      }

    case SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        success: true,
        alertMessage: action.payload
      }

    case SHOW_SWEET_SUCCESS_MESSAGE:
      return {
        ...state,
        sweetSuccess: true,
        alertMessage: action.payload
      }

    case RESET_ERROR:
      return {
        ...state,
        error: false,
        alertMessage: ''
      }

    case RESET_SUCCESS:
      return {
        ...state,
        success: false,
        alertMessage: ''
      }

    case RESET_SWEET_SUCCESS:
      return {
        ...state,
        sweetSuccess: false,
        alertMessage: ''
      }

    default:
      return { ...state };
  }
}