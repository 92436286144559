import { all, call, takeEvery, put } from 'redux-saga/effects';
import {
  GET_VITALS,
  VITALS_RETURNED,
  VITAL_NOTE_ADDED,
  ADD_VITAL_NOTE,
  EDIT_VITAL_NOTE,
  VITAL_NOTE_EDITED,
  DELETE_VITALS_NOTE,
  VITALS_NOTE_DELETED
} from '../constants/ActionTypes';
import { showErrorMessage, showSuccessMessage } from '../../../.././../../actions/Notifications';
import { getTokens, getEventsApiGWClient } from '../../../../../core/utils/auth-functions';
import Preconditions from '../../../../../core/utils/Preconditions';
import { eventsApiClient } from '../../../../../core/utils/restClient';

const errorMessage = "Something went wrong. Please try again later";

function convertObject(data) {
  var obj = JSON.parse(JSON.stringify(data))
  return obj;
}

export function* getVitalsRequest() {
  let message = "";
  let vitals = [];

  const pathTemplate = "/api/vitals";

  try {
    const res = yield eventsApiClient.get(pathTemplate);
    if (res.success) {
      message = res.message;
      vitals = res.payload;
    }
    yield put({
      type: VITALS_RETURNED,
      payload: { vitals },
    });
  } catch (e) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* addVitalNoteRequest(action) {
  Preconditions.shouldBeDefined(action, "Add Note Action must be defined");
  const { vitalsNote, event, user, selectedUserId } = action.payload;

  const newNote = {
    vitalsNote,
    createdBy: user.id,
    eventId: event.id,
    userId: selectedUserId
  };

  let renderedVitals = [];
  let message = '';
  let error = false;
  let convertedNote = null;
  let noteId = null;
  let createdAt = null;

  let body = convertObject(newNote);
  let pathTemplate = '/api/vitals';
  let method = 'post';

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient.invokeApi({}, pathTemplate, method, {}, body)
      .then(response => {
        if (response.data.success) {
          message = "messages.addVitalsNoteSuccess";
          renderedVitals = response.data.payload.renderedVitals;
          noteId = response.data.payload.noteId;
          createdAt = response.data.payload.createdAt;
        } else {
          message = response.data.message ? response.data.message : errorMessage;
          error = true;
        }
      })
      .catch(e => {
        message = e.message ? e.message : errorMessage;
        error = true;
      });
    if (!error) {
      const createdByName = user.basicInfo.firstName + ' ' + user.basicInfo.lastName;
      convertedNote = { id: noteId, records: renderedVitals, createdByName, createdBy: user.id, createdAt };
    }
  } else {
    message = errorMessage;
    error = true;
  }

  if(error){
    yield put(showErrorMessage(message));
  }
  else {
    yield put(showSuccessMessage(message));
  }

  yield put({
    type: VITAL_NOTE_ADDED,
    payload: { convertedNote, error }
  });
}

export function* editVitalNoteRequest(action) {
  Preconditions.shouldBeDefined(action, "Edit Note Action must be defined");
  const { editedNote, selectedNote } = action.payload;

  let message = '';
  let error = false;
  let updatedNote = selectedNote;
  let updatedRecords = [];

  let body = convertObject({ vitalsNote: editedNote });
  let pathTemplate = '/api/vitals';
  let method = 'put';
  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient.invokeApi({}, pathTemplate, method, {}, body)
      .then(response => {
        if (response.data.success) {
          message = "messages.editVitalsNoteSuccess";
          updatedRecords = response.data.payload;
        } else {
          message = response.data.message ? response.data.message : errorMessage;
          error = true;
        }
      })
      .catch(e => {
        message = e.message ? e.message : errorMessage;
        error = true;
      });

    if (!error) {
      updatedNote.records = updatedRecords;
    }
  } else {
    message = errorMessage;
    error = true;
  }

  if(error){
    yield put(showErrorMessage(message));
  }
  else {
    yield put(showSuccessMessage(message));
  }

  yield put({
    type: VITAL_NOTE_EDITED,
    payload: { updatedNote, error }
  });
}

export function* deleteVitalsNoteRequest(action) {
  Preconditions.shouldBeDefined(action, "Delete Note Action must be defined");
  const { deletedNote } = action.payload;

  let message = '';
  let error = false;

  let additionalParams = {
    queryParams: {
      data: JSON.stringify(deletedNote)
    }
  };
  let pathTemplate = '/api/vitals';
  let method = 'delete';

  const tokens = yield call(getTokens);
  if (!tokens[3]) {
    const apigClient = getEventsApiGWClient(tokens[0], tokens[1], tokens[2]);
    yield apigClient.invokeApi({}, pathTemplate, method, additionalParams, {})
      .then(response => {
      if (response.data.success) {
        message = "messages.deleteVitalsNoteSuccess";
      } else {
        message = response.data.message ? response.data.message : errorMessage;
        error = true;
      }
    })
      .catch(e => {
        message = e.message ? e.message : errorMessage;
        error = true;
      });
  } else {
    message = errorMessage;
    error = true;
  }

  if(error){
    yield put(showErrorMessage(message));
  }
  else {
    yield put(showSuccessMessage(message));
  }

  yield put({
    type: VITALS_NOTE_DELETED,
    payload: { deletedNote, error }
  });
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_VITALS, getVitalsRequest),
    yield takeEvery(ADD_VITAL_NOTE, addVitalNoteRequest),
    yield takeEvery(EDIT_VITAL_NOTE, editVitalNoteRequest),
    yield takeEvery(DELETE_VITALS_NOTE, deleteVitalsNoteRequest)
  ]);
}