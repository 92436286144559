import React from 'react';
import IntlMessages from '../../../util/IntlMessages';

export const allType = [
  {
    displayComp: <IntlMessages id="org.type.all" />,
    value: 'all',
    id: 0
  }
]

export const types =
  [
    {
      displayComp: <IntlMessages id="org.type.generalSurgery" />,
      value: 'generalSurgery',
      id: 1
    },
    {
      displayComp: <IntlMessages id="org.type.obstertricsGynecology" />,
      value: 'obstertricsGynecology',
      id: 2
    },
    {
      displayComp: <IntlMessages id="org.type.cardiology" />,
      value: 'cardiology',
      id: 3
    },
    {
      displayComp: <IntlMessages id="org.type.pediatric" />,
      value: 'pediatric',
      id: 4
    },
    {
      displayComp: <IntlMessages id="org.type.earNoseThroat" />,
      value: 'earNoseThroat',
      id: 5
    },
    {
      displayComp: <IntlMessages id="org.type.gastroenterologyHepatology" />,
      value: 'gastroenterologyHepatology',
      id: 6
    },
    {
      displayComp: <IntlMessages id="org.type.neurology" />,
      value: 'neurology',
      id: 7
    },
    {
      displayComp: <IntlMessages id="org.type.hematology" />,
      value: 'hematology',
      id: 8
    },
    {
      displayComp: <IntlMessages id="org.type.oncology" />,
      value: 'oncology',
      id: 9
    },
    {
      displayComp: <IntlMessages id="org.type.andrology" />,
      value: 'andrology',
      id: 10
    },
    {
      displayComp: <IntlMessages id="org.type.dermatologyVenereology" />,
      value: 'dermatologyVenereology',
      id: 11
    },
    {
      displayComp: <IntlMessages id="org.type.plasticSurgery" />,
      value: 'plasticSurgery',
      id: 12
    },
    {
      displayComp: <IntlMessages id="org.type.nephrology" />,
      value: 'nephrology',
      id: 13
    },
    {
      displayComp: <IntlMessages id="org.type.pulmonology" />,
      value: 'pulmonology',
      id: 14
    },
    {
      displayComp: <IntlMessages id="org.type.ophthalmology" />,
      value: 'ophthalmology',
      id: 15
    },
    {
      displayComp: <IntlMessages id="org.type.generalDoctor" />,
      value: 'generalDoctor',
      id: 16
    },
    {
      displayComp: <IntlMessages id="org.type.orthopedics" />,
      value: 'orthopedics',
      id: 17
    },
    {
      displayComp: <IntlMessages id="org.type.anesthesia" />,
      value: 'anesthesia',
      id: 18
    },
    {
      displayComp: <IntlMessages id="org.type.sugarEndocrinology" />,
      value: 'sugarEndocrinology',
      id: 19
    },
    {
      displayComp: <IntlMessages id="org.type.internalMedicine" />,
      value: 'internalMedicine',
      id: 20
    },
    {
      displayComp: <IntlMessages id="org.type.dentistry" />,
      value: 'dentistry',
      id: 21
    },
    {
      displayComp: <IntlMessages id="org.type.vascularMedicine" />,
      value: 'vascularMedicine',
      id: 22
    },
    {
      displayComp: <IntlMessages id="org.type.physiotherapyRehabilitation" />,
      value: 'physiotherapyRehabilitation',
      id: 23
    },
    {
      displayComp: <IntlMessages id="org.type.neonatology" />,
      value: 'neonatology',
      id: 24
    },
    {
      displayComp: <IntlMessages id="org.type.rheumatologyImmunology" />,
      value: 'rheumatologyImmunology',
      id: 25
    },
    {
      displayComp: <IntlMessages id="org.type.painMedicine" />,
      value: 'painMedicine',
      id: 26
    },
    {
      displayComp: <IntlMessages id="org.type.geriatrics" />,
      value: 'geriatrics ',
      id: 27
    },
    {
      displayComp: <IntlMessages id="org.type.psychiatry" />,
      value: 'psychiatry',
      id: 28
    },
    {
      displayComp: <IntlMessages id="org.type.radiology" />,
      value: 'radiology',
      id: 29
    },
    {
      displayComp: <IntlMessages id="org.type.concentration" />,
      value: 'concentration',
      id: 30
    },
    {
      displayComp: <IntlMessages id="org.type.anatomy" />,
      value: 'anatomy',
      id: 31
    },
    {
      displayComp: <IntlMessages id="org.type.urology" />,
      value: 'urology',
      id: 32
    }
  ];

  export const typesWithAll = allType.concat(types);

  export const nativeTypes = [
    'generalSurgery',
    'obstertricsGynecology',
    'cardiology',
    'pediatric',
    'earNoseThroat',
    'gastroenterologyHepatology',
    'neurology',
    'hematology',
    'oncology',
    'andrology',
    'dermatologyVenereology',
    'plasticSurgery',
    'nephrology',
    'pulmonology',
    'ophthalmology',
    'generalDoctor',
    'orthopedics',
    'anesthesia',
    'sugarEndocrinology',
    'internalMedicine',
    'dentistry',
    'vascularMedicine',
    'physiotherapyRehabilitation',
    'neonatology',
    'rheumatologyImmunology',
    'painMedicine',
    'geriatrics',
    'psychiatry',
    'radiology',
    'concentration',
    'anatomy',
    'urology'
  ]