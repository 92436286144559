import * as actions from "../action/actionTypes";

const initialState = {
  currentWallet: {},
  wallets: [],
  transactions: [],
  walletAlreadyExists: false,
  loading: false,
  usersInCurrentWallet: [],
  walletAddedToUser: false,
  usersCount: 0,
  usersInSearch: [],
  searchLoading: false,
  currentUserWalletId: "",
  adminAddsFund: 0,
  fundsList: [],
  usersReturned : 0,
  statusReturned:true,
  filterOptions: [
    {
      id: "WALLET_PAGES",
      title: "sidebar.wallet",
      items: [
        {
          id: "SUMMARY",
          title: "wallet.summary.default",
          isSelected: true,
        },
        {
          id: "BALANCE",
          title: "wallet.balance",
          isSelected: false,
        },
        {
          id: "USERS",
          title: "wallet.users",
          isSelected: false,
        },
      ],
    },
  ],
  adminTableFields: [
    {
      label: "table.wallet.depositValue",
    },
    {
      label: "table.wallet.date",
    },
    {
      label: "table.wallet.depositMethod",
    },
    {
      label: "table.wallet.chequeNumber",
    },
    {
      label: "table.wallet.referenceNumber",
    },
    {
      label: "table.wallet.depositBy",
    },
  ],
  orgTableFields: [
    {
      label: "table.wallet.depositValue",
    },
    {
      label: "table.wallet.date",
    },
    {
      label: "table.wallet.depositTo",
    },
  ],
  walletSummary: {
    items: [
      {
        id: "2",
        title: "wallet.summary.availableBalance",
        value: "0",
        icon: "PIE_CHART",
      },
      {
        id: "4",
        title: "wallet.users",
        value: "0",
        icon: "STOCKS",
      },
    ],
  },
};

export const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_WALLET:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_WALLET_RETURNED:
      return {
        ...state,
        walletAlreadyExists: true,
        loading: false,
        currentWallet: action.payload[0],
      };
    case actions.GET_WALLET_FAILED:
      return {
        ...state,
        walletAlreadyExists: false,
        currentWallet: null,
        loading: false,
      };
    case actions.GET_WALLET_TRANSACTIONS_RETURNED:
      return {
        ...state,
        fundsList: action.payload,
      };
    case actions.ADD_WALLET_TO_USER_RETURNED:
      return {
        ...state,
        walletAddedToUser: true,
        usersCount: state.usersCount + 1,
      };
    case actions.ADD_WALLET_TO_USER_FAILED:
      return {
        ...state,
        walletAddedToUser: false,
      };
    case actions.GET_USERS_RETURNED:
      return {
        ...state,
        usersInCurrentWallet: action.payload
      };
    case actions.SEARCH_USERS:
      return {
        ...state,
        searchLoading: true,
      };
    case actions.SEARCH_USERS_RETURNED:
      return {
        ...state,
        usersInSearch: action.payload,
        searchLoading: false,
      };
    case actions.CURRENT_USER_WALLET_ID:
      return {
        ...state,
        currentUserWalletId: action.payload,
      };
      case actions.ADMIN_ADD_FUND_RETURNED:
        if(action.payload){
          return {
            ...state,
            adminAddsFund: state.adminAddsFund + 1
          };
        }
        break;
        case actions.CHANGE_WALLET_STATUS_RETURNED:
          return {
            ...state,
            statusReturned: !state.statusReturned
          };
    case actions.NAV_OPTION_SELECTED:
      const id = action.payload;
      const newFilters = [...state.filterOptions];
      newFilters.forEach(filter => {
        filter.items.forEach(item => {
          item.isSelected = false;
        })
      });
      newFilters.forEach(filter => {
        filter.items.forEach(item => {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        })
      });
        return {
          ...state,
          filterOptions: newFilters
        };
    default:
      return state;
  }
};
