export class addressListItem {
	constructor({
		city = '',
		province = '',
		country = '',
		postalCode = '',
		address1,
		address2
	}) {
		this.country = country;
		this.city = city;
		this.province = province;
		this.postalCode = postalCode;
		this.address1 = address1;
		this.address2 = address2;
	}
}

export class basicInfoListItem {
	constructor({
		legalName = '',
		clinicType = '',
		orgType = '',
		speciality = '',
		phoneNumber = ''
	}) {
		this.legalName = legalName;
		this.clinicType = clinicType;
		this.orgType = orgType;
		this.speciality = speciality;
		this.phoneNumber = phoneNumber;
	}
}

export class organizationListItem {
	constructor({
		status = false,
		address = [{}],
		basic_info = '',
	}) {
		this.status = status;
		this.address = address;
		this.basic_info = basic_info;
	}
}