import Preconditions from '../../../../../core/utils/Preconditions';
import {
	SUMMARY_VITALS_RETURNED,
	SUMMARY_VITALS_REQUESTED
} from '../constants/ActionTypes';
import { SIGNOUT_USER_SUCCESS } from '../../../../../../constants/ActionTypes';

const initialState = {
	charts: [],
	userId: '',
	loading: false
}

export const mySummary = (state = initialState, action) => {
	Preconditions.shouldBeDefined(action, "action should be defined");
	switch (action.type) {
		case SUMMARY_VITALS_REQUESTED:
			return {
				...state,
				loading: true
			}

		case SUMMARY_VITALS_RETURNED:
			if (!action.payload.error) {
				return {
					...state,
					charts: action.payload.charts,
					loading: false
				}
			} else {
				return {
					...state,
					loading: false
				}
			}

		case SIGNOUT_USER_SUCCESS:
			return initialState;

		default:
			return state;
	}
}