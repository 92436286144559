import React from 'react';

const TermsConditionsEn = () => {
      return (
            <div>
                  <b style={{ fontSize: 16 }}>Welcome</b>
                  <br />
                  Welcome to CALLVITA&#39;s website and online services (&quot;CALLVITA Site&quot; or the &quot;Site&quot;), an online
                  information and communications service provided by CALLVITA in Norton, Massachusetts (&quot;CALLVITA&quot;
                  or &quot;We&quot; or &quot;Us&quot;). This policy is intended to cover the uses for all of the Site, although additional conditions,
                  restrictions and privacy policies may apply. BY USING THE SITE, YOU AGREE TO THESE TERMS OF
                  USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE. CALLVITA reserves the right, at its sole
                  discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your
                  responsibility to check these Terms of Use periodically for changes. Your continued use of the Site
                  following the posting of changes will mean that you accept and agree to the changes.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Special Product disclaimer (Health care disclaimer)</b>
                  <br />
                  The content, products and services offered herein are here to educate consumers on health care and medical
                  issues that may affect their daily lives. Nothing in the content, products or services should be considered, or
                  used as a substitute for, medical advice, diagnosis or treatment. This site and its services do not constitute
                  the practice of any medical, nursing or other professional health care advice, diagnosis or treatment. You
                  should always talk to your health care provider for diagnosis and treatment, including your specific medical
                  needs. None of the products or services offered through this website represents or warrants that any
                  particular service or product is safe, appropriate or effective for you. We advise users to always seek the
                  advice of a physician or other qualified health care provider with any questions regarding personal health or
                  medical conditions. If you have or suspect that you have a medical problem or condition, please contact a
                  qualified health care professional immediately.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Content</b>
                  <br />
                  All text, user interfaces, graphics, visual interfaces, logos, sounds, artwork and computer code (collectively,
                  &quot;Content&quot;), including but not limited to the design, structure, selection, coordination, expression, &quot;look and
                  feel&quot; and arrangement of such Content, contained on the Site is owned, controlled or licensed by or to
                  CALLVITA, and is protected by trade dress, and trademark laws, and various other intellectual property
                  rights and unfair competition laws.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Intended for users 18 and older</b>
                  <br />
                  The Site is intended for use by individuals 18 years of age or older. This website is not directed for use by
                  children under the age of 18. Users under the age of 18 should get the assistance of a parent or guardian to
                  use this site.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Accounts, Passwords and Security</b>
                  <br />
                  In order to use most aspects of our Services, you must register for and maintain an active account
                  (&quot;Account&quot;). You must be at least 18 years of age to obtain an Account. Account registration requires you to
                  submit to CALLVITA certain personal information, such as your name, address, mobile phone number and
                  age, as well as at least one valid payment method (either a credit card or accepted payment partner). You
                  agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to maintain
                  accurate, complete, and up-to-date Account information, including having an invalid or expired payment
                  method on file, may result in your inability to access or use the services. You are entirely responsible for
                  maintaining the confidentiality of the information you hold for your account, including your password, and
                  for any and all activity that occurs under your account as a result of your failing to keep this information
                  secure and confidential. You agree to notify CALLVITA immediately of any unauthorized use of your
                  account or password, or any other breach of security.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Privacy</b>
                  <br />

                  <br />
                  <b style={{ fontSize: 14 }}>Introduction</b>
                  <br />
                  We take your privacy seriously, and we want you to know how we collect, use, share and protect your information. In addition to this privacy policy, users of the Callvita site should consult site terms of use as well as any product specific terms and conditions that apply.

<br />
                  <br />

                  <b style={{ fontSize: 14 }}>Services</b>
                  <br />
                  Callvita's ultimate goal is to maximise the use of technology to serve humanity. Through its' multiple platforms, Callvita is striving to connect people in need with those how can deliver in all sphere of life.
<br />
                  <br />

                  <b style={{ fontSize: 14 }}>Change</b>
                  <br />
                  Callvita (“we” or “us”) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.
                  You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your data is subject to the updated Privacy Policy.
<br />
                  <br />

                  <b style={{ fontSize: 14 }}>Data We Collect</b>
                  <br />
                  1. Data You Provide To Us
<br />
                  <b style={{ fontSize: 12 }}>Registration</b>
                  <br />
                  To create an account you need to provide data including your name, email address and/or mobile number, password, payment (e.g., credit card) and billing information.
<br />
                  <b style={{ fontSize: 12 }}>Related Companies and Other Services</b>
                  <br />
                  We receive data about you when you use some of the other services provided by us or our affiliates.
<br />

                  <b style={{ fontSize: 12 }}>Others</b>
                  <br />
                  Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we use your data, we will notify you and may also modify this Privacy Policy.
<br />
                  <br />


                  <b style={{ fontSize: 14 }}>How We Use Your Data</b>
                  <br />
                  How we use your data will depend on which Services you use, how you use those Services and the choices you make in your settings. We use the data that we have about you to provide and personalize, including with the help of automated systems and inferences we make, our Services (including ads) so that they can be more relevant and useful to you and others.
<br />
                  <b style={{ fontSize: 12 }}>Services:</b>
                  <br />
                  We use your data to authorize access to our services;
<br />
                  <b style={{ fontSize: 12 }}>Stay connected</b>
                  <br />

                  Our Services allow you to stay in touch and up to date with service providers and other professionals. To do so, you will “connect” with the professionals who you choose, and who also wish to “connect” with you.
                  <br />

                  <b style={{ fontSize: 12 }}>Marketing</b>
                  <br />

                  We use data and content about Members for invitations and communications promoting membership and network growth, engagement and our Services.
                  <br />
                  <br />

                  <b style={{ fontSize: 14 }}>Developing Services and Research</b>
                  <br />

                  <b style={{ fontSize: 12 }}>Service Development</b>
                  <br />

                  We use data, to conduct research and development for the further development of our Services in order to provide you and others with a better, more intuitive and personalized experience, drive membership growth and engagement on our Services, and help connect professionals to each other’s.
<br />

                  <b style={{ fontSize: 12 }}>Surveys</b>
                  <br />

                  Polls and surveys are conducted by us and others through our Services. You are not obligated to respond to polls or surveys, and you have choices about the information you provide. You may opt-out of survey invitations.
<br />

                  <b style={{ fontSize: 12 }}>Customer Support</b>
                  <br />

                  We use the data (which can include your communications) to investigate, respond to and resolve complaints and Service issues.
                  <br />

                  <b style={{ fontSize: 12 }}>Security and Investigations</b>
                  <br />
                  We use your data (including your communications) if we think it’s necessary for security purposes or to investigate possible fraud or other violations of our User Agreement or this Privacy Policy and/or attempts to harm our Members.
                  <br />
                  <br />

                  <b style={{ fontSize: 14 }}>Legal Disclosures</b>
                  <br />

                  We may need to share your data when we believe it’s required by law or to help protect the rights and safety of you, us or others.
                  It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you, (3) investigate and defend ourselves against any third-party claims or allegations, (4) protect the security or integrity of our Service (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of Callvita, our Members, personnel, or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand.
                  <br />
                  <br />

                  <b style={{ fontSize: 14 }}>Change in Control or Sale</b>
                  <br />
                  <br />

                  We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.
                  <br />
                  <br />

                  <b style={{ fontSize: 14 }}>Data Retention</b>
                  <br />

                  We retain your personal data while your account is in existence or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. Even if you only use our Services when looking for a new provider few years, we will retain your information and keep your profile open until you decide to close your account. In some cases we choose to retain certain information in a depersonalized or aggregated form.
                  <br />
                  <br />

                  <b style={{ fontSize: 14 }}>Rights to Access and Control Your Personal Data</b>
                  <br />

                  You can access or delete your personal data. You have many choices about how your data is collected, used and shared.
                  We provide many choices about the collection, use and sharing of your data, from deleting or correcting data you include in your profile and controlling the visibility of your account opt-outs and communication controls. We offer you settings to control and manage the personal data we have about you.
                  <br />
                  <br />

                  <b style={{ fontSize: 14 }}>Account Closure</b>
                  <br />
                  If you choose to close your account, your personal data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.
                  We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our User Agreement, or fulfill your request to “unsubscribe” from further messages from us. We will retain de-personalized information after your account has been closed.
                  Information you have shared with others (e.g., through InMail, updates or group posts) will remain visible after you closed your account or deleted the information from your own profile or mailbox, and we do not control data that other Members copied out of our Services.
                  <br />
                  <br />

                  <b style={{ fontSize: 14 }}>Other Important Information</b>
                  <br />
                  <b style={{ fontSize: 12 }}>5.1. Security</b>
                  <br />

                  We monitor for and try to prevent security breaches. Please use the security features available through our Services.
                  We implement security safeguards designed to protect your data. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. Please visit our Safety Centre for additional information about safely using our Services, including two-factor authentication.
                  <br />
                  <b style={{ fontSize: 12 }}>5.2. Cross-Border Data Transfers</b>
                  <br />

                  We store and use your data outside your country.
                  We process data both inside and outside of the United States and rely on legally-provided mechanisms to lawfully transfer data across borders. Countries where we process data may have laws which are different, and potentially not as protective, as the laws of your own country.
                  <b style={{ fontSize: 12 }}>5.3 Lawful Bases for Processing</b>
                  <br />

                  We have lawful bases to collect, use and share data about you. You have choices about our use of your data.
                  At any time, you can withdraw consent you have provided by going to settings.
                  We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you (e.g. to deliver the LinkedIn Services you have requested)) and “legitimate interests”. Learn more.
                  Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object. If you have any questions about the lawful bases upon which we collect and use your personal data, please contact our Data Protection Officer.
                  <br />

                  <b style={{ fontSize: 12 }}>5.4. Direct Marketing and Do Not Track Signals</b>
                  <br />

                  Our statements regarding direct marketing and “do not track” signals.
                  We currently do not share personal data with third parties for their direct marketing purposes without your permission.
                  <br />

                  <b style={{ fontSize: 12 }}>5.5. Contact Information</b>
                  <br />

                  You can contact us or use other options to resolve any complaints.
                  If you have questions or complaints regarding this Policy, please first contact Callvita online. You can also reach us by physical mail. If contacting us does not resolve your complaint, you have more options.

                  Please contact us at info@callvita.com
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Disclaimers</b>
                  <br />
                  CALLVITA DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE
                  OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE
                  CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE
                  AND ITS CONTENT ARE DELIVERED ON AN &quot;AS-IS&quot; AND &quot;AS-AVAILABLE&quot; BASIS. ALL
                  INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE.
                  CALLVITA CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM
                  THE SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES.
                  CALLVITA DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY
                  WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR
                  A PARTICULAR PURPOSE. CALLVITA DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS,
                  OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO
                  YOUR USE OF THE SITE AND/OR ANY CALLVITA SERVICES. YOU ASSUME TOTAL
                  RESPONSIBILITY FOR YOUR USE OF THE SITE. YOUR SOLE REMEDY AGAINST CALLVITA
                  FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO STOP USING THE SITE OR
                  ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN
                  THE PARTIES.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Limitation of liability</b>
                  <br />
                  THE USE OF THE SITE IS ENTIRELY AT YOUR OWN RISK, AND IN NO EVENT SHALL
                  CALLVITA BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                  SPECIAL, EXEMPLARY, PUNITIVE, OR ANY OTHER MONETARY OR OTHER DAMAGES, FEES,
                  FINES, PENALTIES, OR LIABILITIES ARISING OUT OF OR RELATING IN ANY WAY TO THIS
                  SERVICE, OR SITES ACCESSED THROUGH THIS SERVICE, AND/OR CONTENT OR
                  INFORMATION PROVIDED HEREIN. YOUR SOLE AND EXCLUSIVE REMEDY FOR
                  DISSATISFACTION WITH THE SERVICE IS TO STOP USING THE SERVICE. USER HEREBY
                  ACKNOWLEDGES THAT THIS PARAGRAPH SHALL APPLY TO ALL CONTENT, MERCHANDISE
                  AND SERVICES AVAILABLE THROUGH THE SITE.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Indemnity</b>
                  <br />
                  You agree to indemnify and hold Callvita, its officers, directors, shareholders, predecessors, successors in
                  interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or
                  expenses (including attorneys’ fees), made against Callvita by any third party due to or arising out of or in
                  connection with your use of the Site.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Intellectual property</b>
                  <br />
                  The marks &quot;Callvita,&quot; and all it&#39;s products are trademarks and/or service marks of Callvita. The content and
                  design of Callvita Site is protected by U.S. and international copyright laws. You may not copy, reproduce,
                  republish, upload, post, display, transmit or frame any of these materials without prior written consent from
                  the copyright owners.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Termination of use</b>
                  <br />
                  Callvita may, in its sole discretion, terminate your account or your use of the Site at any time. Callvita
                  reserves the right to change, suspend or discontinue all or any aspects of the Site at any time without prior
                  notice.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Governing Law; Arbitration</b>
                  <br />
                  You agree that all matters relating to your access to or use of the Site, including all disputes, will be
                  governed by the laws of the United States and by the laws of the State of Massachusetts without regard to its
                  conflicts of laws provisions. In the event of any controversy or dispute between Callvita and you arising out
                  of or in connection with your use of the Site, the parties shall attempt, promptly and in good faith, to resolve
                  any such dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty
                  (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be
                  resolved through mediation, then the parties shall be free to pursue any right or remedy available to them
                  under applicable law.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Violation of These Terms of Use</b>
                  <br />
                  Callvita may disclose any information we have about you (including your identity) if we determine that such
                  disclosure is necessary in connection with any investigation or complaint regarding your use of the Site, or
                  to identify, contact or bring legal action against someone who may be causing injury to or interference with
                  (either intentionally or unintentionally) Callvita’s rights or property, or the rights or property of visitors to or
                  users of the Site, including Callvita’s customers. Callvita reserves the right at all times to disclose any
                  information that Callvita deems necessary to comply with any applicable law, regulation, legal process or
                  governmental request. Callvita also may disclose your information when Callvita determines that applicable
                  law requires or permits such disclosure, including exchanging information with other companies and
                  organizations for fraud protection purposes. You acknowledge and agree that Callvita may preserve any
                  transmittal or communication by you with Callvita through the Site or any service offered on or through the
                  Site, and may also disclose such data if required to do so by law or Callvita determines that such
                  preservation or disclosure is reasonably necessary to (1) comply with legal process, (2) enforce these Terms
                  of Use, (3) respond to claims that any such data violates the rights of others, or (4) protect the rights,
                  property or personal safety of Callvita, its employees, users of or visitors to the Site, and the public. You
                  agree that Callvita may, in its sole discretion and without prior notice, terminate your access to the Site
                  and/or block your future access to the Site if we determine that you have violated these Terms of Use or
                  other agreements or guidelines which may be associated with your use of the Site. You also agree that any
                  violation by you of these Terms of Use will constitute an unlawful and unfair business practice, and will
                  cause irreparable harm to Callvita, for which monetary damages would be inadequate, and you consent to
                  Callvita obtaining any injunctive or equitable relief that Callvita deems necessary or appropriate in such
                  circumstances. These remedies are in addition to any other remedies Callvita may have at law or in equity.
                  You agree that Callvita may, in its sole discretion and without prior notice, terminate your access to the Site,
                  for cause, which includes (but is not limited to) (1) requests by law enforcement or other government
                  agencies, (2) a request by you (self-initiated account deletions), (3) discontinuance or material modification
                  of the Site or any service offered on or through the Site, or (4) unexpected technical issues or problems. If
                  Callvita does take any legal action against you as a result of your violation of these Terms of Use, Callvita
                  will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such
                  action, in addition to any other relief granted to Callvita. You agree that Callvita will not be liable to you or
                  to any third party for termination of your access to the Site as a result of any violation of these Terms of
                  Use.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Miscellaneous</b>
                  <br />
                  You may not use or export or re-export any Content or any copy or adaptation of such Content, or any
                  product or service offered on the Site, in violation of any applicable laws or regulations, including without
                  limitation United States export laws and regulations. If any of the provisions of these Terms of Use are held
                  by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be
                  limited or eliminated to the minimum extent necessary and replaced with a valid provision that best
                  embodies the intent of these Terms of Use, so that these Terms of Use shall remain in full force and effect.
                  These Terms of Use constitute the entire agreement between you and Callvita with regard to your use of the
                  Site, and any and all other written or oral agreements or understandings previously existing between you and
                  Callvita with respect to such use are hereby superseded and cancelled.
<br />
                  <br />
                  <b style={{ fontSize: 16 }}>Feedback</b>
                  <br />
                  If you have any feedback / questions about our Services, please contact us at info@callvita.com
					<br />
                  <br />
            </div>
      )
}
export default TermsConditionsEn;