export const GET_RESERVATIONS = "GET_RESERVATIONS";
export const GET_RESERVATIONS_RETURNED = "GET_RESERVATIONS_RETURNED";
export const SAVE_TIMING = "SAVE_TIMING";
export const TIME_SAVED = "TIME_SAVED";
export const UPDATE_PROVIDER_TD = "UPDATE_PROVIDER_TD";
export const UPDATE_PROVIDER_TD_RETURNED = "UPDATE_PROVIDER_TD_RETURNED";
export const DELETE_PROVIDER_TD = "DELETE_PROVIDER_TD";
export const DELETE_PROVIDER_TD_RETURNED = "DELETE_PROVIDER_TD_RETURNED";
export const GET_PROVIDER_TDS_REQUEST = "GET_PROVIDER_TDS_REQUEST";
export const GET_PROVIDER_TDS_RETURNED = "GET_PROVIDER_TDS_RETURNED";
export const SAVE_TIME_SLOT = "SAVE_TIME_SLOT";
export const SAVE_TIME_SLOT_DONE = "SAVE_TIME_SLOT_DONE";
export const GET_TIME_SLOT = 'GET_TIME_SLOT';
export const GET_TIME_SLOT_RETURNED = "GET_TIME_SLOT_RETURNED";
export const RESET_PROVIDERS_CALENDAR = "RESET_PROVIDERS_CALENDAR";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const CHANGE_RESERVATION_STATUS = "CHANGE_RESERVATION_STATUS";
export const CHANGE_RESERVATION_STATUS_RETURNED = "CHANGE_RESERVATION_STATUS_RETURNED";
export const RESET_ERROR = "RESET_ERROR";
export const MY_RESERVATIONS = "MY_RESERVATIONS";
export const MYRESERVATIONS_RETURNED = "MYRESERVATIONS_RETURNED";
export const SEARCH_DATE = "SEARCH_DATE";
export const SEARCH_TYPE = "SEARCH_TYPE";
export const SEARCH_NAME = "SEARCH_NAME";
export const SET_SEARCH = "SET_SEARCH";
export const SEARCH = "SEARCH";
export const SEARCH_RETURNED = "SEARCH_RETURNED";
export const SET_FILTER = "SET_FILTER";
export const RESET_ORG_RES_FILTERS = "RESET_ORG_RES_FILTERS";
export const GET_SPECIFIC_DR = "GET_SPECIFIC_DR";

// Find a doctor Actions 
export const GET_ALL_DOCTORS = "GET_ALL_DOCTORS";
export const GET_ALL_DOCTORS_RETURNED = "GET_ALL_DOCTORS_RETURNED";
export const RESET_ALL_DOCTORS = "RESET_ALL_DOCTORS";
export const GET_SLOTS = "GET_SLOTS";
export const GET_SLOTS_RETURNED = "GET_SLOTS_RETURNED";
export const RESET_DR_SLOTS = "RESET_DR_SLOTS";
export const COMPLETE_RESERVATION = "COMPLETE_RESERVATION";
export const RESERVE_RETURN = "RESERVE_RETURN";
export const QUEUE_WARNING = "QUEUE_WARNING";
export const RESET_FILTERS = "RESET_FILTERS";
export const RESET_SEARCH = "RESET_SEARCH";
export const RESET_STATUS = "RESET_STATUS";
export const RESET_REDIRECT = "RESET_REDIRECT";
export const UPDATE_RES_FILTERS = "UPDATE_RES_FILTERS";
export const SORT = "SORT";

export const FIND_A_DOCTOR_RESET = "FIND_A_DOCTOR_RESET";