import moment from 'moment';

export class CommentListItem {
	constructor({
		id = '',
		createdByName = '',
		createdAt = moment.utc().format(),
		text = '',
		model
	}) {
		this.id = id;
		this.createdByName = createdByName;
		this.createdAt = createdAt;
		this.text = text;
		this.model = model;
	}
}