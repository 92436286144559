import * as EmailValidator from 'email-validator';

import { FormControl, FormHelperText } from '@material-ui/core';
import {
  deepLinking,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userSignIn
} from 'actions/Auth';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom'
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import queryString from 'query-string'

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      emailError: ''
    }
  }

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }
  componentDidMount() {
    if (this.props.location.state) {
      if (this.props.location.state.from) {
        const values = queryString.parse(this.props.location.state.from.search);
        if (values.id) {
          this.props.deepLinking(values.id);
        }
      }
    }
  }

  _handleKeyPress = (e) => {
    const {
      email,
      password
    } = this.state;
    if (e.key === 'Enter') {
      if (email && password) {
        this.props.showAuthLoader();
        this.props.userSignIn({ email, password });
      }
    }
  }

  render() {
    const {
      email,
      password
    } = this.state;
    const { loader } = this.props;
    const enabled = email && password;
    return (
      <div
       className='blue-bg'
      >
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div
              className="app-logo-content d-flex align-items-center justify-content-center"
              style={{ backgroundColor: "#fff" }}
            >
              <Link className="logo-lg" to="/" title="CALLVITA">
                <img
                  src={require("assets/images/srvy-blue.png")}
                  alt="Srvy"
                  title="Srvy"
                  style={{ width: "75%" }}
                />
              </Link>
            </div>

            <div className="app-login-content">
              <div className="app-login-header mb-4">
                <h1>
                  <IntlMessages id="appModule.signIn" />
                </h1>
              </div>

              <div>
                <FormControl fullWidth>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) => {
                      if (EmailValidator.validate(event.target.value)) {
                        this.setState({ emailError: "" });
                      } else {
                        this.setState({
                          emailError: (
                            <IntlMessages id="validations.emailError" />
                          ),
                        });
                      }
                      this.setState({ email: event.target.value });
                    }}
                    value={email}
                    onKeyPress={(event) => this._handleKeyPress(event)}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    required
                  />
                  {this.state.emailError && (
                    <FormHelperText id="component-error-text" error>
                      {this.state.emailError}
                    </FormHelperText>
                  )}
                </FormControl>
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                  onKeyPress={(event) => this._handleKeyPress(event)}
                  value={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  required
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    disabled={!enabled}
                    onClick={() => {
                      this.props.showAuthLoader();
                      this.props.userSignIn({ email, password });
                    }}
                    color="primary"
                    variant="contained"
                    // style={{backgroundColor: '#0a77bf'}}
                  >
                    <IntlMessages id="appModule.signIn" />
                  </Button>

                  {/* <Link to="/signup">
                    <IntlMessages id="signIn.userSignUp" />
                  </Link> */}
                </div>

                <Link to="/forget_password">
                  <IntlMessages id="appModule.forgotPassword" />
                </Link>
                <Link
                  style={{
                    position: "absolute",
                    right: "0",
                    bottom: "20px",
                    color: "#C8C8C8",
                    fontSize: 12,
                  }}
                  to="/privacy-policy"
                >
                  <IntlMessages id="privacyPolicy" />
                </Link>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, authUser } = auth;
  return { loader, authUser }
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  deepLinking
})(SignIn);
