const developConfigurations = {
    identityPoolId: "eu-west-2:65f04263-62bd-4f7b-8c0a-f72eca24ce4a",
    region: "eu-west-2",
    identityPoolRegion: "eu-west-2",
    userPoolId: "eu-west-2_51cX4h8Wc",
    userPoolWebClientId: "2b7gtdbe7m08ntb1pf3gri5cu0"
}

const productionConfigurations = {
    identityPoolId: 'eu-west-2:b3bdc6c2-9f89-429c-aafd-d85ccc4e26cc',
    region: 'eu-west-2',
    identityPoolRegion: 'eu-west-2',
    userPoolId: 'eu-west-2_GA5WkLcn4',
    userPoolWebClientId: 'c9q1uip8shk0ht4ldjim0nj2q'
}

export const amplifyConfigurations = process.env.REACT_APP_STAGE === 'develop' ? developConfigurations : productionConfigurations;