import {
  UPDATE_ADMIN_INFO,
  UPDATE_ORG_BASIC_INFO,
  GET_ORGS_REQUEST,
  GET_ORGS_RETURNED,
  DELETE_ORG_REQUEST_DONE,
  UPDATE_ORGS_FILTERS,
  UPDATE_CONFIRM_PASSWORD,
  GET_ORG_BY_ID_RETURNED,
  ORG_CREATED,
  CREATE_ORG_REQUEST,
  ENABLE_ADMIN_CREATE,
  GET_ORG_BY_ID,
  RESET_EDIT_ORG_MODE,
  EDIT_ORG_REQUEST_DONE,
  EDIT_ORG_REQUEST,
  ORGS_SEARCH_RETURNED,
  CHECK_TERMS_CONDITIONS,
  UPDATE_ADMIN_ERRORS,
  UPDATE_BASIC_ERRORS,
  ENABLE_ORG_ADMIN_EDIT,
  GET_SPECIALTIES_SUCCESS,
  GET_ORGANIZATION_TYPES_SUCCESS,
  GET_ALL_USERS_IN_ORG_RETURNED,
} from "../constants/actionTypes";
import { SIGNOUT_USER_SUCCESS } from "../../../../../constants/ActionTypes";
import moment from "moment";
import { orgFiltersItems } from "./org-filters";
import { account_roles as ar } from "../model/organization";

const initialState = {
  orgs: [],
  draftOrg: {
    basicInfo: {
      legalName: "",
      status: false,
      clinicType: "",
      address1: "",
      address2: "",
      country: "",
      city: "",
      province: "",
      postalCode: "",
      orgType: "",
      speciality: "",
    },
    adminInfo: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      orgId: "",
      id: "",
      confirmPassword: "",
      account_roles: new ar({ orgAdmin: true }),
    },
    createdBy: "",
    createdAt: "",
    id: "",
  },
  orgsFilters: [
    {
      id: "ORG_STATUS",
      title: "org.status.default",
      items: [
        {
          id: "ACTIVE",
          title: "org.status.active",
          isSelected: false,
          value: true,
        },
        {
          id: "IN_ACTIVE",
          title: "org.status.inactive",
          isSelected: false,
          value: false,
        },
      ],
    },
    {
      id: "ORGS_TYPES",
      title: "default.type",
      items: orgFiltersItems,
    },
  ],
  timeFilters: {
    startDate: moment()
      .subtract(1, "months")
      .format(),
    endDate: moment().format(),
  },
  itemOptions: [
    {
      label: "default.edit",
      id: "EDIT",
    },
    {
      label: "default.delete",
      id: "DELETE",
    },
    {
      label: "sidebar.wallet",
      id: "WALLET",
    },
  ],
  allUsersInOrg: [],
  edit: false,
  adminCreate: false,
  orgAdminEdit: false,
  loader: false,
  confirmPasswordError: false,
  showMessage: false,
  termsChecked: false,
  allAdminErrors: false,
  allBasicErrors: false,
  showItemDetails: false,
  organizationTypes: [],
  organizationSpecialties: [],
};

export const organization = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORG_BASIC_INFO:
      const newDraftOrgInfo = { ...state.draftOrg };
      newDraftOrgInfo.basicInfo = action.payload;
      return {
        ...state,
        draftOrg: newDraftOrgInfo,
      };

    case UPDATE_ADMIN_INFO:
      const newDraftOrgAdmin = { ...state.draftOrg };
      newDraftOrgAdmin.adminInfo = action.payload;
      return {
        ...state,
        draftOrg: newDraftOrgAdmin,
      };

    case CREATE_ORG_REQUEST:
      return {
        ...state,
        loader: true,
      };

    case ORG_CREATED:
      if (!action.payload.error) {
        return {
          ...state,
          loader: false,
          draftOrg: initialState.draftOrg,
        };
      } else {
        return {
          ...state,
          loader: false,
        };
      }

    case UPDATE_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPasswordError: action.payload,
      };

    case GET_ORGS_REQUEST:
      return {
        ...state,
        loader: true,
      };

    case GET_ORGS_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          loader: false,
          orgs: action.payload.orgs,
        };
      } else {
        return {
          ...state,
          loader: false,
        };
      }

    case ORGS_SEARCH_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          orgs: action.payload.orgs,
        };
      } else {
        return {
          ...state,
        };
      }

    case UPDATE_ORGS_FILTERS:
      const id = action.payload;
      const newFilters = [...state.orgsFilters];
      newFilters.forEach((filter) => {
        filter.items.forEach((item) => {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        });
      });
      return {
        ...state,
        orgsFilters: newFilters,
      };

    case GET_ORG_BY_ID:
      return {
        ...state,
        loader: true,
        showItemDetails: true,
      };

    case GET_ORG_BY_ID_RETURNED:
      if (!action.payload.error) {
        return {
          ...state,
          edit: true,
          draftOrg: action.payload.org
            ? action.payload.org
            : initialState.draftOrg,
          loader: false,
        };
      } else {
        return {
          ...state,
          loader: false,
        };
      }

    case RESET_EDIT_ORG_MODE:
      return {
        ...state,
        edit: false,
        draftOrg: initialState.draftOrg,
      };

    case ENABLE_ADMIN_CREATE:
      return {
        ...state,
        adminCreate: action.payload,
        showItemDetails: action.payload ? true : false,
      };

    case EDIT_ORG_REQUEST:
      return {
        ...state,
        loader: true,
      };

    case EDIT_ORG_REQUEST_DONE:
      if (!action.payload.error) {
        return {
          ...state,
          loader: false,
          showItemDetails: false,
        };
      } else {
        return {
          ...state,
          loader: false,
        };
      }

    case DELETE_ORG_REQUEST_DONE:
      if (!action.payload.error) {
        const deleteId = action.payload.id;
        const newOrgs = state.orgs.filter((org) => {
          return org.id !== deleteId;
        });
        return {
          ...state,
          orgs: newOrgs,
        };
      } else {
        return {
          ...state,
        };
      }

    case CHECK_TERMS_CONDITIONS:
      return {
        ...state,
        termsChecked: action.payload,
      };

    case UPDATE_ADMIN_ERRORS:
      return {
        ...state,
        allAdminErrors: action.payload,
      };

    case UPDATE_BASIC_ERRORS:
      return {
        ...state,
        allBasicErrors: action.payload,
      };

    case ENABLE_ORG_ADMIN_EDIT:
      return {
        ...state,
        orgAdminEdit: action.payload,
      };

    case SIGNOUT_USER_SUCCESS:
      return initialState;

    case GET_SPECIALTIES_SUCCESS:
      return { ...state, organizationSpecialties: action.payload };

    case GET_ORGANIZATION_TYPES_SUCCESS:
      return { ...state, organizationTypes: action.payload };
    case GET_ALL_USERS_IN_ORG_RETURNED:
      return {
        ...state,
        allUsersInOrg: action.payload,
      };

    default:
      return { ...state };
  }
};
