import { all, takeEvery, put } from 'redux-saga/effects';
import { showErrorMessage } from '../../../../../actions/Notifications';
import { orgsApiClient } from "../../../../core/utils/restClient";
import { GET_DAILY_RESERVATIONS } from '../constants/actionTypes';
import { getDailyReservationsSuccess, getDailyReservationsFailed } from '../actions/adminActions';

const errorMessage = "Something went wrong, please try again later.";

export function* getDailyReservations(action) {
  const pathTemplate = '/api/get-all-reservations';
  const myInit = {
    queryStringParameters: {
      ...action.payload,
    }
  }
  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      return yield put(getDailyReservationsSuccess(res.payload));
    }
    yield put(getDailyReservationsFailed());
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (e) {
    yield put(getDailyReservationsFailed());
    yield put(showErrorMessage(errorMessage));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_DAILY_RESERVATIONS, getDailyReservations),
  ]);
}