export const UPDATE_ADMIN_INFO = "UPDATE_ADMIN_INFO";
export const UPDATE_ORG_BASIC_INFO = "UPDATE_ORG_BASIC_INFO";
export const CREATE_ORG_REQUEST = "CREATE_ORG_REQUEST";
export const UPDATE_ORGS_FILTERS = "UPDATE_ORGS_FILTERS";
export const ORG_CREATED = "ORG_CREATED";
export const GET_ORGS_REQUEST = "GET_ORGS_REQUEST";
export const GET_ORGS_RETURNED = "GET_ORGS_RETURNED";
export const GET_ORG_BY_ID = "GET_ORG_BY_ID";
export const RESET_EDIT_ORG_MODE = "RESET_EDIT_ORG_MODE";
export const GET_ORG_BY_ID_RETURNED = "GET_ORG_BY_ID_RETURNED";
export const DELETE_ORG_REQUEST = "DELETE_ORG_REQUEST";
export const DELETE_ORG_REQUEST_DONE = "DELETE_ORG_REQUEST_DONE";
export const EDIT_ORG_REQUEST = "EDIT_ORG_REQUEST";
export const EDIT_ORG_REQUEST_DONE = "EDIT_ORG_REQUEST_DONE";
export const UPDATE_CONFIRM_PASSWORD = "UPDATE_CONFIRM_PASSWORD";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const ENABLE_ADMIN_CREATE = "ENABLE_ADMIN_CREATE";
export const ORGS_SEARCH_REQUEST = "ORGS_SEARCH_REQUEST";
export const ORGS_SEARCH_RETURNED = "ORGS_SEARCH_RETURNED";
export const CHECK_TERMS_CONDITIONS = "CHECK_TERMS_CONDITIONS";
export const RESET_ERROR = "RESET_ERROR";
export const UPDATE_ADMIN_ERRORS = "UPDATE_ADMIN_ERRORS";
export const UPDATE_BASIC_ERRORS = "UPDATE_BASIC_ERRORS";
export const ENABLE_ORG_ADMIN_EDIT = "ENABLE_ORG_ADMIN_EDIT";
export const SEARCH_RETURNED = "SEARCH_RETURNED";
export const SHOW_EDIT_MESSAGE = "SHOW_EDIT_MESSAGE";

export const GET_ORGANIZATION_TYPES = "GET_ORGANIZATION_TYPES";
export const GET_SPECIALTIES_SUCCESS = "GET_SPECIALTIES_SUCCESS";

export const GET_SPECIALTIES = "GET_SPECIALTIES";
export const GET_ORGANIZATION_TYPES_SUCCESS = "GET_ORGANIZATION_TYPES_SUCCESS";
export const GET_ALL_USERS_IN_ORG = "GET_ALL_USERS_IN_ORG";
export const GET_ALL_USERS_IN_ORG_RETURNED = "GET_ALL_USERS_IN_ORG_RETURNED";
