import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import { getUser } from '../../app/modules/user/state/action/usersActions';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { user, history } = this.props;
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt='...'
          src={'https://via.placeholder.com/150x150'}
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>{user && user ? (user.basicInfo ? user.basicInfo.firstName + ' ' + user.basicInfo.lastName : 'UserName') : 'UserName'}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
          {(user.permissions.includes("IS_A_DOCTOR") || user.permissions.includes("IS_AN_OPERATOR") || user.permissions.includes("IS_AN_ORG_ADMIN")) &&
            <div>
              <h6 className="user-name"> {user.orgName}</h6>
            </div>
          }
        </div>
        <Menu className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.getUser(user.id);
            setTimeout(() => {
              history.push('/app/clinic/user-profile')
            }, 100);
          }}
          >
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem>

          { user.permissions.includes("VIEW_CLINIC_PROFILE") &&
            <MenuItem onClick={() => {
              this.handleRequestClose();
              setTimeout(() => {
                history.push('/app/clinic/clinic-profile')
              }, 500);
            }}
            >
              <i className="zmdi zmdi-accounts zmdi-hc-fw mr-2" />
              <IntlMessages id="popup.clinicProfile" />
            </MenuItem>
          }

          <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.userSignOut()
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

UserInfo.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  return {
    locale: locale,
    user: auth.loggedInUser,
    userId: auth.authUser
  }
};

export default connect(mapStateToProps, { userSignOut, getUser })(withRouter(UserInfo));


