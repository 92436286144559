import { all, put, takeEvery } from "redux-saga/effects";
import {
  GET_COUNTRIES_RETURNED,
  GET_COUNTRIES_REQUEST,
  GET_STATES_RETURNED,
  GET_STATES_REQUESTED,
  GET_CITIES_REQUESTED,
  GET_CITIES_RETURNED,
} from "../constants/ActionTypes";
import { showErrorMessage } from "../actions/Notifications";
import { orgsApiClient } from "../app/core/utils/restClient";
const errorMessage = "Something went wrong. please try again later.";

export function* getCountries(action) {
  let countries = [];
  const myInit = {
    queryStringParameters: {
      data: action.payload.ids ? JSON.stringify(action.payload) : "",
    },
  };
  const pathTemplate = "/api/countries";
  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      countries = res.payload;
      return yield put({
        type: GET_COUNTRIES_RETURNED,
        payload: { countries },
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(error.message | errorMessage));
  }
}

export function* getStates(action) {
  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({
        id:
          typeof action.payload.id === "string"
            ? action.payload.id.substring(
                action.payload.res ? 4 : 2,
                action.payload.id.length
              )
            : action.payload.id,
      }),
    },
  };
  var pathTemplate = "/api/states";
  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      const states = res.payload;
      return yield put({
        type: GET_STATES_RETURNED,
        payload: { states },
      });
    }
    yield put(showErrorMessage(res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(errorMessage));
  }
}

export function* getCities(action) {
  let message = "";
  let stateId =
    typeof action.payload.id === "string"
      ? action.payload.id.substring(
          action.payload.res ? 4 : 2,
          action.payload.id.length
        )
      : action.payload.id;

  if (action.payload.us) {
    let states = action.payload.states;
    let statesFiltered = states.filter((state) => state.id === +stateId);
    return yield put({
      type: GET_CITIES_RETURNED,
      payload: { cities: statesFiltered[0]["cities"] },
    });
  }

  const myInit = {
    queryStringParameters: {
      data: JSON.stringify({ id: stateId }),
    },
  };
  var pathTemplate = "/api/cities";
  try {
    const res = yield orgsApiClient.get(pathTemplate, myInit);
    if (res.success) {
      const cities = res.payload;
      return yield put({
        type: GET_CITIES_RETURNED,
        payload: { cities },
      });
    }
    yield put(showErrorMessage( res.message || errorMessage));
  } catch (error) {
    yield put(showErrorMessage(message));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_COUNTRIES_REQUEST, getCountries),
    yield takeEvery(GET_STATES_REQUESTED, getStates),
    yield takeEvery(GET_CITIES_REQUESTED, getCities),
  ]);
}
