import Auth from "./amplify-setup";

import { endpoints } from "./urls";

const {
  resUrl,
  usersBaseUrl,
  eventsUrl,
  orgUrl,
  paymentUrl,
  coreUrl,
} = endpoints;

var apigClientFactory = require("aws-api-gateway-client").default;

export function* getTokens() {
  let accessKey,
    secretkey,
    sessionToken = null;
  let error = false;
  yield Auth.currentCredentials()
    .then((response) => {
      accessKey = response.data.Credentials.AccessKeyId;
      secretkey = response.data.Credentials.SecretKey;
      sessionToken = response.data.Credentials.SessionToken;
    })
    .catch((_) => (error = true));

  return [accessKey, secretkey, sessionToken, error];
}

export const getEventsApiGWClient = (accessKey, secretKey, sessionToken) => {
  var apigClient = apigClientFactory.newClient({
    invokeUrl: eventsUrl,
    accessKey: accessKey,
    secretKey: secretKey,
    sessionToken: sessionToken,
    region: "eu-west-2",
    systemClockOffset: 0,
    retries: 4,
    retryCondition: (err) => {
      return err.response && err.response.status === 500;
    },
  });

  return apigClient;
};

export const getReservationsApiGWClient = (
  accessKey,
  secretKey,
  sessionToken
) => {
  var apigClient = apigClientFactory.newClient({
    invokeUrl: resUrl,
    accessKey: accessKey,
    secretKey: secretKey,
    sessionToken: sessionToken,
    region: "eu-west-2",
    systemClockOffset: 0,
    retries: 4,
    retryCondition: (err) => {
      return err.response && err.response.status === 500;
    },
  });

  return apigClient;
};
export const getUsersApiGWClient = (accessKey, secretKey, sessionToken) => {
  var apigClient = apigClientFactory.newClient({
    invokeUrl: usersBaseUrl,
    accessKey: accessKey,
    secretKey: secretKey,
    sessionToken: sessionToken,
    region: "eu-west-2",
    systemClockOffset: 0,
    retries: 4,
    retryCondition: (err) => {
      return err.response && err.response.status === 500;
    },
  });

  return apigClient;
};

export const getOrgsApiGWClient = (accessKey, secretKey, sessionToken) => {
  var apigClient = apigClientFactory.newClient({
    invokeUrl: orgUrl,
    accessKey: accessKey,
    secretKey: secretKey,
    sessionToken: sessionToken,
    region: "eu-west-2",
    systemClockOffset: 0,
    retries: 4,
    retryCondition: (err) => {
      return err.response && err.response.status === 500;
    },
  });
  return apigClient;
};

export const getPaymentApiGWClient = (accessKey, secretKey, sessionToken) => {
  var apigClient = apigClientFactory.newClient({
    invokeUrl: paymentUrl,
    accessKey: accessKey,
    secretKey: secretKey,
    sessionToken: sessionToken,
    region: "eu-west-2",
    systemClockOffset: 0,
    retries: 4,
    retryCondition: (err) => {
      return err.response && err.response.status === 500;
    },
  });

  return apigClient;
};

export const getCoreApiGWClient = (accessKey, secretKey, sessionToken) => {
  var apigClient = apigClientFactory.newClient({
    invokeUrl: coreUrl,
    accessKey: accessKey,
    secretKey: secretKey,
    sessionToken: sessionToken,
    region: "eu-west-2",
    systemClockOffset: 0,
    retries: 4,
    retryCondition: (err) => {
      return err.response && err.response.status === 500;
    },
  });
  return apigClient;
};
