import { API } from "aws-amplify";

const OrgsEndPoint = "OrgsEndPoint";
const UsersEndPoint = "UsersEndPoint";
const PaymentEndPoint = "PaymentEndPoint";
const CoreEndPoint = "CoreEndPoint";
const EventEndPoint = "EventEndPoint";
const notificationsEndPoint = "notificationsEndPoint";

const _get = (pathName) => async (path, payload) =>
  API.get(pathName, path, payload);

const _post = (pathName) => async (path, payload) =>
  API.post(pathName, path, payload);

const _put = (pathName) => async (path, payload) =>
  API.put(pathName, path, payload);

const _patch = (pathName) => async (path, payload) =>
  API.patch(pathName, path, payload);

const _delete = (pathName) => async (path, payload) =>
  API.del(pathName, path, payload);

export const usersApiClient = {
  get: _get(UsersEndPoint),
  post: _post(UsersEndPoint),
  put: _put(UsersEndPoint),
  patch: _patch(UsersEndPoint),
  delete: _delete(UsersEndPoint),
};

export const orgsApiClient = {
  get: _get(OrgsEndPoint),
  post: _post(OrgsEndPoint),
  put: _put(OrgsEndPoint),
  patch: _patch(OrgsEndPoint),
  delete: _delete(OrgsEndPoint),
};

export const paymentApiClient = {
  get: _get(PaymentEndPoint),
  post: _post(PaymentEndPoint),
  put: _put(PaymentEndPoint),
  patch: _patch(PaymentEndPoint),
  delete: _delete(PaymentEndPoint),
};

export const coreApiClient = {
  get: _get(CoreEndPoint),
  post: _post(CoreEndPoint),
  put: _put(CoreEndPoint),
  patch: _patch(CoreEndPoint),
  delete: _delete(CoreEndPoint),
};

export const eventsApiClient = {
  get: _get(EventEndPoint),
  post: _post(EventEndPoint),
  put: _put(EventEndPoint),
  patch: _patch(EventEndPoint),
  delete: _delete(EventEndPoint),
};
