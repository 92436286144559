export const GET_TOTAL_STATS = "GET_TOTAL_STATS";
export const TOTAL_STATS_RETURNED = "TOTAL_STATS_RETURNED";
export const GET_ORGS_ACTIVITY = "GET_ORGS_ACTIVITY";
export const ORGS_AVTIVITY_RETURNED = "ORGS_ACTIVITY_RETURNED";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
// daily reservation types
export const GET_DAILY_RESERVATIONS = "GET_DAILY_RESERVATIONS";
export const GET_DAILY_RESERVATIONS_SUCCESS = "GET_DAILY_RESERVATIONS_SUCCESS";
export const GET_DAILY_RESERVATIONS_FAILED = "GET_DAILY_RESERVATIONS_FAILED";

// daily chat reservation types
export const GET_DAILY_CHAT_RESERVATIONS = "GET_DAILY_CHAT_RESERVATIONS";
export const GET_DAILY_CHAT_RESERVATIONS_SUCCESS =
  "GET_DAILY_CHAT_RESERVATIONS_SUCCESS";
export const GET_DAILY_CHAT_RESERVATIONS_FAILED =
  "GET_DAILY_CHAT_RESERVATIONS_FAILED";

// transactions types
export const GET_TRANSACTIONS_STATS = "GET_TRANSACTIONS_STATS";
export const GET_TRANSACTIONS_STATS_SUCCESS = "GET_TRANSACTIONS_STATS_SUCCESS";
export const GET_TRANSACTIONS_STATS_FAILED = "GET_TRANSACTIONS_STATS_FAILED";

// Get Transaction by Id
export const GET_TRANSACTION_BY_ID_REQUEST = "GET_TRANSACTION_BY_ID_REQUEST";
export const GET_TRANSACTION_BY_ID_SUCCESS = "GET_TRANSACTION_BY_ID_SUCCESS";
export const GET_TRANSACTION_BY_ID_FAILED = "GET_TRANSACTION_BY_ID_FAILED";

// refund transaction
export const REFUND_TRANSACTION_REQUEST = "REFUND_TRANSACTION_REQUEST";
export const REFUND_TRANSACTION_SUCCESS = "REFUND_TRANSACTION_SUCCESS";
export const REFUND_TRANSACTION_FAILED = "REFUND_TRANSACTION_FAILED";

// payout transaction
export const PAYOUT_TRANSACTION_REQUEST = "PAYOUT_TRANSACTION_REQUEST";
export const PAYOUT_TRANSACTION_SUCCESS = "PAYOUT_TRANSACTION_SUCCESS";
export const PAYOUT_TRANSACTION_FAILED = "PAYOUT_TRANSACTION_FAILED";

// fees table
export const GET_CALLVITA_FEES_REQUEST = "GET_CALLVITA_FEES_REQUEST";
export const GET_CALLVITA_FEES_SUCCESS = "GET_CALLVITA_FEES_SUCCESS";
export const GET_CALLVITA_FEES_FAILED = "GET_CALLVITA_FEES_FAILED";

export const CREATE_CALLVITA_FEES_REQUEST = "CREATE_CALLVITA_FEES_REQUEST";
export const CREATE_CALLVITA_FEES_SUCCESS = "CREATE_CALLVITA_FEES_SUCCESS";
export const CREATE_CALLVITA_FEES_FAILED = "CREATE_CALLVITA_FEES_FAILED";

export const EDIT_CALLVITA_FEES_REQUEST = "EDIT_CALLVITA_FEES_REQUEST";
export const EDIT_CALLVITA_FEES_SUCCESS = "EDIT_CALLVITA_FEES_SUCCESS";
export const EDIT_CALLVITA_FEES_FAILED = "EDIT_CALLVITA_FEES_FAILED";

export const DELETE_CALLVITA_FEES_REQUEST = "DELETE_CALLVITA_FEES_REQUEST";
export const DELETE_CALLVITA_FEES_SUCCESS = "DELETE_CALLVITA_FEES_SUCCESS";
export const DELETE_CALLVITA_FEES_FAILED = "DELETE_CALLVITA_FEES_FAILED";

export const SET_EDIT_MODAL = "SET_EDIT_MODAL";
export const SET_ADD_MODAL = "SET_ADD_MODAL";

export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const GET_CURRENCY_FAILED = "GET_CURRENCY_FAILED";

export const RESET_EDIT_ERROR = "RESET_EDIT_ERROR";
