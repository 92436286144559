import { 
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_SWEET_SUCCESS_MESSAGE,
  RESET_ERROR,
  RESET_SUCCESS, 
  RESET_SWEET_SUCCESS
} from '../constants/ActionTypes';

export const showErrorMessage = (message) => {
  return {
    type: SHOW_ERROR_MESSAGE,
    payload: message
  }
}

export const showSuccessMessage = (message) => {
  return {
    type: SHOW_SUCCESS_MESSAGE,
    payload: message
  }
}

export const showSweetSuccessMessage = (message) => {
  return {
    type: SHOW_SWEET_SUCCESS_MESSAGE,
    payload: message
  }
}

export const resetError = () => {
  return {
    type: RESET_ERROR
  }
}

export const resetSuccess = () => {
  return {
    type: RESET_SUCCESS
  }
}

export const resetSweetSuccess = () => {
  return {
    type: RESET_SWEET_SUCCESS
  }
}