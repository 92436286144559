export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_RETURNED = "GET_WALLET_RETURNED";
export const GET_WALLET_FAILED = "GET_WALLET_FAILED";
export const GET_WALLET_TRANSACTIONS = "GET_WALLET_TRANSACTIONS";
export const GET_WALLET_TRANSACTIONS_RETURNED =
  "GET_WALLET_TRANSACTIONS_RETURNED";
export const GET_WALLET_TRANSACTIONS_FAILED = "GET_WALLET_TRANSACTIONS_FAILED";
export const ADD_WALLET = "ADD_WALLET";
export const ADD_WALLET_RETURNED = "ADD_WALLET_RETURNED";
export const ADD_WALLET_FAILED = "ADD_WALLET_FAILED";
export const ADMIN_ADD_FUND = "ADMIN_ADD_FUND";
export const ADMIN_ADD_FUND_RETURNED = "ADMIN_ADD_FUND_RETURNED";
export const ADMIN_ADD_FUND_FAILED = "ADMIN_ADD_FUND_FAILED";
export const ADD_WALLET_TO_USER = "ADD_WALLET_TO_USER";
export const ADD_WALLET_TO_USER_RETURNED = "ADD_WALLET_TO_USER_RETURNED";
export const ADD_WALLET_TO_USER_FAILED = "ADD_WALLET_TO_USER_FAILED";
export const GET_USERS = "GET_USERS";
export const GET_USERS_RETURNED = "GET_USERS_RETURNED";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_RETURNED = "SEARCH_USERS_RETURNED";
export const ADD_FUNDS_TO_USER = "ADD_FUNDS_TO_USER";
export const ADD_FUNDS_TO_USER_RETURNED = "ADD_FUNDS_TO_USER_RETURNED";
export const CURRENT_USER_WALLET_ID = " CURRENT_USER_WALLET_ID";
export const GET_ORG_WALLET_TRANSACTIONS = "GET_ORG_WALLET_TRANSACTIONS";
export const GET_ORG_WALLET_TRANSACTIONS_RETURNED =
  "GET_ORG_WALLET_TRANSACTIONS_RETURNED";
export const MY_WALLET = "MY_WALLET";
export const MY_WALLET_RETURNED = "MY_WALLET_RETURNED";
export const CHANGE_WALLET_STATUS = "CHANGE_WALLET_STATUS";
export const CHANGE_WALLET_STATUS_RETURNED = "CHANGE_WALLET_STATUS_RETURNED";
export const NAV_OPTION_SELECTED = "NAV_OPTION_SELECTED";

