import {
  GET_TOTAL_STATS,
  GET_ORGS_ACTIVITY,
  GET_TRANSACTIONS_STATS,
  GET_TRANSACTIONS_STATS_SUCCESS,
  GET_TRANSACTIONS_STATS_FAILED,
  GET_DAILY_RESERVATIONS,
  GET_DAILY_RESERVATIONS_SUCCESS,
  GET_DAILY_RESERVATIONS_FAILED,
  GET_DAILY_CHAT_RESERVATIONS,
  GET_DAILY_CHAT_RESERVATIONS_SUCCESS,
  GET_DAILY_CHAT_RESERVATIONS_FAILED,
  GET_CALLVITA_FEES_REQUEST,
  GET_CALLVITA_FEES_SUCCESS,
  GET_CALLVITA_FEES_FAILED,
  CREATE_CALLVITA_FEES_REQUEST,
  CREATE_CALLVITA_FEES_SUCCESS,
  CREATE_CALLVITA_FEES_FAILED,
  EDIT_CALLVITA_FEES_REQUEST,
  EDIT_CALLVITA_FEES_SUCCESS,
  EDIT_CALLVITA_FEES_FAILED,
  DELETE_CALLVITA_FEES_REQUEST,
  DELETE_CALLVITA_FEES_SUCCESS,
  DELETE_CALLVITA_FEES_FAILED,
  SET_EDIT_MODAL,
  SET_ADD_MODAL,
  GET_CURRENCY,
  RESET_EDIT_ERROR,
  SEND_NOTIFICATION,
} from "../constants/actionTypes";

export const getTotalStats = (filter) => {
  return {
    type: GET_TOTAL_STATS,
    payload: filter,
  };
};

export const getOrgsActivity = (filter) => {
  return {
    type: GET_ORGS_ACTIVITY,
    payload: filter,
  };
};

export const getTransactionsStats = (filter) => {
  return {
    type: GET_TRANSACTIONS_STATS,
    payload: filter,
  };
};

export const getTransactionsStatsSuccess = (transactionsObj) => {
  return {
    type: GET_TRANSACTIONS_STATS_SUCCESS,
    payload: transactionsObj,
  };
};

export const getTransactionsStatsFailed = () => {
  return {
    type: GET_TRANSACTIONS_STATS_FAILED,
  };
};
export const getDailyReservations = (filter) => {
  return {
    type: GET_DAILY_RESERVATIONS,
    payload: filter,
  };
};

export const getDailyReservationsSuccess = (reservationsObj) => {
  return {
    type: GET_DAILY_RESERVATIONS_SUCCESS,
    payload: reservationsObj,
  };
};

export const getDailyReservationsFailed = () => {
  return {
    type: GET_DAILY_RESERVATIONS_FAILED,
  };
};

export const getDailyChatReservations = (filter) => {
  return {
    type: GET_DAILY_CHAT_RESERVATIONS,
    payload: filter,
  };
};

export const getDailyChatReservationsSuccess = (reservationsObj) => {
  return {
    type: GET_DAILY_CHAT_RESERVATIONS_SUCCESS,
    payload: reservationsObj,
  };
};

export const getDailyChatReservationsFailed = () => {
  return {
    type: GET_DAILY_CHAT_RESERVATIONS_FAILED,
  };
};

export const getCallvitaFees = () => {
  return {
    type: GET_CALLVITA_FEES_REQUEST,
  };
};

export const getCallvitaFeesSuccess = (fees) => {
  return {
    type: GET_CALLVITA_FEES_SUCCESS,
    payload: fees,
  };
};

export const getCallvitaFeesFailed = () => {
  return {
    type: GET_CALLVITA_FEES_FAILED,
  };
};

export const createCallvitaFees = (feesObj) => {
  return {
    type: CREATE_CALLVITA_FEES_REQUEST,
    payload: feesObj,
  };
};

export const createCallvitaFeesSuccess = (feesObj) => {
  return {
    type: CREATE_CALLVITA_FEES_SUCCESS,
    payload: feesObj,
  };
};

export const createCallvitaFeesFailed = () => {
  return {
    type: CREATE_CALLVITA_FEES_FAILED,
  };
};

export const editCallvitaFees = (feesObj) => {
  return {
    type: EDIT_CALLVITA_FEES_REQUEST,
    payload: feesObj,
  };
};

export const editCallvitaFeesSuccess = (feesObj) => {
  return {
    type: EDIT_CALLVITA_FEES_SUCCESS,
    payload: feesObj,
  };
};

export const editCallvitaFeesFailed = () => {
  return {
    type: EDIT_CALLVITA_FEES_FAILED,
  };
};

export const deleteCallvitaFees = (feesId) => {
  return {
    type: DELETE_CALLVITA_FEES_REQUEST,
    payload: feesId,
  };
};

export const deleteCallvitaFeesSuccess = (feesId) => {
  return {
    type: DELETE_CALLVITA_FEES_SUCCESS,
    payload: feesId,
  };
};

export const deleteCallvitaFeesFailed = () => {
  return {
    type: DELETE_CALLVITA_FEES_FAILED,
  };
};

export const setEditModal = (payload) => {
  return {
    type: SET_EDIT_MODAL,
    payload,
  };
};

export const setAddModal = (payload) => {
  return {
    type: SET_ADD_MODAL,
    payload,
  };
};

export const getCurrency = () => {
  return {
    type: GET_CURRENCY,
  };
};

export const resetEditError = () => {
  return {
    type: RESET_EDIT_ERROR,
  };
};

export const sendNotification = (payload) => {
  return {
    type: SEND_NOTIFICATION,
    payload,
  };
};
