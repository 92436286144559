// import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import cyan from '@material-ui/core/colors/cyan'

export default {
    palette: {
        primary: {
            light: cyan[500],
            main: '#0a77bf',
            dark: '#3E6580',
            contrastText: '#fff'
        },
        secondary: {
            light: pink[300],
            main: pink['A200'],
            dark: pink[700],
            contrastText: '#fff'
        }
    },
    status: {
        danger: 'orange',
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
    }
};
