export class TimeShiftListItem {
  constructor({
    id,
    start_time,
    day,
    end_time,
    created_by,
    created_at,
    provider_id
  }) {
    this.id = id;
    this.startTime = start_time;
    this.endTime = end_time;
    this.day = day;
    this.createdBy = created_by;
    this.createdAt = created_at;
    this.selectedDrId = provider_id;
  }
}